import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { Gallery } from 'angular-gallery';
import { Lightbox } from 'ngx-lightbox';
import { MetaService } from '../../../services/meta-service.service';
@Component({
    selector: 'app-gallery',
    templateUrl: './gallery.component.html',
    styleUrls: ['./gallery.component.scss']
})
export class GalleryComponent implements OnInit {
    whitedock = false;
    blackdock = true;
    blackhsd = true;
    whitehsd = false;
    whitesec = false;
    blacksec = true;
    whiteslide = false;
    blackslide = true;
    whiteswing = false;
    blackswing = true;
    whiterol = false;
    blackrol = true;
    albums = [];
    src: string;
    caption: any;
    thumb: any;
    album: { src: string; caption: any; thumb: any; };
    docsrc: string;
    docthumb: string;
    docalbums = [];
    docalbum: any;
    hsdsrc: string;
    hsdthumb: string;
    hsdalbum: { src: any; thumb: any; };
    hsdalbums = [];
    doorsrc: string;
    doorthumb: string;
    dooralbum: { src: any; thumb: any; };
    dooralbums = [];
    allsrc: string;
    allthumb: string;
    allalbum: {
        src: any;
        //    caption: this.caption,
        thumb: any;
    };
    allalbums = [];
    shuttersrc: string;
    shutterthumb: string;
    shutteralbum: { src: any; thumb: any; };
    shutteralbums = [];
    gatesthumb: string;
    gatesalbum: { src: any; thumb: any; };
    gatessrc: any;
    gatesalbums = [];
    whiteAll = true;
    blackAll = false;
    constructor(private gallery: Gallery, private lightbox: Lightbox, private metaService: MetaService) {
        // for (let i = 1; i <= 128; i++) {
        //     this.allsrc = 'assets/img/gallery/' + i + '.jpg';
        //     // this.caption = 'Image ' + i + ' caption here';
        //     this.allthumb = 'assets/img/gallery/' + i + '.jpg';
        //     this.allalbum = {
        //        src : this.allsrc,
        //     //    caption: this.caption,
        //        thumb: this.allthumb
        //     };
        //     this.allalbums.push(this.allalbum);
        //   }

        for (let i = 1; i <= 16; i++) {
            this.docsrc = 'assets/img/gallery/Docks/' + i + '.jpg';
            // this.caption = 'Image ' + i + ' caption here';
            this.docthumb = 'assets/img/gallery/Docks/' + i + '.jpg';
            this.docalbum = {
               src : this.docsrc,
            //    caption: this.caption,
               thumb: this.docthumb
            };
            this.docalbums.push(this.docalbum);
            this.allalbums.push(this.docalbum);
          }

        for (let i = 1; i <= 24; i++) {
            this.hsdsrc = 'assets/img/gallery/HSD/' + i + '.jpg';
            // this.caption = 'Image ' + i + ' caption here';
            this.hsdthumb = 'assets/img/gallery/HSD/' + i + '.jpg';
            this.hsdalbum = {
               src : this.hsdsrc,
               thumb: this.hsdthumb
            };
            this.hsdalbums.push(this.hsdalbum);
            this.allalbums.push(this.hsdalbum);
          }


        for (let i = 1; i <= 10; i++) {
            this.shuttersrc = 'assets/img/gallery/Shutter/' + i + '.jpg';
            // this.caption = 'Image ' + i + ' caption here';
            this.shutterthumb = 'assets/img/gallery/Shutter/' + i + '.jpg';
            this.shutteralbum = {
               src : this.shuttersrc,
               thumb: this.shutterthumb
            };
            this.shutteralbums.push(this.shutteralbum);
            this.allalbums.push(this.shutteralbum);
          }

        for (let i = 1; i <= 41; i++) {
            this.doorsrc = 'assets/img/gallery/Doors/' + i + '.jpg';
            // this.caption = 'Image ' + i + ' caption here';
            this.doorthumb = 'assets/img/gallery/Doors/' + i + '.jpg';
            this.dooralbum = {
               src : this.doorsrc,
               thumb: this.doorthumb
            };
            this.dooralbums.push(this.dooralbum);
            this.allalbums.push(this.dooralbum);
          }


        for (let i = 1; i <= 54; i++) {
            this.gatessrc = 'assets/img/gallery/Gates/' + i + '.jpg';
            // this.caption = 'Image ' + i + ' caption here';
            this.gatesthumb = 'assets/img/gallery/Gates/' + i + '.jpg';
            this.gatesalbum = {
               src : this.gatessrc,
               thumb: this.gatesthumb
            };
            this.gatesalbums.push(this.gatesalbum);
            this.allalbums.push(this.gatesalbum);
          }




    }


    ngOnInit(): void {
        console.log(this.albums);
        this.metaService.setCanonicalURL();
    }

    openAll(index: number): void {
        // open lightbox
        this.lightbox.open(this.allalbums, index);
      }
      openHsd(index: number): void {
        // open lightbox
        this.lightbox.open(this.hsdalbums, index);
      }
      openDoor(index: number): void {
        // open lightbox
        this.lightbox.open(this.dooralbums, index);
      }
      openDock(index: number): void {
        // open lightbox
        this.lightbox.open(this.docalbums, index);
      }
      openGate(index: number): void {
        // open lightbox
        this.lightbox.open(this.gatesalbums, index);
      }

      openShutter(index: number): void {
        // open lightbox
        this.lightbox.open(this.shutteralbums, index);
      }

      close(): void {
        // close lightbox programmatically
        this.lightbox.close();
      }

    turnWhite(imgname) {
        if (imgname === 'hsd') {
            this.whitehsd = true;
            this.blackhsd = false;

            this.whitedock = false;
            this.blackdock = true;
            this.whitesec = false;
            this.blacksec = true;
            this.whiteslide = false;
            this.blackslide = true;
            this.whiteswing = false;
            this.blackswing = true;
            this.whiterol = false;
            this.blackrol = true;
            this.whiteAll = false;
            this.blackAll = true;

        } else if (imgname === 'dock') {
            this.whitedock = true;
            this.blackdock = false;

            this.whitehsd = false;
            this.blackhsd = true;
            this.whitesec = false;
            this.blacksec = true;
            this.whiteslide = false;
            this.blackslide = true;
            this.whiteswing = false;
            this.blackswing = true;
            this.whiterol = false;
            this.blackrol = true;
            this.whiteAll = false;
            this.blackAll = true;

        } else if (imgname === 'door') {
            this.whitesec = true;
            this.blacksec = false;

            this.whitedock = false;
            this.blackdock = true;
            this.whitehsd = false;
            this.blackhsd = true;
            this.whiteslide = false;
            this.blackslide = true;
            this.whiteswing = false;
            this.blackswing = true;
            this.whiterol = false;
            this.blackrol = true;
            this.whiteAll = false;
            this.blackAll = true;

        } else if (imgname === 'gate') {
            this.whiteslide = true;
            this.blackslide = false;

            this.whitesec = false;
            this.blacksec = true;
            this.whitedock = false;
            this.blackdock = true;
            this.whitehsd = false;
            this.blackhsd = true;
            this.whiteswing = false;
            this.blackswing = true;
            this.whiterol = false;
            this.blackrol = true;
            this.whiteAll = false;
            this.blackAll = true;

        } else if (imgname === 'swing') {
            this.whiteswing = true;
            this.blackswing = false;

            this.whiteslide = false;
            this.blackslide = true;
            this.whitesec = false;
            this.blacksec = true;
            this.whitedock = false;
            this.blackdock = true;
            this.whitehsd = false;
            this.blackhsd = true;
            this.whiterol = false;
            this.blackrol = true;
            this.whiteAll = false;
            this.blackAll = true;

        } else if (imgname === 'shutter') {
            this.whiterol = true;
            this.blackrol = false;

            this.whiteswing = true;
            this.blackswing = false;
            this.whiteslide = false;
            this.blackslide = true;
            this.whitesec = false;
            this.blacksec = true;
            this.whitedock = false;
            this.blackdock = true;
            this.whitehsd = false;
            this.blackhsd = true;
            this.whiteAll = false;
            this.blackAll = true;

        } else {
            this.whiteswing = false;
            this.blackswing = true;
            this.whiteslide = false;
            this.blackslide = true;
            this.whitesec = false;
            this.blacksec = true;
            this.whitedock = false;
            this.blackdock = true;
            this.whitehsd = false;
            this.blackhsd = true;
            this.whiterol = false;
            this.blackrol = true;
            this.whiteAll = true;
            this.blackAll = false;
        }
    }


}
