import { Component, OnInit, } from '@angular/core';

@Component({
  selector: 'app-expansion',
  templateUrl: './expansion.component.html',
  styleUrls: ['./expansion.component.scss']
})
export class ExpansionComponent implements OnInit {
    step: number;
    constructor() { }


    setStep(tabnumber: number) {
        console.log(tabnumber);
        this.step = tabnumber;
    }

    nextStep() {
      this.step++;
    }

    prevStep() {
      this.step--;
    }



  ngOnInit(): void {
    this.setStep(null);
  }



}
