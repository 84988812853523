<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Sectional door</h2>
            <p>Sectional doors are made of separate panels that lift upwards and rest parallel with the garage ceiling.Sectional doors are made up of between three to eight sections fixed together by strong hinges. Castors are fixed to the panel edges allowing the door to move up and down the track system which is fixed to the door frame and roof. Sectional doors are generally operated on a torsion spring system.</p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                    <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                        style="visibility: visible;">
                        <img src="assets/img/products/doors/s1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                        <img src="assets/img/products/doors/s2.png" alt="Image" type="image/png" width="100%" height="auto">
                        <img src="assets/img/products/doors/s3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                        <img src="assets/img/products/doors/s4.png" alt="Image" type="image/png" width="100%" height="auto">
                    </div>

                    <div class="article-content" align="justify">
                        <h3>About Sectional door</h3>
                        <p>The sectional doors open vertically, creating free space in front of and behind the door. The door sections are stored flat underneath the ceiling, vertically above the opening or along the roof.

                            Insulated Sectional Overhead Doors use a double skinned steel foam panel that gives guaranteed high thermal performance, avoiding cold bridging compared with traditional roller shutters.  Insulated Sectional Overhead Door panels have a thermal performance that is greater than required by Building Regulations.  Windows can be installed in single or multiple panels. Panels can be prefinished in an extensive range of colours and materials.

                            Manufactured to your specific requirements, each of our industrial sectional doors provide reliable service and exceptional ease of use combined with an outstanding level of access control capable of withstanding high usage volumes to cope with all levels of industrial workloads.
                        </p>
                        <ul class="feat" align="justify" style="line-height:180%">
                        <li>Sectional doors are extremely secure. The lack of penetration points means it is almost impossible for intruders to gain a pivot point to prise the door open.
                        </li>
                        <li>Each sectional door will differ in its insulation and weather-protection capabilities. Some will offer very high levels of insulation, similar to that of a brick wall.
                        </li>
                        <li>Sectional doors do not require space outside of the garage; they open and close vertically so that the area around the door is easily accessible and usable by anyone who so wishes.
                        </li>
                        <li>Each panel will be connected to the door track directly. This makes your door much more reliable and less prone to breaking than alternatives.
                        </li>
</ul>

                    </div>

            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>
            <blockquote class="flaticon-quote">
                <table class="table">
     <thead class="table-light">
       <tr>
         <th scope="col">Product : </th>
         <th scope="col">Motorized Sliding Gate</th>
       </tr>
     </thead>
     <tbody>
       <tr>
         <th scope="row" color="#fe9900">Door Dimension: </th>
         <td></td>
       </tr>
       <tr>
         <th scope="row">Coming Soon</th>
         <td></td>
       </tr> 
     </tbody>
   </table>  
 </blockquote>
        </div>

    </div>
</section>
