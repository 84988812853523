import { Component, OnInit } from '@angular/core';
declare let $: any;
import { MetaService } from '../../../../services/meta-service.service';
declare function gtag_report_conversion(): any;
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';
import { MailService } from '../../../../services/mail.service';
@Component({
  selector: 'app-galvalume-shutters',
  templateUrl: './galvalume-shutters.component.html',
  styleUrls: ['./galvalume-shutters.component.scss']
})
export class GalvalumeShuttersComponent implements OnInit {
    registerForm: FormGroup;
    submitted = false;
    FormData: FormGroup;
    success: string;
    error: any;
  showSpinner = false;
  constructor(private metaService: MetaService, private formBuilder: FormBuilder, private contact: MailService) {
    this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        phone: [null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
        subject: ['Enquiry About Alluminium Shutters'],
        message: ['', Validators.required],
        // validates date format yyyy-mm-dd
        // dob: ['', [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
        email: ['', [Validators.required, Validators.email]],
       // password: ['', [Validators.required, Validators.minLength(6)]],
       // confirmPassword: ['', Validators.required],
       // acceptTerms: [false, Validators.requiredTrue]
    });
  }

  ngOnInit(): void {
    this.metaService.setCanonicalURL();
    $('.custom1').owlCarousel({
        animateOut: 'slideOutDown',
        animateIn: 'slideInDown',
        items: 1,
        margin: 30,
        stagePadding: 30,
        smartSpeed: 450,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        loop: true
    });
  }


  // convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
    gtag_report_conversion();
    this.submitted = true;
  //  this.showSpinner = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    // this.registerForm.setValue({
    //     subject: 'Enquiry About Alluminium Shutters',
    //  });
    this.contact.PostMessage(this.registerForm.value).subscribe(
        (res: any) => {
          this.showSpinner = false;
          console.log(res);
          if (res) {
            alert('Message sent Successfully. Our team will contact you soon..!');
            this.onReset();
          } else {
            alert('Something went wrong. Please try after sometime');
            this.onReset();
          }


        },
        error => {
          this.onReset();
          if (error.status === 400) {
            alert('Something went wrong. Please try after sometime');
            // this.tostarService.error('Invalid Username or password!', 'Authentication Failed');
          } else {
            alert('Something went wrong. Please try after sometime');

          }

        }
      );
    }

onReset() {
    this.submitted = false;
    this.registerForm.reset();
   // this.registerForm.setValue({name: '', phone: '', email: '', message: ''});
}

}
