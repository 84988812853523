import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mini-footer',
  templateUrl: './mini-footer.component.html',
  styleUrls: ['./mini-footer.component.scss']
})
export class MiniFooterComponent implements OnInit {
active = false;
menu = false;
  constructor() { }

  ngOnInit(): void {
  }
  open() {
     this.active = true;
  }
  close() {
      this.active = false;
  }
  closeMenu() {
    this.menu = false;
}
}
