<!-- <app-high-speed-doors></app-high-speed-doors> -->
<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>Our Products</h2>
                </div>

                <div class="col-lg-6 col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                    <ul>
                        <li><a routerLink="/home">Home </a></li>
                        <li>Our Products</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>MOTORS</h2>
            <p>A Motor is used to bridge the difference in height and distance between the warehouse floor and vehicle
                in the most efficient way.</p>
        </div>
        <div class="container">
            <h1>GATES:</h1>
            <br>
        </div>
        <div class="row">

            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/m13.jpg"  class="imgview" width="80%" height="auto"alt="Image" type="image/png">
                            <!-- <img src="assets/img/blur.png"> -->
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">Gatemaan MASTER18QR</h1>
                            <p class="card-text" align="justify" style="font-size: 13px;padding-right:28px">DC sliding gate operator with encoder, complete with electronic control unit and built-in 433,92 MHz rolling code radio receiver. 18 Volt Automation for gates upto Max. gate weight 1800 kg with electromechanical limit switch speed 14m/min.</p>
                        
                        <p>Motor Power supply : 18V DC</p>
                    </div>
                    </div>
                </div>
            </div>
			<div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/m13.jpg"  class="imgview" width="80%" height="auto"alt="Image" type="image/png">
                            <!-- <img src="assets/img/blur.png"> -->
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">Gatemaan MASTER18QR-F</h1>
                            <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px">DC sliding gate operator with encoder, electro-mechanical limit switch, complete with electronic control unit and built-in 433,92 MHz rolling code radio receiver. 18 Volt Automation for gates upto Max. gate weight 1800 kg with electromechanical limit switch speed 14m/min.
							</p>
                            <p>Motor Power supply : 18V DC</p>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/m1.jpg"  class="imgview" width="80%" height="auto"alt="Image" type="image/png">
                            <!-- <img src="assets/img/motor/blur.png"> -->
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">Gatemaan MASTER-R</h1>
                            <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px">
                                DC sliding gate operator with encoder, complete with electronic control unit and built-in 433,92 MHz rolling code radio receiver. Automation gate upto gate weight 1200 kg. Speed up to 24 m/min.</p>
                        <p>Motor Power supply : 24V DC</p>
                            </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/m1.jpg"  class="imgview" width="80%" height="auto"alt="Image" type="image/png">
                            <!-- <img src="assets/img/motor/blur.png"> -->
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">Gatemaan Master-RF</h1>
                            <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px">DC sliding gate operator with encoder, electromechanical limit switch, complete with electronic control unit and built-in 433,92 MHz rolling code radio receiver. Automation gate upto weight 1200 kg. Speed up to 24 m/min.</p>
                        <p>Motor Power supply : 24V DC</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/m13.jpg"  class="imgview" width="80%" height="auto"alt="Image" type="image/png">
                            <!-- <img src="assets/img/blur.png"> -->
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">Gatemaan MASTER20QRA</h1>
                            <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px">Sliding gate operator with encoder and limit switch, complete with electronic control unit and built-in 433,92 MHz rolling code radio receiver. Maximum gate weight 2000 kg.</p>
                        <p>Motor Power Supply: 230V AC-Semi Sensitive use</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/m13.jpg"  class="imgview" width="80%" height="auto"alt="Image" type="image/png">
                            <!-- <img src="assets/img/blur.png"> -->
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">Gatemaan MASTER20T</h1>
                            <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px">Three-phase sliding gate operator with encoder without electronic control unit and built-in 433,92 MHz rolling code radio receiver. Maximum gate weight 2000 kg</p>
                            <p>Motor Power Supply: 400V AC-Semi Sensitive use</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/sw1.jpg"  class="imgview" width="80%" height="auto"alt="Image" type="image/png">
                            <!-- <img src="assets/img/blur.png"> -->
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">ARM2000BR</h1>
                            <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px">24 Volt. Automation for gate leaves up to 2,5 m. Opening time 9 s. Irreversible fast DC electromechanical linear gate operator with encoder. Maximum gate leaf 2,5m.</p>
                        <p>Motor Power Supply: 24V</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/sw2.jpg"  class="imgview" width="80%" height="auto"alt="Image" type="image/png">
                            <!-- <img src="assets/img/blur.png"> -->
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">ARM2000BI</h1>
                            <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px">18 Volt. Automation for gate leaves up to 3m. Opening time 12s. Irreversible DC electro-mechanical covered screw gate operator with encoder. Maximum gate leaf 3m.</p>
                            <p>Motor Power Supply: 18V DC - Intensive use</p>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/sw3.jpg"  class="imgview" width="80%" height="auto"alt="Image" type="image/png">
                            <!-- <img src="assets/img/blur.png"> -->
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">Gatemaan R30</h1>
                            <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px">230 V electromechanical gate operator. Max. gate leaf 3,5 m / 400 kg with cable l=2,3 m. Foundation box and release to be ordered separately.</p>
                        <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px">230 V electromechanical gate operator. Max. gate leaf 3,5 m / 400 kg with cable l=9 m. Foundation box and release to be ordered separately.</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/sw3.jpg"  class="imgview" width="80%" height="auto"alt="Image" type="image/png">
                            <!-- <img src="assets/img/blur.png"> -->
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">Gatemaan R40</h1>
                            <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px"> DC electromechanical gate operator with encoder. Max. gate leaf 3,5 m/400 kg with cable l=2,3 m. Foundation box and release to be ordered separately.
                                </p>
                                <p class="card-text" align="justify" style="font-size: 13px; padding-right:28px"> DC electromechanical gate operator with encoder. Max. gate leaf 3,5 m/400 kg with cable l=9 m. Foundation box and release to be ordered separately.
                                </p>    
                        </div>
                    </div>
                </div>
            </div>
            

<div class="container">
    <h1>SHUTTERS:</h1>
    <br>
</div>

                        <!--Shutter-->
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-4">

                                        <img src="assets/img/motor/s1.png"  class="imgview" width="100%" height="auto"alt="Image" type="image/png">
                                        <!--<!-- <img src="assets/img/blur.png"> -->
                                    </div>
                                    <div class="col-sm-8">
                                        <h1 class="card-title">Direct Drive Motor</h1>
                                        <p class="card-text">
                                           <li> These are most advanced motors with German make.</li>
                                           <li> Direct drive motors comes with advanced safety system.</li>
                                           <li> Safety edge system is available (auto reversal technology when it come in contact with any obstacles).</li>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <img src="assets/img/motor/s2.png"  class="imgview" width="100%" height="auto"alt="Image" type="image/png">
                                    </div>
                                    <div class="col-sm-8">
                                        <h1 class="card-title">Brushless Motor</h1>
                                        <p class="card-text">
                                            <li>These brushless motor come on the top of rolling shutter.</li>
                                            <li>Emergency chain operation during the time of power failure</li>
                                            <li>Limit switches are available to set- up opening and closing limits</li>
                                            <li>Around 3.7 m in 40 secs.</li>
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <img src="assets/img/motor/s3.png"  class="imgview" width="100%" height="auto"alt="Image" type="image/png">

                                    </div>
                                    <div class="col-sm-8">
                                        <h1 class="card-title">Tubular Motor</h1>
                                        <p class="card-text">
                                            <li>Preferably used for Aluminium rolling shutters.</li>
                                            <li>Motor can be used in rolling shutters up to 400 kgs weight.</li>
                                            <li>Tubular motors are concealed motors which come inside the shaft.</li>
                                            <p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-6">
                            <div class="card">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <img src="assets/img/motor/s4.png"  class="imgview" width="100%" height="auto"alt="Image" type="image/png">

                                    </div>
                                    <div class="col-sm-8">
                                        <h1 class="card-title">Hyrid Motor</h1>
                                        <p class="card-text">
                                            <li>Gatemaan manufactures hybrid motors by using German technology.</li>
                                            <li>During the time of power failure we can easily operate it manual by using small handle</li>
                                            <li>Both single phase and 3 phase motors available.</li>

                                            <p>
                                    </div>
                                </div>
                            </div>
                        </div>
            <!--- 
            <div class="col-sm-6">
                <div class="card">
                    <div class="row">
                        <div class="col-sm-4">
                            <img src="assets/img/motor/m5.png"  class="imgview" width="100%" height="auto"alt="Image" type="image/png">
                             <img src="assets/img/blur.png"> 
                        </div>
                        <div class="col-sm-8">
                            <h1 class="card-title">Final</h1>
                            <p class="card-text">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, </p>
                        </div>
                    </div>
                </div>
            </div>
            Last -->
        </div>


    </div>
</section>

