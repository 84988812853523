<section class="blog-details-area">
    <br>
    <div class="row">
        <div class="col-sm-12 text-center">
            <ul class="nav nav-pills mb-3 text-center bordernav" id="pills-tab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active anav" id="sft-sft-tab" data-toggle="pill" href="#sft-sft" role="tab"
                        aria-controls="sft-sft" aria-selected="true">EFA SFT®</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="stt-therm-tab" data-toggle="pill" href="#sft-therm" role="tab"
                        aria-controls="stt-therm" aria-selected="false">EFA SFT® Therm</a>
                </li>
            </ul>
        </div>
    </div>
    <br>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="sft-sft" role="tabpanel" aria-labelledby="sft-sft-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SFT®</h2>
                    <p>The EFA-SFT® perfectly combines functionality and esthetics. Due to its modular design, it only requires few repair
                        and maintenance. Especially large doors are equipped with special door stoppers in order to additionally
                        stabilise the middle area of the closed wing.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SFT/1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SFT/2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SFT/3.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SFT/4.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SFT/5.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SFT/6.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SFT®</h3>
                                <p>As far as the equipment options of the EFA-SFT® are concerned, it is the most diverse
                                    high-speed door by EFAFLEX. There are numerous possibilities for adjusting the EFA-SFT®
                                    optimally to every kind of facade - variable options are wing partition, the layout of
                                    the bars and the panels. The surface of the door leaf can be anodised in the colours of
                                    the Eloxal table or be powder-coated in all RAL-colours, just as you like it.
                                </p>

                                <strong>Fast and Robust</strong>
                                <p>
                                    Due to the horizontal folding movement of the high-speed folding doors, the full clearance height is
                                    immediately completely free. Doors of the F series open with high speeds and are extremely heavy-duty.
                                    They protect against noise and draft air, save energy and convince with good heat and sound insulating
                                    properties.
                                </p>
                                <strong>Individual Design</strong>
                                <p>
                                    High-speed folding doors by EFAFLEX which are mainly used for closing whole buildings are one aspect of modern
                                    industrial architecture. Thanks to the large number of design possibilities, your high-speed folding door can be
                                    adjusted to every facade.
                                </p>
                                <strong>Speed Up</strong>
                                <p>
                                    Even an EFA-SFT® with a width and height of several metres can achieve speeds of up to 2.5 m/s. Inside, this
                                    advantage ensures a smooth and efficient workflow. Outside, the enormous speed helps in saving energy during opening and closing.
                                </p>
                                <strong>Power and reliability</strong>
                                <p>
                                    Doors of the F series are as a standard equipped with a robust pneumatic drive. This powerful drive has proved many thousand times
                                    and was developed until it was perfect. The drive easily performs one million operating cycles and more. If you cannot provide
                                    compressed air supply, we can also supply your high-speed folding door with an electric motor.
                                </p>
                                <strong>Touch of a Button</strong>
                                <p>
                                    The EFA-SFT® can be equipped with a comfortable remote locking. It is operated from the control cabinet or by means of an external
                                    key switch. If necessary, the door can be unlocked from inside by means of a lever and be opened manually.
                                </p>




                                <strong>EFA-SFT® at a glance</strong>
                                <ul class="feat">
                                    <li>Fast, robust, economical</li>
                                    <li>Low space requirement</li>
                                    <li>Excellent price-performance ratio</li>
                                    <li>Also in pneumatics design</li>
                                    <li>Opening up to 2.5 m/s</li>
                                    <li>Closing up to 1.0 m/s</li>
                                    <li>Up to 200,000 operating cycles p.a.</li>
                                    <li>Standard sizes up to w=8,000mm x h=6,000mm</li>

                                </ul>



                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SFT®</th>
                 <th> </th>
                 <th> </th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                   <td scope="col">S
                </td>
                <td scope="col">ÜS
                </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">◦
                    <br>•
                </td>
                <td scope="col">◦
                    <br>•
                </td>
                <td scope="col">◦
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">4
               </td>
               <td scope="col">3
            </td>
            <td scope="col">2
            </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
               <td scope="col">fulfilled
            </td>
            <td scope="col">fulfilled
            </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
               <td scope="col">0
            </td>
            <td scope="col">0
            </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
               <td scope="col">0
            </td>
            <td scope="col">0
            </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">21
               </td>
               <td scope="col">21
            </td>
            <td scope="col">21
            </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">4.88
               </td>
               <td scope="col">4.66
            </td>
            <td scope="col">4.11
            </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">3,750
                    <br>3,750
               </td>
               <td scope="col">5,250
                <br>7,000
           </td>
           <td scope="col">8,000
            <br>6,000
       </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">2.0
               </td>
               <td scope="col">2.0
            </td>
            <td scope="col">1.5
            </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">2.0
                    <br>1.0
                    <br>-
               </td>
               <td scope="col">2.0
                <br>1.0
                <br>-
           </td>
           <td scope="col">1.0
            <br>0.6
            <br>-
       </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">-
                    <br>-
                    <br>-
               </td>
               <td scope="col">-
                <br>-
                <br>-
           </td>
           <td scope="col">-
            <br>-
            <br>-
       </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
               </td>
               <td scope="col">•
                <br>-
                <br>◦
           </td>
           <td scope="col">-
            <br>-
            <br>•
       </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>•/◦
                        <br>◦/◦
                        <br>◦
                        <br>•
                        <br>-
                        <br>-
                        <br>-
               </td>
               <td scope="col">-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>•/◦
                <br>◦/◦
                <br>◦
                <br>•
                <br>-
                <br>-
                <br>-
       </td>
       <td scope="col">-
        <br>-
        <br>-
        <br>-
        <br>-
        <br>•/◦
        <br>◦/◦
        <br>◦
        <br>•
        <br>-
        <br>-
        <br>-
</td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
               <td scope="col">B2
            </td>
            <td scope="col">B2
            </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
               <td scope="col">-
            </td>
            <td scope="col">-
            </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">200,000
               </td>
               <td scope="col">200,000
            </td>
            <td scope="col">200,000
            </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">◦
                    <br>•
               </td>
               <td scope="col">◦
                <br>•
           </td>
           <td scope="col">◦
            <br>•
       </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">◦
                    <br>MCP2 - ETR 
                    <br>◦
                    <br>•
               </td>
               <td scope="col">◦
                <br>MCP2 - ETR
                <br>◦
                <br>•
           </td>
           <td scope="col">◦
            <br>MCP2 - ETR
            <br>◦
            <br>•
       </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
           <td scope="col">•
            <br>-
       </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">◦
               </td>
               <td scope="col">◦
            </td>
            <td scope="col">◦
            </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">-
                    <br>•
               </td>
               <td scope="col">-
                <br>•
           </td>
           <td scope="col">-
            <br>•
       </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>◦
                    <br>◦
               </td>
               <td scope="col">-
                <br>•
                <br>•
                <br>◦
                <br>◦
           </td>
           <td scope="col">-
            <br>•
            <br>•
            <br>◦
            <br>◦
       </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/◦
               </td>
               <td scope="col"> -/◦
            </td>
            <td scope="col"> -/◦
            </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
               <td scope="col">•
            </td>
            <td scope="col">•
            </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>
            </div>
        </div>

        <div class="tab-pane fade show" id="sft-therm" role="tabpanel" aria-labelledby="sft-therm-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SFT® Therm</h2>
                    <p>The introduction of this new door type sees a fundamental new development, with the door leaf now consisting of a thermally
                        separated steel profile frame combined with 40mm thick EFA-THERM® laths.

                        Given to the configuration of materials used a whole new standard in thermal performance for folding doors has been achieved, fulfilling even the highest requirements perhaps for new build or refurbishment,
                        where the aim is to deliver energy savings.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                            <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                                style="visibility: visible;">
                                <img src="assets/img/products/hsd/SFT/t1.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SFT/t2.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SFT/t3.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SFT/t4.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SFT/t5.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SFT/t6.jpg" alt="Image" width="100%" height="auto">
                            </div>

                            <div class="article-content">
                                <h3>About EFA-SFT® Therm</h3>
                                <p>The combined safety contact edge and light barrier continuously monitor door safety. Should the safety contact edge encounter an obstacle in operation the door wings stop, and immediately return
                                    to the open position, with operation reinstated automatically on a timed close delay. To optimise safety the light barriers are integrated into the side frames, offset from the door closing line,
                                    with variable fitting heights to suit your operation.
                                <br>
                                As an additional safety option, a light curtain can be mounted to the vertical frame which monitors the area in front of the closing line up to a height of 1,755 mm. The light curtain prevents the
                                door system operating when an obstacle is detected in the opening.
                                <br>
                                Perhaps your wish for more natural light in which case triple glazing is available, thanks to the unique framing profile the infills are quickly and easily replaced.


                                </p>


                                <strong>EFA-SFT® THERM Highlights</strong>
                                <ul class="feat">
                                    <li>Quick, robust, economical</li>
                                    <li>Minimum space requirement</li>
                                    <li>Thermally insulated steel profile construction</li>
                                    <li>Infills utilize EFA-THERM® laths</li>
                                    <li>Triple PMMA glazing optionally available</li>
                                    <li>Opening speed up to 1 m/s</li>
                                    <li>Closing speed up to 0.6 m/s</li>
                                    <li>Up to 150,000 operating cycles per year</li>
                                    <li>Standard sizes up to W=5,250mm x H=7,000mm</li>


                                </ul>


                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SFT® Therm</th>
                 <th> </th>
                 <th> </th>
                
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">◦
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">4
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">1
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">25
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">1.6
               </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">5,250
                    <br>7,000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">1.2
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.0
                    <br>0.6
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">-
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">•
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>◦
                        <br>-
                        <br>◦
                        <br>-
                        <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">150,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">◦
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>
    </div>

</section>
