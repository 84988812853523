<div class="error-area">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="error-contact-wrap">
                <h1>4 <span>0</span> 4</h1>
                <h3>Oops! Page Not Found</h3>
                <p>The page you were looking for could not be found.</p>
                <a routerLink="/" class="default-btn btn-two">Return To Home Page</a>
            </div>
        </div>
    </div>
</div>