<!-- <nav class="navbar navbar-expand-md navbar-light">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-12">
                <a class="navbar-brand" routerLink="/">
                    <img src="assets/img/gatemaangold.png" alt="Logo">
                </a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>
            </div>
            <div class="col-lg-9 col-md-12">
                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home</a></li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Home <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home One</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-two" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Two</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-three" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Three</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-four" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Four</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/home-five" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Home Five</a></li>
                            </ul>
                        </li>
                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/about" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Us</a></li>
                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Products <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">


                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/services" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Gates</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/affiliate" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Rolling Shutters</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/team" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Doors</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/pricing" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Landing Bay Equipment</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/faq" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">High Speed Doors</a></li>

                                <li class="nav-item">
                                    <a href="javascript:void(0)" class="nav-link">User <i class='bx bx-chevron-down'></i></a>

                                    <ul class="dropdown-menu">
                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/log-in" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Log In</a></li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/sign-up" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Sign Up</a></li>

                                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/recover-password" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Recover Password</a></li>
                                    </ul>
                                </li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/terms-conditions" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Terms Conditions</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/privacy-policy" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Privacy Policy</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/coming-soon" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Coming Soon</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/error" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">404 Error</a></li>
                            </ul>
                        </li>

                        <li class="nav-item"><a routerLink="/domain" class="nav-link">Gallery</a></li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Hosting <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/shared-hosting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Shared Hosting</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/reseller-hosting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Reseller Hosting</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/dedicated-hosting" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Dedicated Hosting</a></li>
                            </ul>
                        </li>

                         <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">News <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/news-grid" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Grid</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/news-left-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Left Sidebar</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/news-right-sidebar" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Right Sidebar</a></li>

                                <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a  class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">News Details</a></li>
                            </ul>
                        </li>

                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link">Contact</a></li>
                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link">Brochers</a></li>
                        <li class="nav-item" data-toggle="collapse" data-target=".navbar-collapse.show"><a routerLink="/contact" class="nav-link">Showcube Expo</a></li>
                    </ul>

                </div>
            </div>
        </div>
    </div>
</nav> -->
<div class="mat-typography pace-done" [ngClass]="(menu==true)?'menu-is-open':''">

 <!-- header
    ================================================== -->
    <header class="s-header">
        <div class="header-logo">
            <div class="spacer"></div>
            <a class="site-logo" href="index.html" [routerLink]="">
                <img src="../../../../assets/img/logopng.png" alt="Homepage">
            </a>
            <a class="site-logo efa" href="index.html" [routerLink]=""   >
                <img src="../../../../assets/img/Partner Efaflex.PNG" alt="Homepage">
            </a>
            
        </div>
        <br>
        

        <!-- end header-logo -->
        <nav class="header-nav">
            <button (click) = "closeMenu()" class="header-nav__close" title="close"><span>Close</span></button>
            <div class="header-nav__content">
                <h3>Gatemaan </h3>
                <ul class="header-nav__list">
                    <li class="current"><a class="smoothscroll" (click)="closeMenu()" routerLink="/home" title="home">Home</a></li>
                    <li><a  class="smoothscroll" routerLink="/about" (click)="closeMenu()" title="about">About Us</a></li>
                    <li><a class="smoothscroll"  routerLink="/products" (click)="closeMenu()" title="services">Our Products</a></li>
                    <!-- <li><a class="smoothscroll"  href="#works" title="works">Works</a></li> -->
                    <li><a  class="smoothscroll" routerLink="/gallery" (click)="closeMenu()"  title="gallery">Gallery</a></li>
                    <li><a  class="smoothscroll" routerLink="/clients"  (click)="closeMenu()"title="Brouchers">Our Clients</a></li>
                    <li><a class="smoothscroll"  routerLink="/contact"  (click)="closeMenu()"title="contact">Contact</a></li>
                    <li><a  class="smoothscroll" routerLink="/brouchers"  (click)="closeMenu()"title="Brouchers">Brouchers</a></li>
                    </ul>
                <!-- <p>Perspiciatis hic praesentium nesciunt. Et neque a dolorum <a href='#0'>voluptatem</a> porro iusto sequi veritatis libero enim. Iusto id suscipit veritatis neque reprehenderit.</p>
     -->
                <ul class="header-nav__social">
                    <li>
                        <a href="https://www.facebook.com/Gatemaan-A-Vertical-of-NSMT-101129395105393"><i class="fab fa-facebook"></i></a>
                    </li>
                    <!-- <li>
                        <a href="#0"><i class="fab fa-twitter"></i></a>
                    </li> -->
                    <li>
                        <a href="https://www.linkedin.com/company/68846226/admin/"><i class="bx bxl-linkedin"></i></a>
                    </li>
                    <!-- <li>
                        <a href="#0"><i class="fab fa-behance"></i></a>
                    </li>
                    <li>
                        <a href="#0"><i class="fab fa-dribbble"></i></a>
                    </li> -->
                </ul>

            </div> <!-- end header-nav__content -->

        </nav> <!-- end header-nav -->

        <button class="header-menu-toggle" (click) = "menutoggle()">
            <span class="header-menu-icon"></span>
        </button>

    </header> <!-- end s-header -->
</div>
