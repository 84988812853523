<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title">
            <h2>EFA-STR®</h2>
            <p>The EFA-STR® opens with an incredible speed of more than 4 m/s.
                With the EFA-STR, your logistic processes become faster and more efficient.</p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/products/hsd/STR/1.jpg" alt="Image" width="100%" height="auto">
                    <img src="assets/img/products/hsd/STR/2.jpg" alt="Image" width="100%" height="auto">
                    <img src="assets/img/products/hsd/STR/3.jpg" alt="Image" width="100%" height="auto">
                    <img src="assets/img/products/hsd/STR/4.jpg" alt="Image" width="100%" height="auto">
                    <img src="assets/img/products/hsd/STR/5.jpg" alt="Image" width="100%" height="auto">
                    <img src="assets/img/products/hsd/STR/6.jpg" alt="Image" width="100%" height="auto">
                </div>

                <div class="article-content">
                    <h3>About EFA-STR®</h3>
                    <p>
                        Our fastest door – a combination of the spiral-shaped door guidance and the flexible door blade
                        guarantees you an optimal traffic flow.
                        The EFA-STR does not only move with an incredible speed but also requires extremely low
                        maintenance, is heavy-duty and extremely stable.
                        The flexible door leaf is led laterally and is moved upwards and downwards in a force-closed
                        manner.</p>

                    <ul class="feat">
                        <li>EFA-ACS® with self-repairing door leaf as option</li>
                        <li>Opening up to 4.0 m/s.</li>
                        <li>Closing up to 1.0 m/s.</li>
                        <li>Highest wind load capacity.</li>
                        <li>Top safety devices.</li>
                        <li>Up to 250,000 operating cycles p.a.</li>
                        <li>Standard sizes up to w=7,000mm x h=6,000mm</li>

                    </ul>
                    <p>
                        The door leaf is not wound-up on a shaft but kept space-saving in a certain distance in the
                        patented EFAFLEX spiral. This functional principle guarantees quite a number of unique
                        advantages: only this unique design combines highest opening speed, long service life and
                        efficiency in such a perfect manner. Thanks to the spiral, the doors move quietly and wear-free
                        - and look as good as new for many years.
                    </p>


                </div>



            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Efaflex</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a routerLink="" class="thumb">
                                    <span class="fullimage cover bg11" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a routerLink="">
                                            Global Market Leader
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="" class="thumb">
                                    <span class="fullimage cover bg12" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a routerLink="">
                                            Unwavering Innovative Strength
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="" class="thumb">
                                    <span class="fullimage cover bg13" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a routerLink="">
                                            Consistent customer focus
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="" class="thumb">
                                    <span class="fullimage cover bg14 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a routerLink="">
                                            Cost Efficiency
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="" class="thumb">
                                    <span class="fullimage cover bg15 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a routerLink="">
                                            Quality Pays off
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a routerLink="" class="thumb">
                                    <span class="fullimage cover bg16 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a routerLink="">
                                            Customised Configuration
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>
            <blockquote class="flaticon-quote">
                <table class="table">
     <thead class="table-light">
       <tr>
         <th scope="col">Product : </th>
         <th>EFA-STR®</th>
         <th> </th>
         <th> </th>
       </tr>
     </thead>
     <tbody>
        <tr>
            <th scope="row" ></th>
            <td scope="col">Size
            </td>
            <td scope="col">L
           </td>
           <td scope="col">S
        </td>
          </tr>
       <tr>
         <th scope="row" >Application </th>
         <td scope="col">Interior Door
            <br>
            Lock up Doors
         </td>
         <td scope="col">•
            <br>◦
        </td>
        <td scope="col">•
            <br>◦
        </td>
       </tr>
       <tr>
        <th scope="row">Wind load max.*</th>
        <td scope="col">According to DIN EN 12424 class
        </td>
        <td scope="col">2 - 3
       </td>
       <td scope="col">2 - 3
    </td>
      </tr>
      <tr>
        <th scope="row">Operating forces/safe opening</th>
        <td scope="col">According to DIN EN 13241
        </td>
        <td scope="col">fulfilled
       </td>
       <td scope="col">fulfilled
    </td>
      </tr>
      <tr>
        <th scope="row">Resistance against water ingress</th>
        <td scope="col">According to DIN EN 13241 class
        </td>
        <td scope="col">0
       </td>
       <td scope="col">0
    </td>
      </tr>
      <tr>
        <th scope="row">Air permeability*</th>
        <td scope="col">According to DIN EN 13241 class
        </td>
        <td scope="col">1
       </td>
       <td scope="col">1
    </td>
      </tr>
      <tr>
        <th scope="row">Direct airborne sound insulation RW*</th>
        <td scope="col">in dB according to DIN EN 717-1
        </td>
        <td scope="col">12
       </td>
       <td scope="col">12
    </td>
      </tr>
      <tr>
        <th scope="row">U value maximum*</th>
        <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
        </td>
        <td scope="col">6.1
       </td>
       <td scope="col">5.95
    </td>
      </tr>

      <tr>
        <th scope="row">Door size (in mm) </th>
        <td scope="col">Width W max.
            <br>
            Height H max.
        </td>
        <td scope="col">4000
            <br>5000
       </td>
       <td scope="col">7000
        <br>6000
   </td>
      </tr>
      <tr>
        <th scope="row">Maximum door blade speed* </th>
        <td scope="col">in m/s
        </td>
        <td scope="col">4.0
       </td>
       <td scope="col">3.2
    </td>
      </tr>
      <tr>
        <th scope="row">Average speed, ca.*</th>
        <td scope="col">Opening in m/s
            <br>Closing in m/s
            <br>Closing in m/s, with EFA-TLG® door light-line
            grid
        </td>
        <td scope="col">3.6
            <br>0.75
            <br>1.0
       </td>
       <td scope="col">2.8
        <br>0.75
        <br>1.0
   </td>
      </tr>
      <tr>
        <th scope="row">Door blade guidance</th>
        <td scope="col">Round spiral
            <br>Oval Spiral
            <br>Low-header
        </td>
        <td scope="col">•
            <br>-
            <br>-
       </td>
       <td scope="col">•
        <br>-
        <br>-
   </td>
      </tr>
      <tr>
        <th scope="row">Steel design</th>
        <td scope="col">Galvanized sheet steel frame
            <br>Stainless steel
            <br>Powder coated in RAL colours
        </td>
        <td scope="col">•
            <br>◦
            <br>◦
       </td>
       <td scope="col">•
        <br>◦
        <br>◦
   </td>
      </tr>
      <tr>
        <th scope="row">Door blade</th>
        <td scope="col">EFA-THERM® laths insulated / painted
            <br>EFA-CLEAR® Vision laths double-walled,
            thermally separated
            <br>EFA-CLEAR® Vision laths single-walled
            <br>EFA-VENT® Ventilation laths
            <br>EFA-ALUX® Aluminium laths
            <br>Vision panel single-walled / double-walled
            <br>non transparent infill single-walled / doublewalled
            <br>Colour according to RAL (without vison panel)
            <br>Door blade modules made of anodized
            aluminium E6 / EV1
            <br>Triple glazing for vision panel 
            <br>Door curtain made of flexible PVC, transparent
            with warning stripes in different colours 
            <br>flexible fabric in different colours with / without
            vison panel
        </td>
        <td scope="col">-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>◦/•
       </td>
       <td scope="col">-
        <br>-
        <br>-
        <br>-
        <br>-
        <br>-
        <br>-
        <br>-
        <br>-
        <br>-
        <br>-
        <br>◦/•
</td>
      </tr>
      <tr>
        <th scope="row">Fire class</th>
        <td scope="col">Building Material class DIN 4102
        </td>
        <td scope="col">B2
       </td>
       <td scope="col">B2
    </td>
      </tr>
      <tr>
        <th scope="row">Weight balancing by</th>
        <td scope="col">
        </td>
        <td scope="col">Spring
       </td>
       <td scope="col">Spring
    </td>
      </tr>
      <tr>
        <th scope="row">Designed for approx ... operating cycles </th>
        <td scope="col">per year
        </td>
        <td scope="col">200,000
       </td>
       <td scope="col">200,000
    </td>
      </tr>
      <tr>
        <th scope="row">Drive</th>
        <td scope="col">Electric motor
            <br>Pneumatic with electric controller

        </td>
        <td scope="col">•
            <br>-
       </td>
       <td scope="col">•
        <br>-
   </td>
      </tr>
      <tr>
        <th scope="row">Control</th>
        <td scope="col">EFA-TRONIC®
            <br>EFA-TRONIC® Light
            <br>EFA-TRONIC® Professional
            <br>Main switch and foil keypad
        </td>
        <td scope="col">•
            <br>-
            <br>◦
            <br>•
       </td>
       <td scope="col">•
        <br>-
        <br>◦
        <br>•
   </td>
      </tr>
      <tr>
        <th scope="row">Lead</th>
        <td scope="col">Electricity connection 230 V/50 Hz
            <br>Electricity connection 400 V/50 Hz
        </td>
        <td scope="col">•
            <br>-
       </td>
       <td scope="col">•
        <br>-
   </td>
      </tr>
      <tr>
        <th scope="row">Manual locking</th>
        <td scope="col">
        </td>
        <td scope="col">-
       </td>
       <td scope="col">-
    </td>
      </tr>
      <tr>
        <th scope="row">Emergency opening</th>
        <td scope="col">Automatic after manual activation
            <br>Manual activation
        </td>
        <td scope="col">•
            <br>-
       </td>
       <td scope="col">•
        <br>-
   </td>
      </tr>
      <tr>
        <th scope="row">Safety Devices</th>
        <td scope="col">EFA-TLG® door light grid in door closing line
            <br>Contact edge
            <br>Light barrier
            <br>Approach area monitoring
            <br>Light grid, external
        </td>
        <td scope="col">•
            <br>-
            <br>-
            <br>◦
            <br>◦
       </td>
       <td scope="col">•
        <br>-
        <br>-
        <br>◦
        <br>◦
   </td>
      </tr>
      <tr>
        <th scope="row">Safety system including activator</th>
        <td scope="col">EFA-SCAN® frame/bollard
        </td>
        <td scope="col"> ◦/◦
       </td>
       <td scope="col"> ◦/◦
    </td>
      </tr>
      <tr>
        <th scope="row">Activators</th>
        <td scope="col">Connection of all common activators possible
        </td>
        <td scope="col">•
       </td>
       <td scope="col">•
    </td>
      </tr>
      </tbody>
   </table>
   <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
     
 </blockquote>
        </div>

    </div>
</section>
