<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Stainless Steel Shutters</h2>
            <p>Stainless Steel Shutters are an ideal solution for exterior building closure Presently carbon steel is
                being used for the manufacture of rolling shutters in our country. Gatemaan has developed Stainless
                Steel Shutters in 304, 316, and 430 grade (Dull finish) considering the advantages of the material</p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/products/shutters/ssrs1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/ssrs2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/ssrs3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/ssrs4.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/ssrs0.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                </div>

                    <div class="article-content" align="justify">
                        <h3>About Stainless Steel Shutters</h3>
                        <p>The Stainless Steel Rolling Shutter, perhaps more than any other door has a diverse range of
                            applications. The shutter has very good Corrosion, Oxidation resistance and is Flat roll
                            formed stainless steel lath. The tracks are made of Stainless Steel, which makes them long
                            lasting and reliable. Laths are kept in place using Nylon End Clips to every lath. There are
                            various bottom rail options as well as different locking options.
                        </p>
                        <ul class="feat" style="line-height:180%">
                            <li>Stainless steel which prevent the shutters from rusting and helps to provide them a long life.</li>
                            <li>Mostly used in high humid areas. </li>
                            <li>These shutters protect from harsh environments.</li>
                            <li>These rolling shutters give longer product life.</li>
                            <li>These are mostly used in pharma and food processing industries because of its contamination prevention and easy clean properties.</li>
                            <li>Stainless steel  rolling shutters are cost-effective solution for any kind of application & provide protection against burglary.</li>
                            <li>Can be used in chemical environments and pharmaceutical industries, due to its less reaction towards chemicals and corrosion resistant property.
                            </li>

                        </ul>


                    </div>
            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>

            <table class="table table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Product : </th>
                    <th scope="col">Motorized Stainless Steel Rolling Shutter</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" color="#fe9900">Shutter Dimension : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Width Min. /Max.</th>
                    <td>1000 / 12000mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Height Min. /Max.</th>
                    <td>1000 / 12000mm; Max. Area 90.0 Sqm 
                        <br> Large Dimestion on Request
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Opening Dimension</th>
                    <td>Vertical</td>
                  </tr>
                  <tr>
                    <th scope="row">Mounting Location</th>
                    <td>Inside / Outside</td>
                  </tr>
                  <tr>
                    <th scope="row">Shutter Material</th>
                    <td>Aluminum Alloy</td>
                  </tr>
                  <tr>
                    <th scope="row">Maximum Width * Height:</th>
                    <td>6 * 8 m</td>
                  </tr>
                  <tr>
                    <th scope="row"> Type of box</th>
                    <td>165 185 205 250 300 350mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Thickness</th>
                    <td>1.2mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Surface Treatin</th>
                    <td>Powder Coating</td>
                  </tr>
                  <tr>
                    <th scope="row">The sevice life</th>
                    <td>can be used 15 years</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening/Closing Speed</th>
                    <td>0.05m - 0.2m/sec</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Up to 90 km/hr
                        <br> * Higher Wind Pressure on Request
                    </td>
                  </tr>
                
                  <tr>
                    <th scope="row" color="#fe9900">Motor Size</th>
                    <td>600Kg / 800Kg / 1000Kg etc</td>
                  </tr>
    
                  <tr>
                    <th scope="row">Color</th>
                    <td>Red, Yellow, Orange, Blue, Grey etc</td>
                  </tr>
                  <tr>
                    <th scope="row">Control Panel</th>
                    <td>Danvers Brand,Including Inverter, PLC and other components.Size Option: 0.75KW</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive System</th>
                    <td>Germany SEW motor 380V or 220V/50Hz 0.75kw,1.1kw,1.5kw
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Opening Way</th>
                    <td>Double-side radar, double-side press button, infrared sensors</td>
                  </tr>
                  <tr>
                    <th scope="row">Dynamical systems</th>
                    <td>Adopt high-tech motor reducer:0.75-2.2KW, which according to your door size.</td>
                  </tr>
                  <tr>
                    <th scope="row">Options Available</th>
                    <td>Perforation In all above Slat profile S.S Hood Cover made of sheet thickness 0.4mm</td>
                  </tr>
    
                  <tr>
                    <th scope="row">Details for Automation System : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Control Type</th>
                    <td>Deadman / Pulse Control</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Type</th>
                    <td>Indirect / Direct Connecting Drive</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Unit</th>
                    <td>Electric Motor(Imported Make) Single Phase/Three Phase.</td>
                  </tr>
                  <tr>
                    <th scope="row">Emergency Operation	</th>
                    <td>Manual operation by Hand chain</td>
                  </tr>
                  <tr>
                    <th scope="row" color="#fe9900">Options Available</th>
                    <td>Up to 90 km/hr</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Perforation In Slat profile
                        Rolling Grill
                        Wicket Door for pedestrian entry</td>
                  </tr>
                  <tr>
                    <th scope="row">Features : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Certification</th>
                    <td>CE VDE checked</td>
                  </tr>
                  <tr>
                    <th scope="row">Protection Class</th>
                    <td>IP54.</td>
                  </tr>
                  
                </tbody>
              </table>


<!--
            <blockquote class="flaticon-quote">
                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td style="width:120px"><span class="fa fa-thumbs-o-up"></span> &nbsp;Product
                                        </td>
                                        <td>Motorized S.S rolling Shutters

                                        </td>
                                    </tr>
                                    <tr>

                                        <td colspan="2" >Door Dimension</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><span class="fa fa-dashboard"></span> &nbsp;Width Min.
                                            /Max.</td>
                                        <td> 1000/12000mm</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><span class="fa fa-gears"></span>&nbsp;
                                            Height Min. /Max.</td>
                                        <td class="txt-clr">000/12000mm<br>
                                            Max. Area 90.0Sqm<br>
                                            Larger Dimension on request</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px"><i class="fa fa-steam"></i>&nbsp;Opening Dimension </td>
                                        <td>Vertical</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><i
                                                class="fa fa-fire-extinguisher"></i>&nbsp; Mounting Location</td>
                                        <td class="txt-clr">Inside/Outside</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><i class="fa fa-steam"></i>&nbsp;Opening/Closing Speed
                                        </td>
                                        <td>0.05m-0.2 m/sec</td>
                                    </tr>



                                </tbody>

                            </table>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Wind Resistance </td>
                                        <td >Up to 90 km/hr<br>*Higher Wind Pressure on
                                            Request</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><span></span> &nbsp;Motor Size</td>
                                        <td>600Kg / 800Kg / 1000Kg etc</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px" class="txt-clr"><span></span> &nbsp;Color</td>
                                        <td class="txt-clr">red, yellow, orange, blue, grey etc</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><span></span> &nbsp;Door Size</td>
                                        <td>To your Order</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px" class="txt-clr"><span></span>&nbsp; Control Panel</td>
                                        <td class="txt-clr">Danvers Brand,Including Inverter, PLC and other
                                            components.Size Option: 0.75KW</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><i></i>&nbsp;Drive System </td>
                                        <td>Germany SEW motor 380V or 220V/50Hz 0.75kw,1.1kw,1.5kw</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px" class="txt-clr"><i></i>&nbsp; Opening Way</td>
                                        <td class="txt-clr">Double-side radar, double-side press button, infrared
                                            sensors</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i></i>&nbsp;Dynamical systems</td>
                                        <td>Adopt high-tech motor reducer:0.75-2.2KW, which according to your door size
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <hr>
                 specification table 2 

                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>

                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Options Available
                                        </td>
                                        <td >Perforation In all above Slat profile<b>
                                                S.S Hood Cover made of sheet thickness 0.4mm</b></td>
                                    </tr>
                                    <tr>

                                        <td colspan="2" >Details for Automation
                                            System</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Control Type </td>
                                        <td >Deadman/Pulse Control</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Drive Type </td>
                                        <td>In Direct /Direct Connecting Drive</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Drive Unit </td>
                                        <td >Electric Motor(Imported Make) Single Phase/Three
                                            Phase</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Emergency Operation </td>
                                        <td>Manual operation by Hand chain</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Options Available
                                        </td>
                                        <td >Perforation In Slat profile<br>
                                            Rolling Grill<br>
                                            Wicket Door for pedestrian entry</td>
                                    </tr>

                                    <tr>
                                        <td><i class=""></i>&nbsp;Accessories(Optional)
                                            *can be integrated with advanced control </td>
                                        <td>
                                            Additional Push Button<br>
                                            Remote Control with receiver<br>
                                            Pull Cord Switch<br>

                                        </td>

                                    </tr>

                                    <tr>

                                        <td colspan="2" style="text-align: center;">Features</td>
                                    </tr>

                                    <tr>
                                        <td><i class=""></i>&nbsp;Certification </td>
                                        <td>CE VDE checked<br>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Protection Class </td>
                                        <td >IP54

                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Surface Finish </td>
                                        <td >Standard Off White Color<br>
                                            Special Color on request</td>
                                    </tr>
                                    <tr>

                                        <td colspan="2" >Details for Automation System</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Control Type </td>
                                        <td >Deadman/Pulse Control</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Drive Type </td>
                                        <td>In Direct /Direct Connecting Drive</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Drive Unit </td>
                                        <td >Electric Motor(Imported Make) Single Phase/Three
                                            Phase</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Emergency Operation
                                        </td>
                                        <td>Manual operation by Hand chain</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Options Available
                                        </td>
                                        <td >Up to 90 km/hr</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Wind Resistance </td>
                                        <td>Perforation In Slat profile<br>
                                            Rolling Grill<br>
                                            Wicket Door for pedestrian entry</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Accessories(Optional)
                                            *can be integrated with advanced control </td>
                                        <td >
                                            Additional Push Button<br>
                                            Remote Control with receiver<br>
                                            Pull Cord Switch<br>

                                        </td>

                                    </tr>

                                    <tr>

                                        <td colspan="2">Features</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Certification </td>
                                        <td >CE VDE checked<br>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Protection Class </td>
                                        <td> IP54

                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </blockquote>-->
        </div>

    </div>
</section>
