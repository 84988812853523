<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Gallery</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home </a></li>
                        <li>Gallery</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="blog-area ptb-100 feedback-area-two section-padding bg-custom-primary">
    <div class="container">
        <div class="section-title">
            <h2 class="h2-cus">Gallery</h2>
            <div class="container mt-5">
                <div class="row">
                    <div class="col-lg-12 col-md-12">
                        <ul class="nav nav-pills nav-pills-icons" role="tablist">
                            <!--
                                color-classes: "nav-pills-primary", "nav-pills-info", "nav-pills-success", "nav-pills-warning","nav-pills-danger"
                            -->
                            <li class="nav-item">
                                <a class="nav-link active" href="#all" role="tab" data-toggle="tab"
                                    (click)="turnWhite('')">
                                      <i class="material-icons">
                                        <img class="img-h" [hidden]="!blackAll"
                                            src="assets/img/gallery/dashboard_b.png" alt="Image" type="image/png">
                                        <img class="img-h" [hidden]="!whiteAll" src="assets/img/gallery/dashboard_w.png" alt="Image" type="image/png">
                                    </i>All
                                   <!-- <img src="assets/img/gallery/menu.png" class="img-all"><br>All -->

                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link" href="#hsd" role="tab" data-toggle="tab" (click)="turnWhite('hsd')">
                                    <i class="material-icons">
                                        <img class="img-h" [hidden]="!whitehsd"
                                            src="assets/img/icons/HSD/srt-white.PNG" alt="Image" type="image/png">
                                        <img class="img-h" [hidden]="!blackhsd" src="assets/img/icons/HSD/srt.png" alt="Image" type="image/png">
                                    </i>
                                    High Speed Doors
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link" href="#door" role="tab" data-toggle="tab"
                                    (click)="turnWhite('door')">
                                    <i class="material-icons">
                                        <img class="img-h" [hidden]="!whitesec"
                                            src="assets/img/icons/Doors/sectional-door-white.PNG" alt="Image" type="image/png">
                                        <img class="img-h" [hidden]="!blacksec"
                                            src="assets/img/icons/Doors/sectional-doors.png" alt="Image" type="image/png">
                                    </i>
                                     Doors
                                </a>
                            </li>

                            <li class="nav-item">
                                <a class="nav-link marleft" href="#shutters" role="tab" data-toggle="tab"
                                    (click)="turnWhite('shutter')">
                                    <i class="material-icons">
                                        <img class="img-h" [hidden]="!whiterol"
                                            src="assets/img/icons/Shutters/Aluminium-white.PNG" alt="Image" type="image/png">
                                        <img class="img-h" [hidden]="!blackrol"
                                            src="assets/img/icons/Shutters/Aluminium.png" alt="Image" type="image/png">
                                    </i>
                                   Shutters
                                </a>
                            </li>


                            <li class="nav-item">
                                <a class="nav-link mar-left" href="#gates" role="tab" data-toggle="tab"
                                    (click)="turnWhite('gate')">
                                    <i class="material-icons">
                                        <img class="img-h" [hidden]="!whiteslide"
                                            src="assets/img/icons/Gates/sliding-gate-white.PNG" alt="Image" type="image/png">
                                        <img class="img-h" [hidden]="!blackslide"
                                            src="assets/img/icons/Gates/sliding-gate.png" alt="Image" type="image/png">
                                    </i>
                                     Automated Gates
                                </a>
                            </li>
                            <li class="nav-item">
                                <a class="nav-link " href="#docks" role="tab" data-toggle="tab"
                                    (click)="turnWhite('dock')">
                                    <i class="material-icons">
                                        <img class="img-h" [hidden]="!whitedock"
                                            src="assets/img/icons/Docks/dock-leveler-white.PNG" alt="Image" type="image/png">
                                        <img class="img-h" [hidden]="!blackdock"
                                            src="assets/img/icons/Docks/dock-levellers.png" alt="Image" type="image/png">
                                    </i>
                                    Docks
                                </a>
                            </li>
                            <!-- <li class="nav-item">
                                <a class="nav-link" href="#swing" role="tab" data-toggle="tab"
                                    (click)="turnWhite('swing')">
                                    <i class="material-icons">
                                        <img class="img-h" [hidden]="!whiteswing"
                                            src="assets/img/icons/Gates/swing-gate-white.PNG">
                                        <img class="img-h" [hidden]="!blackswing"
                                            src="assets/img/icons/Gates/swing-gate.png">
                                    </i>
                                    Swing Gates
                                </a>
                            </li> -->
                        </ul>
                        <div class="tab-content tab-space pt-70">
                            <div class="tab-pane active" id="all">
                                <div class="row">
                                    <div class="col-sm-3"  *ngFor="let image of allalbums; let i=index">
                                            <img [src]="image.thumb" (click)="openAll(i)" alt="Image" type="image/png" />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="hsd">
                                <div class="row">
                                    <div class="col-sm-3"  *ngFor="let image of hsdalbums; let i=index">
                                            <img [src]="image.thumb" (click)="openHsd(i)" alt="Image" type="image/png" />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="door">
                                <div class="row">
                                    <div class="col-sm-3"  *ngFor="let image of dooralbums; let i=index">
                                            <img ng-show="image.thumb" [src]="image.thumb" (click)="openDoor(i)" alt="Image" type="image/png" />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="shutters">
                                <div class="row">
                                    <div class="col-sm-3"  *ngFor="let image of shutteralbums; let i=index">
                                            <img [src]="image.thumb" (click)="openShutter(i)" alt="Image" type="image/png" />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="gates">
                                <div class="row">
                                    <div class="col-sm-3"  *ngFor="let image of gatesalbums; let i=index">
                                            <img [src]="image.thumb" (click)="openGate(i)" alt="Image" type="image/png" />
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane" id="docks">
                                <div class="row">
                                    <div class="col-sm-3"  *ngFor="let image of docalbums; let i=index">
                                            <img [src]="image.thumb" (click)="openDock(i)" alt="Image" type="image/png" />
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="tab-pane" id="swing">
                                <div class="row">
                                    <div class="col-sm-3"  *ngFor="let image of gatesalbums; let i=index">
                                            <img [src]="image.thumb" (click)="openDoor(i)" />
                                    </div>
                                </div>

                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
