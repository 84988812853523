import { Component, OnInit } from '@angular/core';
import { MetaService } from '../../../services/meta-service.service';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
@Component({
    selector: 'app-gates',
    templateUrl: './gates.component.html',
    styleUrls: ['./gates.component.scss']
})
export class GatesComponent implements OnInit {
    page: any;
    slidingwhite = true;
    slidingblack = false;

    telescopicblack = true;
    telescopicwhite = false;

    cantileverblack = true;
    cantileverwhite = false;

    retractableblack = true;
    retractablewhite = false;

    swingblack = true;
    swingwhite = false;

    boomblack = true;
    boomwhite = false;

    curveblack = true;
    curvewhite = false;

    bifoldblack = true;
    bifoldwhite = false;

    constructor(private route: ActivatedRoute, private metaService: MetaService, private location: Location) { }

    ngOnInit(): void {
        this.metaService.setCanonicalURL();
        this.route.queryParams.subscribe(params => {
            this.page = params.page;
        });
    }

    turnWhite(imgname) {
        if (imgname === 'sliding') {
            this.slidingwhite = true;
            this.slidingblack = false;

            this.telescopicblack = true;
            this.telescopicwhite = false;

            this.cantileverblack = true;
            this.cantileverwhite = false;

            this.retractableblack = true;
            this.retractablewhite = false;

            this.swingblack = true;
            this.swingwhite = false;

            this.boomblack = true;
            this.boomwhite = false;

            this.curveblack = true;
            this.curvewhite = false;

            this.bifoldblack = true;
            this.bifoldwhite = false;
            this.location.replaceState('gates?page=sliding');
        } else if (imgname === 'telesocpic') {
            this.slidingwhite = false;
            this.slidingblack = true;
            // this.slidingwhite = false;
            // this.slidingblack = true;

            this.telescopicblack = false;
            this.telescopicwhite = true;

            this.cantileverblack = true;
            this.cantileverwhite = false;

            this.retractableblack = true;
            this.retractablewhite = false;

            this.swingblack = true;
            this.swingwhite = false;

            this.boomblack = true;
            this.boomwhite = false;

            this.curveblack = true;
            this.curvewhite = false;

            this.bifoldblack = true;
            this.bifoldwhite = false;
            this.location.replaceState('gates?page=telescopic');
        } else if (imgname === 'cantilever') {
            this.slidingwhite = false;
            this.slidingblack = true;

            this.telescopicblack = true;
            this.telescopicwhite = false;

            this.cantileverblack = false;
            this.cantileverwhite = true;

            this.retractableblack = true;
            this.retractablewhite = false;

            this.swingblack = true;
            this.swingwhite = false;

            this.boomblack = true;
            this.boomwhite = false;

            this.curveblack = true;
            this.curvewhite = false;

            this.bifoldblack = true;
            this.bifoldwhite = false;
            this.location.replaceState('gates?page=cantilever');
        } else if (imgname === 'retractable') {
            this.slidingwhite = false;
            this.slidingblack = true;


            this.telescopicblack = true;
            this.telescopicwhite = false;

            this.cantileverblack = true;
            this.cantileverwhite = false;

            this.retractableblack = false;
            this.retractablewhite = true;

            this.swingblack = true;
            this.swingwhite = false;

            this.boomblack = true;
            this.boomwhite = false;

            this.curveblack = true;
            this.curvewhite = false;

            this.bifoldblack = true;
            this.bifoldwhite = false;
            this.location.replaceState('gates?page=retractable');
        } else if (imgname === 'swing') {
            this.slidingwhite = false;
            this.slidingblack = true;


            this.telescopicblack = true;
            this.telescopicwhite = false;

            this.cantileverblack = true;
            this.cantileverwhite = false;

            this.retractableblack = true;
            this.retractablewhite = false;

            this.swingblack = false;
            this.swingwhite = true;

            this.boomblack = true;
            this.boomwhite = false;

            this.curveblack = true;
            this.curvewhite = false;

            this.bifoldblack = true;
            this.bifoldwhite = false;
            this.location.replaceState('gates?page=swing');
        } else if (imgname === 'boom') {
            this.slidingwhite = false;
            this.slidingblack = true;


            this.telescopicblack = true;
            this.telescopicwhite = false;

            this.cantileverblack = true;
            this.cantileverwhite = false;

            this.retractableblack = true;
            this.retractablewhite = false;

            this.swingblack = true;
            this.swingwhite = false;

            this.boomblack = false;
            this.boomwhite = true;

            this.curveblack = true;
            this.curvewhite = false;

            this.bifoldblack = true;
            this.bifoldwhite = false;
            this.location.replaceState('gates?page=boom');
        } else if (imgname === 'curve') {
            this.slidingwhite = false;
            this.slidingblack = true;


            this.telescopicblack = true;
            this.telescopicwhite = false;

            this.cantileverblack = true;
            this.cantileverwhite = false;

            this.retractableblack = true;
            this.retractablewhite = false;

            this.swingblack = true;
            this.swingwhite = false;

            this.boomblack = true;
            this.boomwhite = false;

            this.curveblack = false;
            this.curvewhite = true;

            this.bifoldblack = true;
            this.bifoldwhite = false;
            this.location.replaceState('gates?page=curve');
        }else if (imgname === 'bifold') {
            this.slidingwhite = false;
            this.slidingblack = true;


            this.telescopicblack = true;
            this.telescopicwhite = false;

            this.cantileverblack = true;
            this.cantileverwhite = false;

            this.retractableblack = true;
            this.retractablewhite = false;

            this.swingblack = true;
            this.swingwhite = false;

            this.boomblack = true;
            this.boomwhite = false;

            this.curveblack = true;
            this.curvewhite = false;

            this.bifoldblack = false;
            this.bifoldwhite = true;
            this.location.replaceState('gates?page=bifold');
        }
        else {
            this.slidingwhite = true;
            this.slidingblack = false;

            this.telescopicblack = true;
            this.telescopicwhite = false;

            this.cantileverblack = true;
            this.cantileverwhite = false;

            this.retractableblack = true;
            this.retractablewhite = false;

            this.swingblack = true;
            this.swingwhite = false;

            this.boomblack = true;
            this.boomwhite = false;

            this.curveblack = true;
            this.curvewhite = false;

            this.bifoldblack = true;
            this.bifoldwhite = false;
        }

    }
}
