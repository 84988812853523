<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Aluminium Shutters</h2>
            <p>The Rolling Shutters made of aluminum is called Aluminum Rolling Shutters. Aluminum Shutters are ideal for all
                areas of your home, showrooms and also for offices. They are commonly used in various commercial, industrial, and residential settings. Aluminium rolling shutters offer several benefits such as security, durability, and aesthetic appeal</p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/products/shutters/Alumi01.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/Alumi0.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/Alumi1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/Alumi2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/Alumi3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">

                </div>

                <div class="article-content" align="justify">
                    <h3>About Aluminium Shutter</h3>
                    <p>They can be used internally and externally, and are perfect for your alfresco and outdoor
                        living areas. If you are considering exterior shutters for your home, office or showroom
                        then our aluminum shutters are the most durable and suitable option for you.</p>

                    <ul class="feat" style="line-height:180%">
                        <li>Aluminum shutters are almost available in the double wall with and without insulation
                        </li>
                        <li>Remote shutters and aluminum shutters are suitable for internal applications where
                            aesthetics are more of focus.</li>
                        <li>Available in the adonized finish, Natural Aluminum finish or powder coating. </li>
                        <li>Rolling shutter automatic is self-sufficient and can be operated by just a single push
                            button.</li>
                        <li>Also, a fingertip control to open and close shutters of any size with the utmost ease
                            from a single push button switch or a radio remote control transmitter. </li>
                        <li>Our aluminum shutters have features that are in build high-performance drives,
                            intelligent and smart controls.</li>
                        <li>Drives have weight carrying a capacity of almost up to 2000kgs
                        </li>
                        <li>In case of any power failure, these shutters can be operated much more manually using a
                            hand crank or chain drive
                        </li>
                        <li>Our drives are inbuilt with Anti Drop device and also an additional electromagnetic
                            brake to make sure that the door does not free fall in any of the worst conditions.
                        </li>
                        
                    </ul>
                    <p>
                        These aluminum shutters can be used internally and externally and are completely perfect for
                        your alfresco and your outdoor living areas. If you are considering exterior shutters for
                        your home, office or showroom then our aluminum roller shutters are the most durable and
                        suitable option for you.
                    </p>


                </div>



            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>




                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>


            <table class="table table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Product : </th>
                    <th scope="col">Motorized Non-Insulated Aluminium Double walled Rolling Shutters</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" color="#fe9900">Shutter Dimension : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Width Min. /Max.</th>
                    <td>1000 / 4200mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Height Min. /Max.</th>
                    <td>1000 / 4500mm; Max. Area 18.0 Sqm</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening Dimension</th>
                    <td>Vertical</td>
                  </tr>
                  <tr>
                    <th scope="row">Mounting Location</th>
                    <td>Inside</td>
                  </tr>
                  <tr>
                    <th scope="row">Shutter Material</th>
                    <td>Aluminum Alloy</td>
                  </tr>
                  <tr>
                    <th scope="row">Maximum Width * Height:</th>
                    <td>6 * 8 m</td>
                  </tr>
                  <tr>
                    <th scope="row"> Type of box</th>
                    <td>165 185 205 250 300 350mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Thickness</th>
                    <td>1.2mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Surface Treatin</th>
                    <td>Powder Coating</td>
                  </tr>
                  <tr>
                    <th scope="row">The sevice life</th>
                    <td>can be used 15 years</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening/Closing Speed</th>
                    <td>0.05m-0.2 m/sec</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Up to 90 km/hr</td>
                  </tr>
                  
    
                  <tr>
                    <th scope="row" color="#fe9900">Shutter Structure : </th>
                    <td></td>
                  </tr>
    
                  <tr>
                    <th scope="row">Slat Material</th>
                    <td>Non Insulated Double Wall Aluminum profiled Slats</td>
                  </tr>
                  <tr>
                    <th scope="row">Slat Profile</th>
                    <td>Corrugation profile</td>
                  </tr>
                  <tr>
                    <th scope="row">Bottom Profile</th>
                    <td>Aluminum profile with Bottom Rubber sealing.
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Side Guide Profile</th>
                    <td>Aluminum profile with Rubber inserts on both ends.</td>
                  </tr>
                  <tr>
                    <th scope="row">Roller Shaft</th>
                    <td>M.S Tubular Pipe with flanges & machined shaft at the ends.
                        Pipe dia-100 to 300mm, as per the opening dimension.</td>
                  </tr>
                  <tr>
                    <th scope="row">Side Brackets</th>
                    <td>M.S plate 3mm to 10mm,as per the opening dimension.</td>
                  </tr>
                  <tr>
                    <th scope="row">Surface Finish</th>
                    <td>Standard Off White Color</td>
                  </tr>
    
                  <tr>
                    <th scope="row" color="#fe9900"></th>
                    <td>Special Color on request</td>
                  </tr>
    
                  <tr>
                    <th scope="row">Details for Automation System : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Control Type</th>
                    <td>Deadman / Pulse Control</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Type</th>
                    <td>Indirect / Direct Connecting Drive</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Unit</th>
                    <td>Electric Motor(Imported Make) Single Phase/Three Phase.</td>
                  </tr>
                  <tr>
                    <th scope="row">Emergency Operation	</th>
                    <td>Manual operation by Hand chain</td>
                  </tr>
                  <tr>
                    <th scope="row" color="#fe9900">Options Available</th>
                    <td>Up to 90 km/hr</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Perforation In Slat profile
                        Rolling Grill
                        Wicket Door for pedestrian entry</td>
                  </tr>
                  <tr>
                    <th scope="row">Accessories(Optional) </th>
                    <td>Additional Push Button
                        <br>Remote Control with receiver
                        <br>Pull Cord Switch
                        <br> *can be integrated with advanced control</td>
                  </tr>
                  <tr>
                    <th scope="row">Features : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Certification</th>
                    <td>CE VDE checked</td>
                  </tr>
                  <tr>
                    <th scope="row">Protection Class</th>
                    <td>IP54.</td>
                  </tr>
                  
                </tbody>
              </table>


          <!--  <blockquote class="flaticon-quote">
                <div class="row">
                    <div class="col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td class="aluf6"><span class="fa fa-thumbs-o-up"></span> &nbsp;Product</td>
                                        <td>Motorized Non insulated Aluminum Double Wall rolling Shutters
                                        </td>
                                    </tr>
                                    <tr>

                                        <td colspan="2">Door Dimension</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><span class="fa fa-dashboard"></span> &nbsp;Width Min. /Max.
                                        </td>
                                        <td> 1000/4200mm</td>
                                    </tr>
                                    <tr>
                                        <td class="txt-clr aluf7"><span class="fa fa-gears"></span>&nbsp; Height Min.
                                            /Max.</td>
                                        <td class="txt-clr aluf8">1000/4500mm
                                            Max. Area 18.0Sqm</td>
                                    </tr>

                                    <tr>
                                        <td class="aluf6"><i class="fa fa-steam"></i>&nbsp;Opening Dimension </td>
                                        <td>Vertical</td>
                                    </tr>
                                    <tr>
                                        <td class="txt-clr aluf7"><i class="fa fa-fire-extinguisher"></i>&nbsp; Mounting
                                            Location</td>
                                        <td class="txt-clr aluf8">Inside</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                     2 table 
                    <div class="col-md-6 wow fadeInUp animated animated" style="visibility: visible;">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>


                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><span></span>&nbsp; HPcs</td>
                                        <td class="txt-clr">HPcs indicates that Synergy 380 meets Marantec’s
                                            specifications for 1.1 HP garage door operators</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i></i>&nbsp;maintenance</td>
                                        <td style="">Operator and rail system are maintenance free</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><i></i>&nbsp; safety system</td>
                                        <td class="txt-clr">photo eye safety system is equipped with UV filters in order
                                            to eliminate interference created by sunlight</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px" class="txt-clr"><i></i>&nbsp; Mounting Location</td>
                                        <td class="txt-clr">Inside/outside</td>
                                    </tr>



                                    <tr>
                                        <td><i class=""></i>&nbsp;Opening/Closing Speed </td>
                                        <td>0.6m/sec to 1.5m/Sec</td>
                                    </tr>

                                    <tr>
                                        <td><i class=""></i>&nbsp;Wind Resistance </td>
                                        <td>Upto 90Km/hr</td>
                                    </tr>

                                    <tr>

                                        <td colspan="2">Door Structure</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Door Curtain </td>
                                        <td>Self extinguishing PVC Fabric-European Make.<br>
                                            Fabric standard complies with (DIN) EN 12641-2.</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>

                                    <tr>
                                        <td class="aluf6"><i></i>&nbsp;Opening/Closing Speed </td>
                                        <td>0.05m-0.2 m/sec</td>
                                    </tr>


                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Wind Resistance </td>
                                        <td class="fire1">Up to 90 km/hr</td>
                                    </tr>

                                    <tr>

                                        <td colspan="2">Door Structure</td>
                                    </tr>
                                    <tr>
                                        <td class="fire"><i class=""></i>&nbsp;Slat Material </td>
                                        <td class="fire1">Non Insulated Double Wall Aluminum profiled Slats</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Slat Profile </td>
                                        <td>Corrugation profile</td>
                                    </tr>
                                    <tr>
                                        <td class="fire"><i class=""></i>&nbsp;Bottom Profile </td>
                                        <td class="fire1">Aluminum profile with Bottom Rubber sealing</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Side Guide Profile </td>
                                        <td>Aluminum profile with Rubber inserts on both ends.</td>
                                    </tr>
                                    <tr>
                                        <td class="fire"><i class=""></i>&nbsp;Roller Shaft </td>
                                        <td class="fire1">M.S Tubular Pipe with flanges &amp; machined shaft at the
                                            ends.<br>
                                            Pipe dia-100 to 300mm, as per the opening dimension.</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Side Brackets </td>
                                        <td>M.S plate 3mm to 10mm,as per the opening dimension</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Surface Finish </td>
                                        <td class="fire1">Standard Off White Color<br>
                                            Special Color on request</td>
                                    </tr>
                                    <tr>

                                        <td colspan="2" class="fire">Details for Automation System</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Control Type </td>
                                        <td class="fire">Deadman/Pulse Control</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Drive Type </td>
                                        <td class="aluf8">In Direct /Direct Connecting Drive</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Drive Unit </td>
                                        <td class="aluf7">Electric Motor(Imported Make) Single Phase/Three Phase</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Emergency Operation </td>
                                        <td class="aluf8">Manual operation by Hand chain</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Options Available </td>
                                        <td>Up to 90 km/hr</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Wind Resistance </td>
                                        <td class="aluf8">Perforation In Slat profile<br>
                                            Rolling Grill<br>
                                            Wicket Door for pedestrian entry</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Accessories(Optional)
                                            *can be integrated with advanced control </td>
                                        <td>
                                            Additional Push Button<br>
                                            Remote Control with receiver<br>
                                            Pull Cord Switch<br>

                                        </td>

                                    </tr>

                                    <tr>

                                        <td colspan="2" class="aluf8">Features</td>
                                    </tr>

                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Certification </td>
                                        <td>CE VDE checked<br>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Protection Class </td>
                                        <td class="aluf8"> IP54

                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </blockquote> -->
        </div>

    </div>
</section>
<section class="bottomright">
    <div class="single-contact-info">
        <div class="row">
            <div class="col-sm-12 hddr-bg">
                <img src="../../../../assets/img/envelope.png"  alt="Image" type="image/png" class="cnt-img"> <span class="hddr"> Drop Message :</span>
            </div>
        </div>

        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" id="contactForm below-head">
                <div class="form-group">
                    <!-- <label>Name</label> -->
                    <input type="text"  placeholder="Name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <label>Phone</label> -->
                    <input type="text" placeholder="Phone" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Phone number is required</div>
                        <div *ngIf="f.phone.errors.pattern">Phone number is not valid</div>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <label>Email</label> -->
                    <input type="text"  placeholder="Email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <label>Subject</label> -->
                    <input type="hidden"  placeholder="Subject" value="Enquiry About Alluminium Shutters" formControlName="subject" class="form-control" />

                </div>
                <div class="form-group">
                    <!-- <label>Message</label> -->
                    <input type="text" placeholder="Message" formControlName="message" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" />
                    <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                        <div *ngIf="f.message.errors.required">Message is required</div>
                        <!-- <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div> -->
                    </div>
                </div>

            <div class="text-center">
                <button class="btn btn-secondary cust-btn">
                    <i class="fa fa-spinner fa-spin" style="font-size:24px" *ngIf="showSpinner === true"></i> <span  *ngIf="showSpinner === false">Send Message</span></button>
                    <button class="btn btn-secondary" type="reset" (click)="onReset()">Cancel</button>
            </div>
        </form>
    </div>
    </section>
