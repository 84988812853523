import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, NgForm } from '@angular/forms';
import { MailService } from '../../../services/mail.service';
import { MetaService } from '../../../services/meta-service.service';
declare let $: any;
declare function gtag_report_conversion(): any;
@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

    registerForm: FormGroup;
    submitted = false;
    cars: any[];
    success: string;
    error: any;
  showSpinner = false;
  constructor(private metaService: MetaService, private formBuilder: FormBuilder, private contact: MailService) { }

  ngOnInit() {
    this.metaService.setCanonicalURL();
    this.registerForm = this.formBuilder.group({
        name: ['', Validators.required],
        phone: [null, [Validators.required, Validators.pattern('^((\\+91-?)|0)?[0-9]{10}$')]],
        subject: ['', Validators.required],
        message: ['', Validators.required],
        // validates date format yyyy-mm-dd
        // dob: ['', [Validators.required, Validators.pattern(/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/)]],
        email: ['', [Validators.required, Validators.email]],
       // password: ['', [Validators.required, Validators.minLength(6)]],
       // confirmPassword: ['', Validators.required],
       // acceptTerms: [false, Validators.requiredTrue]
    });
}

// convenience getter for easy access to form fields
get f() { return this.registerForm.controls; }

onSubmit() {
    gtag_report_conversion();
    this.submitted = true;
  //  this.showSpinner = true;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
        return;
    }

    // this.registerForm.setValue({
    //     subject: 'Enquiry About Alluminium Shutters',
    //  });
    this.contact.PostMessage(this.registerForm.value).subscribe(
        (res: any) => {
          this.showSpinner = false;
          console.log(res);
          if (res) {
            alert('Message sent Successfully. Our team will contact you soon..!');
            this.onReset();
          } else {
            alert('Something went wrong. Please try after sometime');
            this.onReset();
          }


        },
        error => {
          this.onReset();
          if (error) {
            alert('Something went wrong. Please try after sometime');
            // this.tostarService.error('Invalid Username or password!', 'Authentication Failed');
          } else {
            alert('Something went wrong. Please try after sometime');

          }

        }
      );
    }

onReset() {
    this.submitted = false;
    this.registerForm.reset();
   // this.registerForm.setValue({name: '', phone: '', email: '', subject: '', message: ''});
}
}
