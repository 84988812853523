<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Polycarbonate Shutters</h2>
            <p>Polycarbonate is a manmade material that's especially suitable for roller shutters. Like glass, it's transparent, but unlike glass, it's very hard to break.</p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/products/shutters/polyrs4.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/polyrs0.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/polyrs1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/polyrs2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/polyrs3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                </div>

                    <div class="article-content" align="justify">
                        <h3>About Polycarbonate Shutter</h3>
                        <p>
                            The combination of high visibility with exceptional strength make our polycarbonate transparent roller shutter doors ideal for shops that want to keep valuable goods on display, such as jewelers. Also, these shutters are a good choice for premises that require a higher level of security, for example banks.
                        </p>
                        <ul class="feat" style="line-height:180%">
                            <li>Glass like transparent but stronger in material.
                            </li>
                            <li>These rolling shutters can be used as both external and internal doors.
                            </li>
                            <li>High level protection.</li>
                            <li>These rolling shutters give longer product life.</li>
                            <li>Longer product life, fade proof.
                            </li>
                            <li>We can customize as per client requirement.</li>
                        </ul>
                           <h3>Types of Designs</h3>
                           <li>Clear Vision</li>
                           <li>Glass Look</li>
<br>
<p>Constructed with different thickness of polycarbonate (usually 5-6mm thick), they provide great resistance against sever weather and protection against UV.
<br>
Professional anti-rust technology, which makes the roller door beautiful, durable and anti-add and base.
<br>The surface is smooth, easy to clean, the surface of the wafer is very smooth, easy to clean, and with a high-quality stainless steel tube, the entire gate will not rust.
</p>
                    </div>


            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>

            <table class="table table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Product : </th>
                    <th scope="col">Motorized Polycarbonate Rolling Shutter</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" color="#fe9900">Shutter Dimension : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Width Min. /Max.</th>
                    <td>4000mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Height Min. /Max.</th>
                    <td>4000mm 
                        <br> Large Dimension on Request
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Curtain material</th>
                    <td>.2mm thickness PVC</td>
                  </tr>
                  <tr>
                    <th scope="row">Curtain Color</th>
                    <td>Yellow, Blue, Orange, Grey etc.,</td>
                  </tr>
                  <tr>
                    <th scope="row">Shutter Frame</th>
                    <td>2mm thickness galvanized steel door frame</td>
                  </tr>
                  <tr>
                    <th scope="row">Frame size</th>
                    <td>120mm X 120mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening speed</th>
                    <td>1.2m/s</td>
                  </tr>
                  <tr>
                    <th scope="row">Options Available</th>
                    <td>Perforation In all above Slat profile S.S Hood Cover made of sheet thickness 0.4mm</td>
                  </tr>
                  
                  <tr>
                    <th scope="row">Details for Automation System : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Control Type</th>
                    <td>Deadman / Pulse Control</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Type</th>
                    <td>Indirect / Direct Connecting Drive</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Unit</th>
                    <td>Electric Motor(Imported Make) Single Phase/Three Phase.</td>
                  </tr>
                  <tr>
                    <th scope="row">Emergency Operation	</th>
                    <td>Manual operation by Hand chain</td>
                  </tr>
                  <tr>
                    <th scope="row" color="#fe9900">Options Available</th>
                    <td>Up to 90 km/hr</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Perforation In Slat profile
                        Rolling Grill
                        Wicket Door for pedestrian entry</td>
                  </tr>
                  <tr>
                    <th scope="row">Features : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Certification</th>
                    <td>CE VDE checked</td>
                  </tr>
                  <tr>
                    <th scope="row">Protection Class</th>
                    <td>IP54.</td>
                  </tr>
                  
                </tbody>
              </table>


<!-- 
            <blockquote class="flaticon-quote">
                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                </tbody><tbody>
                                    <tr>
                                        <td style="width:120px"><span class="fa fa-thumbs-o-up"></span> &nbsp;Curtain material</td>
                                        <td>.2mm thickness PVC</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><span class="fa fa-group"></span> &nbsp;Curtain color</td>
                                        <td >yellow,blue,orange,grey,etc.</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><span class="fa fa-dashboard"></span> &nbsp;Door frame</td>
                                        <td>2mm thickness galvanized steel door frame</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><span class="fa fa-gears"></span>&nbsp; Frame size</td>
                                        <td >120mm X 120mm </td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px"><i class="fa fa-steam"></i>&nbsp;Opening speed </td>
                                        <td>1.2m/s</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class="fa fa-fire-extinguisher"></i>&nbsp; Average operation cycles</td>
                                        <td > 150,000per/year(around 400/day)</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><i class="fa fa-steam"></i>&nbsp;Max door size</td>
                                        <td>4000mm(W)X4000mm(H)</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class="fa fa-steam"></i>&nbsp;Opening ways</td>
                                        <td >push button,pull cord,radar,induction loop,remote...(optional) </td>
                                    </tr>




                                </tbody>

                            </table>

                        </div>
                    </div>


                </div>
                <hr>
                specification table 2 

                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>

                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Options Available
                                        </td>
                                        <td >Perforation In all above Slat profile<b>
                                                S.S Hood Cover made of sheet thickness 0.4mm</b></td>
                                    </tr>
                                    <tr>

                                        <td colspan="2" >Details for Automation
                                            System</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Control Type </td>
                                        <td >Deadman/Pulse Control</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Drive Type </td>
                                        <td>In Direct /Direct Connecting Drive</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Drive Unit </td>
                                        <td >Electric Motor(Imported Make) Single Phase/Three
                                            Phase</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Emergency Operation </td>
                                        <td>Manual operation by Hand chain</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Options Available
                                        </td>
                                        <td >Perforation In Slat profile<br>
                                            Rolling Grill<br>
                                            Wicket Door for pedestrian entry</td>
                                    </tr>

                                    <tr>
                                        <td><i class=""></i>&nbsp;Accessories(Optional)
                                            *can be integrated with advanced control </td>
                                        <td>
                                            Additional Push Button<br>
                                            Remote Control with receiver<br>
                                            Pull Cord Switch<br>

                                        </td>

                                    </tr>

                                    <tr>

                                        <td colspan="2" style="text-align: center;">Features</td>
                                    </tr>

                                    <tr>
                                        <td><i class=""></i>&nbsp;Certification </td>
                                        <td>CE VDE checked<br>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Protection Class </td>
                                        <td >IP54

                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Surface Finish </td>
                                        <td >Standard Off White Color<br>
                                            Special Color on request</td>
                                    </tr>
                                    <tr>

                                        <td colspan="2" >Details for Automation System</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Control Type </td>
                                        <td >Deadman/Pulse Control</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Drive Type </td>
                                        <td>In Direct /Direct Connecting Drive</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Drive Unit </td>
                                        <td >Electric Motor(Imported Make) Single Phase/Three
                                            Phase</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Emergency Operation
                                        </td>
                                        <td>Manual operation by Hand chain</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Options Available
                                        </td>
                                        <td >Up to 90 km/hr</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Wind Resistance </td>
                                        <td>Perforation In Slat profile<br>
                                            Rolling Grill<br>
                                            Wicket Door for pedestrian entry</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Accessories(Optional)
                                            *can be integrated with advanced control </td>
                                        <td >
                                            Additional Push Button<br>
                                            Remote Control with receiver<br>
                                            Pull Cord Switch<br>

                                        </td>

                                    </tr>

                                    <tr>

                                        <td colspan="2">Features</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Certification </td>
                                        <td >CE VDE checked<br>

                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Protection Class </td>
                                        <td> IP54

                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </blockquote>
        -->
        </div>

    </div>
</section>
