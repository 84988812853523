<section class="blog-details-area ">
    <br>
    <div class="row">

        <div class="col-sm-12 text-center">
            <ul class="nav nav-pills mb-3 text-center bordernav" id="pills-tab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link anav" id="srt-srt-tab" data-toggle="pill" href="#srt-srt" role="tab"
                        aria-controls="srt-srt" aria-selected="false">EFA SRT®</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link active anav" id="srt-ms-tab" data-toggle="pill" href="#srt-ms" role="tab"
                        aria-controls="srt-ms" aria-selected="true">EFA SRT® MS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="srt-eco-tab" data-toggle="pill" href="#srt-eco" role="tab"
                        aria-controls="srt-eco" aria-selected="false">EFA SRT® ECO</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="srt-soft-touch-tab" data-toggle="pill" href="#srt-soft-touch"
                        role="tab" aria-controls="srt-soft-touch" aria-selected="false">EFA SRT® ST</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="srt-ec-tab" data-toggle="pill" href="#srt-ec" role="tab"
                        aria-controls="srt-ec" aria-selected="false">EFA SRT® EC</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="srt-ex-tab" data-toggle="pill" href="#srt-ex" role="tab"
                        aria-controls="srt-ex" aria-selected="false">EFA SRT® EX</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="srt-mtl-tab" data-toggle="pill" href="#srt-mtl" role="tab"
                        aria-controls="srt-mtl" aria-selected="false">EFA SRT® MTL</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="srt-cr-efficient-tab" data-toggle="pill" href="#srt-cr-efficient"
                        role="tab" aria-controls="srt-cr-efficient" aria-selected="false">EFA SRT® CR Efficient</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="srt-cr-premium-tab" data-toggle="pill" href="#srt-cr-premium"
                        role="tab" aria-controls="srt-cr-premium" aria-selected="false">EFA SRT® CR Premimum</a>
                </li>
            </ul>
        </div>

    </div>
    <br>

    <div class="tab-content" id="pills-tabContent">

        <div class="tab-pane fade show " id="srt-srt" role="tabpanel" aria-labelledby="srt-srt-tab">
            <div class="container">
                <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>EFA-SRT®</h2>
                    <p>The EFA-SRT® is a real allrounder! It can be used as inside door or as additional hall closure.
                        For each opening which is extremely often passed by forklift trucks, the high-speed roll-up door
                        /from EFAFLEX is a high-quality solution.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SRT/1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/3.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/4.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/5.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SRT®</h3>
                                <p>Robust plastic ropes and tension springs in the side members of the door hold the
                                    curtain under tension when opening and closing.
                                    Wind, negative pressure, heat or cold and draft air cannot affect the curtain: It is
                                    always in shape and can be wound up and
                                    unwound without any problems and at any time. The extraordinarily heavy-duty
                                    hangings are available fully transparent or in different colours.</p>
                                <p><strong>Special Curtains</strong></p>
                                <p>Three Special Curtains:</p>
                                <p>In addition to the standard curtain, which is made of silicone-free PVC, EFAFLEX also
                                    offers three special curtains as option for the EFA-SRT®, which can be used for
                                    different applications. All three curtains are made of a transversely stable
                                    material and are silicone-free.
                                    <br>
                                    <strong>Special curtain 1 : </strong> is made of PVC-coated polyester. It was
                                    developed for the field of food logistics and is FDA-approved for the transport of
                                    dry food.
                                    <br>
                                    <strong>Special curtain 2 : </strong> is made of urethane-impregnated polyester
                                    material and is the best option if a hardly flammable curtain is needed for the
                                    EFA-SRT®.
                                    <br>
                                    <strong>Special curtain 3 : </strong> was developed for the use in painting plants
                                    and is therefore free of substances which are detrimental to paint adhesion. It is
                                    also made of urethane-impregnated polyester material.
                                </p>

                                <strong>EFA-SRT® at a glance</strong>
                                <ul class="feat">
                                    <li>Clean room version available.</li>
                                    <li>Hygiene version available.</li>
                                    <li>Heavy-duty inside door.</li>
                                    <li>With crash protection as option.</li>
                                    <li>Opening up to 2.0 m/s.</li>
                                    <li>Closing up to 0.75 m/s.</li>
                                    <li>Up to 150,000 operating cycles p.a.</li>
                                    <li> Standard sizes up to w=6,000mm x h=6,000mm.</li>

                                </ul>
<br>
                                <strong>EFA-SRT® Variants</strong>
                                <ul class="feat">
                                    <li>EFA-SRT® MS</li>
                                    <li>EFA-SRT® ECO</li>
                                    <li>EFA-SRT® ST</li>
                                    <li>EFA-SRT® EC</li>
                                    <li>EFA-SRT® EX</li>
                                    <li>EFA-SRT® MTL</li>
                                    <li>EFA-SRT® CR Efficient</li>
                                    <li>EFA-SRT® CR Premium</li>

                                </ul>
                            </div>
                    </div>

                    <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <!--
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST® Efficient</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">2 - 4
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">2
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">20
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">1.7
               </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>5130
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">0.5
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">0.5
                    <br>0.5
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>•
                        <br>-
                        <br>◦
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">150,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> ◦/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>
-->
            </div>
        </div>

        <div class="tab-pane fade show active" id="srt-ms" role="tabpanel" aria-labelledby="srt-ms-tab">
            <div class="container">
                <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>EFA-SRT® MS</h2>
                    <p>Due to its space saving, compact design the patented machine protection doors EFA-SRT® MS high
                        speed roll-up door
                        satisfies the requirements for optimum integration into safety guard applications. The EFA-SRT®
                        MS comes with a
                        fully transparent door curtain equipped with warning stripes as standard; the hard wearing
                        fabric is transversely
                        stable and offered in a range of fabric colours finishes.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SRT/ms1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/ms2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/ms3.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/ms4.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/ms5.jpg" alt="Image" width="100%" height="auto">
                        </div>
                        <!-- <video [muted]="true" autoplay playsinline loop width="100%">
                            <source src="assets/videos/efaflex1.mp4" type="video/mp4" >
                        </video> -->
                        <div class="article-content">
                            <h3>About EFA-SRT® MS</h3>
                            <p>Push-on gear unit with absolute encoder, for machine protection doors EFA-SRT® MS
                                including gear unit failure protection.</p>

                            <p>Safety end-limit switches Cat. 4 / Pl »e« in allowance with DIN EN ISO 13849-1 fitted
                                with transparent covering re movable for adjust-ment and replacement.</p>

                            <p>Safety contact strip in the main closing edge, in combination with an additional
                                light barrier. For safety reasons patented machine protection door EFA-SRT® MS.</p>

                            <strong>EFA-SRT® MS at a glance</strong>
                            <ul class="feat">
                                <li>Functional safety performance level »d«</li>
                                <li>Maximum speed up to 1.8 m / s</li>
                                <li>Door curtain made from transparent flexible PVC</li>
                                <li>Special curtains are available on request</li>
                                <li>Up to 250,000 cycles per year</li>
                                <li>Maximum of 7 cycles per minute</li>
                                <li>Life cycle 12 years</li>
                                <li>Standard sizes of up to w=5,000mm x h=3,500mm</li>

                            </ul>
                            <p>
                                EFAFLEX high-speed doors of course also guarantee highest standard with regard to
                                safety. As the leading manufacturer of high-speed doors, EFAFLEX is aware of its
                                responsibility! Therefore, we aim at also doing pioneering work with regard to
                                safety. Our systems offer you considerably more safety – for persons as well as for
                                material loaded and the door itself.
                            </p>


                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>
                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SRT® MS</th>
                 <th> </th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                   <td scope="col">S
                </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>-
                </td>
                <td scope="col">•
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">0
               </td>
               <td scope="col">0
            </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
               <td scope="col">fulfilled
            </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
               <td scope="col">-
            </td>            
        </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
               <td scope="col">0
            </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">12
               </td>
               <td scope="col">12
            </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">-
               </td>
               <td scope="col">-
            </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">3000
                    <br>3000
               </td>
               <td scope="col">5000
                <br>3500
           </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">1.8
               </td>
               <td scope="col">1.8
            </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.3
                    <br>0.6
                    <br>-
               </td>
               <td scope="col">1.3
                <br>0.6
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">-
                    <br>-
                    <br>-
               </td>
               <td scope="col">-
                <br>-
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
               </td>
               <td scope="col">•
                <br>-
                <br>◦
           </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>•
                        <br>◦/◦
               </td>
               <td scope="col">-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>•
                <br>◦/◦
       </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
               <td scope="col">B2
            </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
               <td scope="col">-
            </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">250,000
               </td>
               <td scope="col">250,000
            </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">◦
                    <br>-
                    <br>•
                    <br>•
               </td>
               <td scope="col">◦
                <br>-
                <br>•
                <br>•
           </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">◦
                    <br>•
               </td>
               <td scope="col">◦
                <br>•
           </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
               <td scope="col">-
            </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">-
                    <br>•
               </td>
               <td scope="col">-
                <br>•
           </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">◦HSO
                    <br>•
                    <br>•
                    <br>◦
                    <br>◦HSO
               </td>
               <td scope="col">◦HSO
                <br>•
                <br>•
                <br>◦
                <br>◦HSO
           </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/◦
               </td>
               <td scope="col"> -/◦
            </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
               <td scope="col">•
            </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade show " id="srt-eco" role="tabpanel" aria-labelledby="srt-eco-tab">
            <div class="container">
                <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>EFA-SRT® ECO</h2>
                    <p>The EFA-SRT® ECO convinces with its excellent price-performance ratio. Special structural
                        preparations are not necessary due to the
                        space-saving design, for example very slim side frames. Thus, the EFA-SRT® ECO can be applied in
                        more situations than any other door.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SRT/eco1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/eco2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/eco3.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/eco4.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/eco5.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SRT® ECO</h3>
                                <p>A cost-intensive guide at the sides of the curtain prevents undesired air exchange
                                    which is caused by pressure differences and drafts.
                                    The standard curtain is fully transparent and is provided with warning strips as
                                    standard. Coloured curtains are also available without
                                    any problems. The guide profiles made of sendzimir galvanized steel are also
                                    available in stainless steel.</p>
                                <br>
                                <strong>Ideal for materials-handling Technology</strong>
                                <p>Due to its space-saving design (e.g. very slim side frames), the EFA-SRT® ECO is
                                    absolutely suitable for being used with runways and automatic transportation
                                    systems. It guarantees a secure and fast material flow.</p>
                                <strong>Clear passage also in case of power failure</strong>
                                <p>During opening and closing, the weight of the door is balanced by maintenance-free
                                    and wear-free counter weights. During a power failure, a quick and easy emergency
                                    opening is possible. For this purpose, only operate the pull cord, this releases the
                                    brake and the door opens automatically.</p>

                                <h4>EFA-EAS® Crash Protection</h4>
                                <strong>Reliable safety</strong>
                                <p>Your high-speed roll-up door can be equipped with the EFA-EAS® crash protection upon
                                    request. This protective equipment prevents expensive damages to the curtain in the
                                    case of a crash.

                                    During a crash, the bottom module is pressed out of its guides on both sides.
                                    Sensors signalise this to the control and stop the door movement immediately. The
                                    curtain and the closing edge can be returned in no time. Your door will be ready for
                                    operation again after a short time.</p>

                                <strong>If everything was so easy</strong>
                                <p>With the EAS-version, you avoid unprofitable standstill times and your logistic
                                    processes will become even more efficient. Moreover, the protective equipment
                                    reduces your repair costs. Decide in favour of EFA-EAS®; this will save time, money
                                    and a lot of trouble.</p>

                                <h3>XXL High-speed roll-up door EFA-SRT® ECO</h3>
                                <strong>Wider and Higher</strong>
                                <p>The BIG economic high-speed roll-up door with an excellent price-performance ratio.
                                    Now also available for clear opening widths up to 6000mm and clear heights up to
                                    7000mm. Extremely high opening speeds are guaranteed by its design with flexible
                                    curtains.</p>


                                <strong>Wind Resistance and Use</strong>
                                <p>The high-speed roll-up doors of the R Series are ideally suited for highly frequented
                                    permanent use. By means of counter weights in the legs the door system can be opened
                                    manually in emergency situations, or perhaps in case of power failure. Wind buttons
                                    at the outer edge of the curtains hold them safely in the guidance even in
                                    applications with wind, draught or pressure differences.</p>

                                <strong>EFA-SRT® ECO at a glance</strong>
                                <ul class="feat">
                                    <li>Ideal for materials-handling technology</li>
                                    <li>Space-saving design</li>
                                    <li>Excellent price-performance ratio</li>
                                    <li>With crash protection as option.</li>
                                    <li>Opening up to 2.0 m/s.</li>
                                    <li>Closing up to 1.0 m/s.</li>
                                    <li>Up to 150,000 operating cycles p.a.</li>
                                    <li> Standard sizes up to w=6,000mm x h=7,000mm.</li>

                                </ul>
                            </div>
                    </div>

                    <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SRT® ECO</th>
                 <th> </th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                   <td scope="col">S
                </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>-
                </td>
                <td scope="col">•
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">-
               </td>
               <td scope="col">1 - 2
            </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
               <td scope="col">fulfilled
            </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
               <td scope="col">0
            </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
               <td scope="col">1
            </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">12
               </td>
               <td scope="col">11
            </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">-
               </td>
               <td scope="col">-
            </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>4000
               </td>
               <td scope="col">6000
                <br>7000
           </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">2.0
               </td>
               <td scope="col">2.0
            </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.5
                    <br>0.75
                    <br>1.0
               </td>
               <td scope="col">1.5
                <br>0.6
                <br>1.0
           </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">-
                    <br>-
                    <br>-
               </td>
               <td scope="col">-
                <br>-
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
               </td>
               <td scope="col">•
                <br>◦
                <br>◦
           </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>•
                        <br>◦/◦
               </td>
               <td scope="col">-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>◦/•
       </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
               <td scope="col">B2
            </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Weight
               </td>
               <td scope="col">Weight
            </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">150,000
               </td>
               <td scope="col">150,000
            </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
                    <br>•
               </td>
               <td scope="col">•
                <br>-
                <br>◦
                <br>•
           </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>◦
           </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
               <td scope="col">-
            </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">◦
                    <br>•
                    <br>•
                    <br>◦
                    <br>◦
               </td>
               <td scope="col">◦
                <br>•
                <br>•
                <br>◦
                <br>◦
           </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/◦
               </td>
               <td scope="col"> -/◦
            </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
               <td scope="col">•
            </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade show " id="srt-soft-touch" role="tabpanel" aria-labelledby="srt-soft-touch-tab">
            <div class="container">
                <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>EFA-SRT® ST (Soft Touch)</h2>
                    <p>
                        EFA Soft Touch System (patent pending), all of your demands regarding a door curtain which
                        remains inherently stable
                        and unaffected by draft air and pressure differences, offering high speed, easy manual opening
                        if necessary as well
                        as collision and operator protection, are fulfi lled in a unique way. This innovative technology
                        combines the tensioning
                        of the door curtain and the weight counterbalance with a collision and crash detection in one
                        integrated system.
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                            <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                                style="visibility: visible;">
                                <img src="assets/img/products/hsd/SRT/st1.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SRT/st2.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SRT/st3.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SRT/st4.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SRT/st5.jpg" alt="Image" width="100%" height="auto">
                            </div>

                            <div class="article-content">
                                <h3>About EFA-SRT® Soft Touch</h3>
                                <p>The door system is equipped with a completely new collision protection and provides
                                    superior
                                    operational safety due to its flexible closing edge. If the door leaf should touch
                                    an obstacle
                                    or a person, the closing edge will easily bend in any direction – therefore, no
                                    additional safety
                                    contact strip is required. If in such a case, a certain extent of deformation is
                                    exceeded, the two
                                    snap-in connections at the bottom of the door are disconnected so that the door leaf
                                    can snap out of
                                    the guide pieces. Sensors detect this and transmit a „crash” signal to the control –
                                    the movement of
                                    the door is stopped immediately and an upwards movement of the door system is
                                    initiated without delay.

                                    In contrast to other constructions, the separated snap-in connections of the
                                    EFA-SRT® Soft Touch are reunited
                                    when they reach their lower end positions. This results in superior functionality,
                                    absolute operational safety
                                    and a legendary longevity. Recommended for applications with a high access frequency
                                    of staff.
                                </p>


                                <strong>EFA-SRT® ST(Soft Touch) at a glance</strong>
                                <ul class="feat">
                                    <li>With frequency converters.</li>
                                    <li>Control on the door frame.</li>
                                    <li>With crash protection as standard.</li>
                                    <li>Opening up to 2,0 m/s, Closing up to 0,75 m/s.</li>
                                    <li>Up to 150,000 operating cycles p.a.</li>
                                    <li>Standard sizes up to w=4,500mm x h=5,000mm</li>


                                </ul>



                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SRT® ST(Soft Touch)</th>
                 <th> </th>
                 <th> </th>
                 <th> </th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                   <td scope="col">S
                </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>-
                </td>
                <td scope="col">•
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">-
               </td>
               <td scope="col">-
            </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
               <td scope="col">fulfilled
            </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
               <td scope="col">0
            </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
               <td scope="col">0
            </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">12
               </td>
               <td scope="col">12
            </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">-
               </td>
               <td scope="col">-
            </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>4000
               </td>
               <td scope="col">4500
                <br>5000
           </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">2.0
               </td>
               <td scope="col">2.6
            </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.5
                    <br>0.75
                    <br>-
               </td>
               <td scope="col">2.0
                <br>1.0
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">-
                    <br>-
                    <br>-
               </td>
               <td scope="col">-
                <br>-
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
               </td>
               <td scope="col">•
                <br>◦
                <br>◦
           </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>◦/•
               </td>
               <td scope="col">-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>◦/•
       </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
               <td scope="col">B2
            </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
               <td scope="col">Spring
            </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">150,000
               </td>
               <td scope="col">150,000
            </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">◦
                    <br>•
                    <br>◦
                    <br>•
               </td>
               <td scope="col">•
                <br>-
                <br>-
                <br>•
           </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
               <td scope="col">-
            </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>-
                    <br>•
                    <br>◦
                    <br>◦
               </td>
                <td scope="col">-
                    <br>◦
                    <br>•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/-
               </td>
               <td scope="col"> -/◦
            </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
               <td scope="col">•
            </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade show " id="srt-ec" role="tabpanel" aria-labelledby="srt-ec-tab">
            <div class="container">
                <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>EFA-SRT® EC</h2>
                    <p>EFA-SRT® EC is the optimised solution for all internal passages with the highest hygienic
                        requirements, for example foods.The entire door design is made of stainless steel as a standard.
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                            <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                                style="visibility: visible;">
                                <img src="assets/img/products/hsd/SRT/ec1.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SRT/ec2.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SRT/ec3.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SRT/ec4.jpg" alt="Image" width="100%" height="auto">
                            </div>

                            <div class="article-content">
                                <h3>About EFA-SRT®EC</h3>
                                <p>The locking of the frame covers can be unlocked easily and the frames can be opened
                                    for cleaning. The entire inside is cleanable with the steam jet.
                                    Due to its space-saving design, the EFA-SRT® EC can also be used in restricted
                                    installation conditions.
                                    The cover of the winding shaft is slanted so that liquids can run off and nothing
                                    will drop on the driveway or onto the food. In no time, the cover can be turned
                                    upwards.
                                    Now you can easily clean the bottom side, and the winding shaft is reachable.
                                </p>
                                <strong>Weight Balance</strong>
                                <p>Every part of the EFA-SRT® EC is designed down to the last detail. The weight
                                    balancing system of our hygienic professionals through counterweights is protected
                                    by a special anti-corrosion coating.
                                    The electric and electronic parts of the microprocessors and frequency converters
                                    are housed in a V2A switch cabinet of protection class IP 65.</p>

                                <strong>EFA-SRT® EC at a glance</strong>
                                <ul class="feat">
                                    <li>Easy to clean.</li>
                                    <li>Space-saving design.</li>
                                    <li>Excellent price-performance ratio.</li>
                                    <li>In stainless steel design.</li>
                                    <li>Opening up to 2.0 m/s.</li>
                                    <li>Closing up to 0.75 m/s.</li>
                                    <li>Up to 150,000 operating cycles p.a.</li>
                                    <li> Standard sizes up to w=3,500mm x h=3,750mm.</li>

                                </ul>



                            </div>

                    </div>

                    <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SRT® EC</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">12
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">-
               </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>4000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">2.0
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.5
                    <br>0.75
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">-
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">-
                    <br>•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>◦
                        <br>◦/•
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">150,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">-
                    <br>-
                    <br>•
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>◦
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col">-/-
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade show " id="srt-ex" role="tabpanel" aria-labelledby="srt-ex-tab">
            <div class="container">
                <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>EFA-SRT® EX</h2>
                    <p>EFA-SRT® EX for internal use comprises all the typical basic features of modern door technology,
                        such as dynamic door tension and emergency opening via spring systems in the frame members. A
                        hand lever on the frame member allows quick emergency opening of the door system in the event of
                        a power failure. The door leaf is formed by a black, non-transparent special ATEX curtain.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SRT/ex1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/ex2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/ex3.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SRT® EX</h3>
                                <p>The EFA-SRT® EX has been developed in accordance with ATEX Directive 2014/34/EU
                                    specifically for use in potentially explosive atmospheres for Zone 1 (II 2G IIB T4
                                    GbX), Zone 2 (II 3G IIB T4 GcX), Zone 21 (II 2D IIIB 135°C DbX) and Zone 22 (II 3D
                                    IIIB 135°C DcX). The control cabinet must be installed outside the explosion
                                    protection zone.</p>
                                <strong>EFA-SRT® EX at a glance</strong>
                                <ul class="feat">
                                    <li>For use in explosion protection zones 1, 2, 21 and 22.</li>
                                    <li>Up to 150,000 operating cycles p.a.</li>
                                    <li>Heavy-duty inside door.</li>
                                    <li>Opening up to 1.0 m/s.</li>
                                    <li>Closing up to 0.5 m/s.</li>
                                    <li> Standard sizes up to w=4,000mm x h=4,000mm.</li>

                                </ul>
                            </div>

                    </div>

                    <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SRT® EX</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">12
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">-
               </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>4000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">1.0
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.0
                    <br>0.5
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">-
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>black without vision panel
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">150,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">-
                    <br>-
                    <br>•
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">◦
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade show " id="srt-mtl" role="tabpanel" aria-labelledby="srt-mtl-tab">
            <div class="container">
                <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>EFA-SRT® MTL</h2>
                    <p> EFA-SRT® MTL door has been designed for all applications within intensive logistics processes
                        and is particularly suitable for both commercial and industrial use in closed areas that are not
                        exposed to wind and/or weather conditions. With a large number of applications, equipment and
                        combination options, the high-speed roll-up door (SRT) spans all conceivable areas in the
                        eponymous product portfolio “Material – Transport – Logistics” (MTL).</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SRT/mtl1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/mtl2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/mtl3.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/mtl4.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/mtl5.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SRT® MTL</h3>
                                <p>The EFA-SRT® MTL door supports all applications in modern intralogistics. Based on
                                    the installation situation, the space constraignts and the process related
                                    specifications, you will always find the suitable drive. For a long service life and
                                    dynamic door travel, frequency converters are used as standard. Operational and
                                    personal safety comply exactly with your desired application. For this purpose,
                                    various emergency opening, safety and pulse generator systems can be provided.
                                    Moreover, the use of mechanical and / or inductive limit switches (up to CAT. IV) is
                                    possible.</p>
                                <h3>Wealth of variants</h3>
                                <strong>Access Obstacle</strong>
                                <p>Accessing the danger area is made more diffi cult, but not prevented. For example, in
                                    areas which are absolutely shielded from persons and reserved for materials handling
                                    only, it is not necessary to equip relevant MTL doors with personal protection. This
                                    is referred to as access obstacle, because movement of people is excluded during
                                    regular operation and access to the system is only permissible for instructed
                                    operating personnel after the entire system has been switched off, for example for
                                    service work and / or inspection services.</p>
                                <strong>Access Protection</strong>
                                <p>The danger area is prevented from being accessed by means of the door. This variant
                                    is used for MTL doors which are operated in connection with present persons, whereby
                                    the danger area is to be prevented from being entered or reached into. In these
                                    applications, it is mandatory to equip corresponding MTL models with conventional
                                    protection systems for the door closing line (e. g. by means of a safety edge and
                                    light barrier or door line light grid “TLG”) and a drive with gear unit failure
                                    protection in order to prevent any hazards to people. </p>

                                <strong>EFA-SRT® MTL at a glance</strong>

                                <ul class="feat">
                                    <li>Power-driven high-speed roll-up door for industrial and commercial use in the
                                        materials handling sector.</li>
                                    <li>Particularly suitable for confined space applicatons</li>
                                    <li>Maximum speed up to 1.5 m / s</li>
                                    <li>Closing in 1.0 m / s</li>
                                    <li>Up to 250,000 cycles per year</li>
                                    <li> Standard sizes up to w=3,000mm x h=3,000mm.</li>

                                </ul>


                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SRT® MTL</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">12
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">-
               </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">3000
                    <br>3000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">1.5
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.5
                    <br>0.6
                    <br>1.0
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">-
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>•
                        <br>◦/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">250,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">◦
                    <br>•
                    <br>•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>
<!--Efficient-->
        <div class="tab-pane fade show " id="srt-cr-efficient" role="tabpanel" aria-labelledby="srt-cr-efficient-tab">
            <div class="container">
                <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>EFA-SRT® CR Efficient</h2>
                    <p>The high speed EFA-SRT® CR Efficient roller doors were designed especially for use in clean rooms
                        in the midrange of
                        requirements. The extremely narrow door legs allow space-saving installation, while retaining
                        aesthetics even in small spaces.
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SRT/cre1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/cre2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/cre3.jpg" alt="Image" width="100%" height="auto">
                        </div>
                            <div class="article-content">
                                <h3>About EFA-SRT® CR Efficient</h3>
                                <p>An integrated absolute encoder ensures the door returns to automatic operation after
                                    any interruptions of power supply,
                                    negating the need for manual synchronisation. </p>
                                <strong>EFA-SRT® CR Efficient at a glance</strong>
                                <ul class="feat">
                                    <li>Almost Airtight.</li>
                                    <li>Extremely slim frame.</li>
                                    <li>Certified up to ISO Class 6.</li>
                                    <li>Opening up to 0.8 m/s.</li>
                                    <li>Closing up to 0.5 m/s.</li>
                                    <li>Up to 100,000 operating cycles p.a.</li>
                                    <li> Standard sizes up to w=3,000mm x h=3,500mm.</li>

                                </ul>


                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SRT® CR Efficient</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">-
               </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">3000
                    <br>3500
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">1.0
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">0.8
                    <br>0.5
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">-
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">-
                    <br>◦
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>•
                        <br>-
                        <br>-
                        <br>-
                        <br>◦/•
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">100,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>
<!--Premium-->
        <div class="tab-pane fade show " id="srt-cr-premium" role="tabpanel" aria-labelledby="srt-cr-premium-tab">
            <div class="container">
                <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>EFA-SRT® CR Premium</h2>
                    <p>The clean room doors of the CR series by EFAFLEX are perfectly adjusted to the requirements in
                        controlled production zones. They guarantee the tightness of all
                        accesses. The powerful high-speed doors have a smooth surface structure and no protruding edges.
                        Therefore, they can be easily cleaned and depositing of particles
                        is largely excluded. The GMP-compatible door types of the CR series are mainly made of V2A.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SRT/cr1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/cr2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/cr3.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/cr4.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SRT/cr5.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SRT® CR Premium</h3>
                                <p>EFAFLEX clean room doors are enormously heavy-duty and require low maintenance thanks
                                    to their excellent quality.
                                    The most important argument for EFAFLEX clean room doors is, however, their air
                                    tightness which considerably contributes to saving costs in clean rooms, as well as
                                    their modular structure in a clinical design.</p>
                                <strong>Fast and Robust</strong>
                                <p>Due to its design and its high air tightness, the standard door for GMP-compatible
                                    clean rooms is - among others - suitable for being installed in clean rooms of class
                                    8 according to DIN EN ISO 14644 for the insulation of clean rooms of class 7. The
                                    EFA-SRT® CR Premium is, of course, also suitable for being installed in clean rooms
                                    of other classes.
                                    <br>
                                    The CR version of the EFA-SRT® CR Premium is ideal for closing rooms with different
                                    pressure conditions. Fast opening and closing increase the profitability in clean
                                    rooms; this keeps the filter load low and reduces the air loss.</p>

                                <strong>EFA-SRT® CR Premium at a glance</strong>
                                <ul class="feat">
                                    <li>Almost airtight</li>
                                    <li>Smooth surface structure</li>
                                    <li>Also for air locks</li>
                                    <li>Opening up to 1.0 m/s.</li>
                                    <li>Closing up to 0.5 m/s.</li>
                                    <li>Up to 150,000 operating cycles p.a.</li>
                                    <li> Standard sizes up to w=2,500mm x h=3,000mm.</li>

                                </ul>



                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SRT® CR Premium</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">-
               </td>
              </tr>
        
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">2500
                    <br>3000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">1.5
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.0
                    <br>0.5
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">-
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">-
                    <br>•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>◦/•
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">200,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller
        
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•Integrated
                    <br>-
                    <br>-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">◦
                    <br>•
                    <br>•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

    </div>



</section>
