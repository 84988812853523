<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>About Us</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home </a></li>
                        <li>About Us</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>


<section class="customer-support-area pt-70 pb-70" id="team">
        <div class="container">


<div id="carouselExampleSlidesOnly" class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <div class="row align-items-center wow fadeInUp animated animated" style="visibility: visible;">
            <div class="col-lg-6 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="customer-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2 style="padding:0px; text-align: left">About GATEMAAN</h2>
                    <p class="para wow fadeInUp animated animated" style="visibility: visible;margin-bottom: 20px;">GATEMAAN : A Trademark of Nithyaa Sai Metal Technics LLP(NSMT LLP), launched with a vision to bring the worlds
best Automation technologies in Industrial High Speed Doors, Gates, Roll-Up Doors/ Rolling Shutters, Sectional Doors, Dock Levellers, Dock Shelters
etc to India. by partnering with the global market leading company Efaflex.                            
</p>
                </div>
                <h2 style="text-align: left; margin:0px; padding:0px">Why Gatemaan ?</h2>
                <ul class="support-list wow fadeInUp animated animated" style="visibility: visible;">
                    <li>A Versatile Company Means Endless Possibilities</li>
                    <li>30+ Years of Experience</li>
                    <li>Nobody Else Can Do All This Under One Roof.</li>
                    <li>Design & Build Smart To Save You Time & Money</li>
                </ul>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="support-img wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/about/GATEMAAN.png" alt="Image">
                </div>
            </div>
        </div>
    </div>
    <div class="carousel-item">
        <div class="row align-items-center wow fadeInUp animated animated" style="visibility: visible;">
            <div class="col-lg-6 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="customer-title wow fadeInUp animated animated" style="visibility: visible;">
                    <h2 style="padding:0px; text-align: left">Our History</h2>
                    <p class="para wow fadeInUp animated animated" style="visibility: visible;margin-bottom: 20px;">
                        GATEMAAN is the child company of Nithyaa Sai Metal Technics LLP(NSMT LLP). NSMT is the consequence of the business acumen and technical experience acquired over 30+ years at the shop floor in H.M.T. Ltd of founder and chairman Sri. KUNTAMUKKALA THYAGARAJA NAIDU (B.E.,M.Tech). 
                        <br>
                        <br>
                        Our business story incepted in the year 1986 with Sri Thyagaraja Naidu launched ‘Vijay Sapthagiri Machines and Tools’ an ancillary division for H.M.T Ltd. The company is now being managed by his son Mr. KUNTAMUKKALA VENKATA SAI PRASAD(B.E), and was incorporated 
                        in the year 2001 as ‘NSMT’ with its corporate office located at Hyderabad, Telangana, India. It is an entrepreneur driven company with its strong fundamentals derived from its founder. 
</p>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="support-img wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/about/nsmt.PNG" alt="Image">
                </div>
            </div>
        </div>
    </div>
      </div>
      </div>

      <br>
      <br>
      <br>
<!-- New code for Time line-->
<!--phani update-->

<section class="timeline_area section_padding_130">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-sm-8 col-lg-6">
                <!-- Section Heading-->
                <div class="section_heading text-center">
                    
                    <h3>A brief stories of our 30 years of journey.</h3>
                    <br>
                    <div class="line"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- Timeline Area-->
                <div class="apland-timeline-area">
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p style="margin:0px;">Near Future</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-address-card" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px; font-size: 13px;">Manufacturing Unit</h6>
                                        <p style="margin:0px; font-size:12px">New Manufacturing unit is under construction. in next 6 months it will completed</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-archive" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px ; font-size: 13px;">International Market</h6>
                                        <p style="margin:0px; font-size:12px">We started our ground work to enter into the international market.</p>
                                    </div>
                                </div>
                            </div>
                           <!--- <div class="col-12 col-md-6 col-lg-4">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.7s" style="visibility: visible; animation-delay: 0.7s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-address-book" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6>Reach 1k Users</h6>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                    </div>
                                </div>
                            </div>-->
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p style="margin:0px;">2020</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-12">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px ; font-size: 13px;">New Firm</h6>
                                        <p style="margin:0px ; font-size:12px">We started our New Firm in Pandigotur, Irala Mandal, Chittoor Dist, Andhra Pradesh</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p style="margin:0px;">2018</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-6">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-id-card" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px; font-size: 13px;">Partnership</h6>
                                        <p style="margin:0px ; font-size:12px">Proposed Partnership with EFAFLEX for HSD</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6 col-lg-6">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.5s" style="visibility: visible; animation-delay: 0.5s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-desktop" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px; font-size: 13px;">First HSD</h6>
                                        <p style="margin:0px ; font-size:12px">We successfully installed our First High Speed Doors.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p style="margin:0px;">2017</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-12">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px ; font-size: 13px;">Sales and Marketing</h6>
                                        <p style="margin:0px ; font-size:12px">We are intiated way of setting up sales and marketing network and results are encourging</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p style="margin:0px;">2016</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-12">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px ; font-size: 13px;">New Vertical - GATEMAAN</h6>
                                        <p style="margin:0px ; font-size:12px">Decision to open a new vertical "GATEMAAN" for distributing Automated Gates, Shutters/ Roll Up Doors and High Speed Doors.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p style="margin:0px;">2013</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-12">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px ; font-size: 13px;">New Firm</h6>
                                        <p style="margin:0px ; font-size:12px">Expanded to housing sector as NITHYA HOMES launched to complete the residential projects for more than 100 flats in tirupati and bengaluru.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p style="margin:0px;">2012</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-12">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px ; font-size: 13px;">New Heights</h6>
                                        <p style="margin:0px ; font-size:12px">NSMT became prime vendor in prestigious project like Hyderbad and Bengaluru Airport and metro Projects</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p style="margin:0px;">2004</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-12">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px ; font-size: 13px;">Starting Steps in Automation</h6>
                                        <p style="margin:0px ; font-size:12px">We started supplying Automated Gates , Rolling Shutters in the market.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p style="margin:0px;">1996</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-12">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px ; font-size: 13px;">Rename with New Market</h6>
                                        <p style="margin:0px ; font-size:12px">Changed Name to NITHYAA SAI METAL TECHNICS and entered into Steel fabrication business.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- Single Timeline Content-->
                    <div class="single-timeline-area">
                        <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
                            <p style="margin:0px;">1988</p>
                        </div>
                        <div class="row">
                            <div class="col-12 col-md-6 col-lg-12">
                                <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                                    <div class="timeline-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></div>
                                    <div class="timeline-text">
                                        <h6 style="margin-top:6px; margin-bottom:6px ; font-size: 13px;">Prime Supplier</h6>
                                        <p style="margin:0px ; font-size:12px">Became prime supplier of Machines and spare parts to BHEL, HMT, Vijay Electrics etc.,</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
<!-- Single Timeline Content-->
<div class="single-timeline-area">
    <div class="timeline-date wow fadeInLeft" data-wow-delay="0.1s" style="visibility: visible; animation-delay: 0.1s; animation-name: fadeInLeft;">
        <p style="margin:0px;">1986</p>
    </div>
    <div class="row">
        <div class="col-12 col-md-6 col-lg-12">
            <div class="single-timeline-content d-flex wow fadeInLeft" data-wow-delay="0.3s" style="visibility: visible; animation-delay: 0.3s; animation-name: fadeInLeft;">
                <div class="timeline-icon"><i class="fa fa-briefcase" aria-hidden="true"></i></div>
                <div class="timeline-text">
                    <h6 style="margin-top:6px; margin-bottom:6px ; font-size: 13px;">First Step</h6>
                    <p style="margin:0px ; font-size:12px">Vijay Sapthagiri Machines and Tools, Incepted as an aucillery division of HMT.</p>
                </div>
            </div>
        </div>
    </div>
</div>

                </div>
            </div>
        </div>
    </div>
</section>

<!--phani update-->

<!--New code for Time Line-->
<br>
<br>
<h2 style="font-size:30px">OUR PROFESSIONALS</h2>
<p style="text-align: center;">Our team is a vast mix of skills that are specialized and geared to offer sound financial advice and solutions. The congregation of experienced and qualified professionals who strive hard to achieve the best by adding value and optimizing the benefits to clients.</p>
<!--
    <div class="feature-boxes row justify-content-center">
        <div class="col-lg-2"></div>
        <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
            <div class="feature-box wow fadeInUp animated animated" style="visibility: visible;">
                <div class="feature-bg bg-img-c" style="background-image: url('../../../../assets/img/about/naidu\ sir.jpg');">
                </div>
                <div class="feature-desc wow fadeInUp animated animated" style="visibility: visible;">
                    <a href="#" class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
                    <h4>Thyagaraja Naidu</h4>
                    <p>Chairman</p>
                </div>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
            <div class="feature-box wow fadeInUp animated animated" style="visibility: visible;">
                <div class="feature-bg bg-img-c" style="background-image: url('../../../../assets/img/about/prasadsir.jpg');">
                </div>
                <div class="feature-desc wow fadeInUp animated animated" style="visibility: visible;">
                    <a href="#" class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
                    <h4>Venkata Sai Prasad</h4>
                    <p>Vice Chairman</p>
                </div>
            </div> 
        </div>
        <div class="col-lg-2"></div>
        <div class="col-lg-4 col-md-6 col-10 col-tiny-12">
            <div class="feature-box wow fadeInUp animated animated" style="visibility: visible;">
                <div class="feature-bg bg-img-c" style="background-image: url('../../../../assets/img/about/nithin.jpg');">
                </div>
                <div class="feature-desc wow fadeInUp animated animated" style="visibility: visible;">
                    <a href="#" class="feature-link"><i class="fal fa-long-arrow-right"></i></a>
                    <h4>Nithin Seshu</h4>
                    <p>Managing Head</p>
                </div>
            </div>
        </div> 
    </div>
-->
<!--my code-->
<div class="row ">
      <!-- column  -->
      <div class="col-lg-4 mb-4">
        <!-- Row -->
        <div class="row">
          <div class="col-md-12">
            <div class="member">
                <div class="member-img">
            <img src="../../../../assets/img/about/naidu sir.jpg" alt="wrapkit" class="img-fluid" />
          </div>
          <div class="member-info">
            <h4>K THYAGARAJA NAIDU</h4>
            <span>FOUNDER & CHAIRMAN</span>
          </div>
          </div>
          </div>
        </div>
        <!-- Row -->
      </div>
      <!-- column  -->
      <!-- column  -->
      <div class="col-lg-4 mb-4">
        <!-- Row -->
        <div class="row">
            <div class="col-md-12">
              <div class="member">
                  <div class="member-img">
              <img src="../../../../assets/img/about/prasad sir image.jpg" alt="wrapkit" class="img-fluid" />
            </div>
            <div class="member-info">
              <h4>K VENKATA SAI PRASAD</h4>
              <span>VICE CHAIRMAN</span>
            </div>
            </div>
            </div>
          </div>
        <!-- Row -->
      </div>
      <!-- column  -->
      <!-- column  -->
      <div class="col-lg-4 mb-4">
        <!-- Row -->
        <div class="row">
            <div class="col-md-12">
              <div class="member">
                  <div class="member-img">
              <img src="../../../../assets/img/about/dummy-image.jpg" alt="wrapkit" class="img-fluid" style="height:350px"/>
            </div>
            <div class="member-info">
              <h4>K NITHIN SESHU</h4>
              <span>H R & SALES MANAGER</span>
            </div>
            </div>
            </div>
          </div>
        <!-- Row -->
      </div>
</div>


<!--my code-->

    </div>
</section>
<!-- <section class="solutions-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="solutions-img">
                    <img src="assets/img/solutions-img.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="solutions-wrap">
                    <div class="solutions-title">
                        <h2>Complete Cloud Hosting Solutions</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commo o viverra maecenas accumsan lacus.</p>
                    </div>

                    <ul class="solutions-list">
                        <li><i class="flaticon-right"></i> After you purchase a hosting plan</li>
                        <li><i class="flaticon-right"></i> Next to the hosting account, you want to use, click Manage</li>
                        <li><i class="flaticon-right"></i> Select or enter the domain name you want to use</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section> -->

<section class="location-area ptb-100 server-content-wrap " >
    <div class="container">
        <div class="row">
            <div class="col-sm-5 col-xs-12">
                <div class="section-title loca-sec wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>Our Office <span style="color:#fe9900">Locations</span></h2>
                    <ul align="left" style="color: #fe9900;">
                        <li>⚪ Hyderabad</li>
                        <li>⚪ Chittoor</li>
                        <li>⚪ Bengaluru</li>
                        <li>⚪ Visakhapatnam</li>
                        <li>⚪ Chennai</li>
                        <li>⚪ Pune</li>
                        <li>⚪ Delhi</li>
                        
                    </ul>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                        dolore magna aliqua. ipsum suspendisse.</p> -->
                </div>
            </div>
            <div class="col-sm-7 col-xs-12">
                <img src="assets/img/mapp.png">
                <!--
                <div class="row mobi-view">
                    <div class="col-4 col-sm-6 ">
                        <div class="cadr">
                        <img src="assets/img/hyd.png" class="img-icon">
                        <h3 class="loc-h3">Hyderabad</h3>
                        </div>
                    </div>
                    <div class="col-4 col-sm-6 ">
                        <div class="cadr">
                        <img src="assets/img/banglore.png"  class="img-icon">
                        <h3 class="loc-h3">Banglore</h3>
                        </div>
                    </div>
                    <div class="col-4 col-sm-6 ">
                        <div class="cadr">
                        <img src="assets/img/hyd.png" class="img-icon">
                        <h3 class="loc-h3">Visakhaptnam</h3>
                        </div>
                    </div>
                    <div class="col-2 "></div>
                    <div class="col-4 col-sm-6 ">
                        <div class="cadr">
                        <img src="assets/img/banglore.png"  class="img-icon">
                        <h3 class="loc-h3">Guntur</h3>
                        </div>
                    </div>
                    <div class="col-4 col-sm-6 ">
                        <div class="cadr">
                        <img src="assets/img/banglore.png"  class="img-icon">
                        <h3 class="loc-h3">Tirupathi</h3>
                        </div>
                    </div>
                    <div class="col-2 "></div>
                </div>
                <div class="map-img wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/dotted map.PNG" alt="Image">

                    <div class="single-location video-btn">
                        <i class="bx bx-location-plus"></i>
                        <span>Hyderabad</span>
                    </div>

                    <div class="single-location location-two video-btn">
                        <i class="bx bx-location-plus"></i>
                        <span>Visakhapatnam</span>
                    </div>

                    <div class="single-location location-three video-btn">
                        <i class="bx bx-location-plus"></i>
                        <span>Tirupathi</span>
                    </div>

                    <div class="single-location location-four video-btn">
                        <i class="bx bx-location-plus"></i>
                        <span>Bengaluru</span>
                    </div>

                    <div class="single-location location-five video-btn">
                        <i class="bx bx-location-plus"></i>
                        <span>Guntur</span>
                    </div>

                    <div class="single-location location-five video-btn">
                        <i class="bx bx-location-plus"></i>
                        <span>Delhi</span>
                    </div>
                </div>
                -->
            </div>
        </div>



    </div>
</section>

<section class="dedicated-server-area ptb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="server-img wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/about/promise.png" alt="Image">
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="server-content-wrap wow fadeInUp animated animated" style="visibility: visible;">
                    <h2>Our Promises</h2>
                    <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua
                    </p> -->

                    <ul>
                        <li>
                            <!-- <i class="flaticon-migrating"></i> -->
                            <img src="assets/img/about/smart.PNG" class="icon-img" >
                            <span>Build Smart To Save You Money</span>
                            <p>Read twice Execute once Build Smart. We apply experience and innovation to deliver you
                                the
                                complete Gatemaan experience. From design engineering to installation and professional
                                close out procedures.Our aim is to deliver the best at your budget while saving your
                                time money and additional
                                subcontractors.</p>
                        </li>
                        <li>
                            <img src="assets/img/about/100.PNG" class="icon-img" >
                            <span>100% Project Coordination</span>
                            <p>Architects, Engineers, Project managers, Builders, Owners our team
                                of people work seamlessly from start to finish to
                                make every project easy for you. We don't outsource our work, We do it by ourselves for
                                your gain

                            </p>
                        </li>
                        <li>
                            <img src="assets/img/about/time.PNG" class="icon-img" >
                            <span>On Time On Budget</span>
                            <p> We're honest,loyal and organized.
                                Our experience means when we give a firm price that price sticks and will only change to
                                meet
                                specification or circumstances. We'll
                                schedule your Door/Gate/Shutter to be delivered
                                and unloaded whenever you want.</p>
                        </li>
                        <!-- <li>
                            <i class="flaticon-network-1"></i>
                            <span>Optimized Network</span>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </li> -->
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="client-area  ptb-100">
    <!-- <div class="shape shape-two shape-style-two">
    </div> -->
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>What our <span style="color:#fe9900">Clients</span> say</h2>
            <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                dolore magna aliqua. ipsum suspendisse.</p> -->
        </div>

        <div class="row">
            <div class="client-wrap owl-carousel owl-theme">
                <div class="single-client wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/client/cl1.jpg" alt="Image">
                    <h3>L&T Infotech</h3>
                    <span>Planning Department</span>
                    <p>We are in partner with NSMT for 30 years and we have not had a single problem till date. This
                        shows their commitment!</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/client/cl2.jpg" alt="Image" type="image/jpeg">
                    <h3>Woxsen</h3>
                    <span>Purchase Department</span>
                    <p>Automated gates that run forever,best product, best service!</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/client/cl3.jpg" alt="Image" type="image/jpeg">
                    <h3>Hyderabad Metro Rail</h3>
                    <span>Purchase Department</span>
                    <p>Any steel product its NSMT, on time best quality!</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

                <div class="single-client wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/client/cl4.jpg" alt="Image" type="image/jpeg">
                    <h3>Chettinad Chemsem</h3>
                    <span>Purchase Department</span>
                    <p>As it quotes "On time On Budget" very apt to the company. not only on time and on budget but the Quality is very good by comparing with others.</p>
                    <ul>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                        <li><i class="bx bxs-star"></i></li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
    <!-- <div class="shape shape-ome ">
        <img src="assets/img/06.png" alt="Image">
    </div> -->


</section>




<div class="brand-area pt-100 pb-70">
    <div class="container">
        <div class="row wow fadeInUp animated animated" style="visibility: visible;">

                <h2 class="cl">Our Prestigious Projects :</h2>

            <div class="brand-wrap owl-carousel owl-theme wow fadeInUp animated animated" style="visibility: visible;">
                <div class="single-brand">
                    <div class="spacer-sm"></div>
                    <img src="assets/img/projects/p1.png" alt="Image" type="image/png">
                </div>

                <div class="single-brand">
                    <br><br> <div class="spacer-sm"></div>
                    <img src="assets/img/projects/p2.png" alt="Image" type="image/png">
                </div>

                <div class="single-brand">
                    <img src="assets/img/projects/p3.png" alt="Image" type="image/png">
                </div>

                <div class="single-brand">
                    <img src="assets/img/projects/p4.png" alt="Image" type="image/png">
                </div>

                <div class="single-brand">
                    <img src="assets/img/projects/p5.png" alt="Image" type="image/png">
                </div>

                <div class="single-brand">
                    <img src="assets/img/projects/p6.png" alt="Image" type="image/png">
                </div>

                <div class="single-brand">
                    <img src="assets/img/projects/p7.png" alt="Image" type="image/png">
                </div>
            </div>
        </div>
    </div>
</div>


<script src="assets/js/custom.js" async></script>
