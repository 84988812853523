import { Component, OnInit} from '@angular/core';
import {MatAccordion} from '@angular/material/expansion';
import { MetaService } from '../../../services/meta-service.service';
@Component({
  selector: 'app-home-one',
  templateUrl: './home-one.component.html',
  styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {
    step = 0;

    setStep(index: number) {
      this.step = index;
    }

    nextStep() {
      this.step++;
    }

    prevStep() {
      this.step--;
    }
  constructor(private metaService: MetaService) { }

  ngOnInit(): void {
    this.metaService.setCanonicalURL();
  }

}
