<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Dock Levelers</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home</a></li>
                        <li><a routerLink="/products">Products</a></li>
                        <li>Docks</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="contact-info-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="h2-cus">DOCKS</h2>
            <!-- </div>
        <div class="txt-center"> -->
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                <li class="nav-item  ">
                    <a class="nav-link" id="dock-levelers-tab" (click)="turnWhite('dclevel')" [ngClass]="{'active' : page == 'dock-levelers'}" data-toggle="pill"
                        href="#dock-levelers" role="tab" aria-controls="dock-levelers" aria-selected="false"> <i class="">
                            <img class="cus-icon" [hidden]="!dclevelblack" src="assets/img/icons/Docks/dock-levellers.png"alt="Image" type="image/png" >
                            <img class="cus-icon" [hidden]="!dclevelwhite" src="assets/img/icons/Docks/dock-leveler-white.PNG"alt="Image" type="image/png">
                        </i>

                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="dock-shelter-tab" (click)="turnWhite('shelter')" [ngClass]="{'active' : page == 'dock-shelter'}" data-toggle="pill"
                        href="#dock-shelter" role="tab" aria-controls="dock-shelter" aria-selected="false"> <i class="">
                            <img class="cus-icon" [hidden]="!shelterblack" src="assets/img/icons/Docks/dock-shelter.png"alt="Image" type="image/png">
                            <img class="cus-icon" [hidden]="!shelterwhite" src="assets/img/icons/Docks/dock-shelter-white.PNG"alt="Image" type="image/png">
                        </i></a>
                </li>

            </ul>


        </div>

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'dock-levelers'}" id="dock-levelers" role="tabpanel"
                aria-labelledby="dock-levelers-tab">
                <app-dock-levellers></app-dock-levellers>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'dock-shelter'}" id="dock-shelter" role="tabpanel"
                aria-labelledby="dock-shelter-tab">
                <app-dock-shelters></app-dock-shelters>
            </div>

        </div>
    </div>
</section>
