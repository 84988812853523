<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Telescopic Gate</h2>
            <p>Telescopic gates, also known as telescoping gates, are a type of sliding gate system that consists of multiple overlapping gate panels. These panels are designed to fold or collapse upon each other when the gate is opened, allowing for a compact storage configuration.</p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/gates/t0.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                            <img src="assets/img/products/gates/t1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                            <img src="assets/img/products/gates/t2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                            <img src="assets/img/products/gates/t3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                            
                        </div>

                    <div class="article-content">
                        <h3>About Telescopic Gate</h3>
                        <p align="justify">The Telescopic Gate system is commonly used in situations where there is limited space available 
                            for the gate to slide into when open. By utilizing multiple panels that fold or slide within each other,
                             telescopic gates can achieve a greater opening width while minimizing the amount of space required for 
                             gate storage.
                            <br>
                            <br>
                            Our Telescopic Sliding Gates are manufactured
                            in two, three or more gate panels reducing the required back / run parking space. All components
                             are manufactured using graduated steel material with robust electric motor and advanced intelligent
                             control system having better operation, functioning and looks. Our automatic telescopic sliding gates
                              are perfect suit to industries with medium to heavy duty applications and for very large openings.
                        </p>
<br>
                        <ul class="feat" align="justify" style="line-height:180%">
                            <li>It specially designed telescopic mechanism that moves each
                                gate panel at a different speed to reach final position simultaneously.</li>
                            <li>Some of the notable features
                                of our sliding gates are - convenient, safer, more functional, extremely durable, less space and
                                improve visual appeal.</li>
                            <li>Functions at high opening and closing speed</li>
                            <li>Move quickly and cannot be forced open without exceptional leverage</li>
<li>Use of top nylon guide rollers with bearing ensuring smooth operations.</li>
<li>Use of latest technology for automation and energy efficiency.</li>
                        </ul>


                    </div>


            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>
            <blockquote class="flaticon-quote">
            <table class="table table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Product : </th>
                    <th scope="col">Telescopic Gate</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" color="#fe9900">Door Type</th>
                    <td>Single side With multiple panel / Bi-parting Type with multiple panel</td>
                  </tr>
                  <tr>
                    <th scope="row">Door Dimension</th>
                    <td> </td>
                  </tr>
                  <tr>
                    <th scope="row">Width Min. /Max.</th>
                    <td>Single Side Sliding : 2 Panels : 2000mmto 10000mm</td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>Single Side Sliding : 3 Panels : 3000mm-15000mm</td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>Bi-Parting Sliding : 2+2 Panels: 4000mm to 20000mm</td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>Bi-Parting Sliding : 3+3 Panels : 6000mm to 30000mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Available In</th>
                    <td>Mild-Steel,Stainless Steel,Wood & Steel combination,Aluminum and with Hot-dip galvanization including powder coating.</td>
                  </tr>
                  <tr>
                    <th scope="row">Warranty</th>
                    <td>Limited Lifetime on the motor, gears, and rail assembly</td>
                  </tr>
                  <tr>
                    <th scope="row"> Illumination	</th>
                    <td>500 Lumens of integrated LED lighting provides over 30.000 hours</td>
                  </tr>
                  <tr>
                    <th scope="row"> Technology</th>
                    <td>Multi-bit code technology 315MHz 2-channel remote control is included</td>
                  </tr>
                  <tr>
                    <th scope="row">HPcs</th>
                    <td>HPcs indicates that Synergy 380 meets Marantec’s specifications for 1.1 HP garage door operators</td>
                  </tr>
                  <tr>
                    <th scope="row">Maintenance</th>
                    <td>Operator and rail system are maintenance free</td>
                  </tr>
                  <tr>
                    <th scope="row">Safety System</th>
                    <td>2500mm – 10000mm ; Larger Dimension on Request</td>
                  </tr>
                  <tr>
                    <th scope="row">Height Min. /Max.</th>
                    <td>photo eye safety system is equipped with UV filters in order to eliminate interference created by sunlight.</td>
                  </tr>
                  <tr>
                    <th scope="row">Door Mounting</th>
                    <td>On Top Track Mechanism</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening Direction</th>
                    <td>Horizontal</td>
                  </tr>
                  <tr>
                    <th scope="row">Mounting Location</th>
                    <td>Inside / Outside</td>
                  </tr>
                  <tr>
                    <th scope="row">Mounting Surface</th>
                    <td>On RCC / Steel</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Up to 120 km/Hr. * Higher Wind Pressure on Request.</td>
                  </tr>
                  <tr>
                    <th scope="row">Door Structure</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Door Panel</th>
                    <td>Mild Steel tubular section door frame with provision to fix 40 mm thk / 50 mm thk CFC free self extinguishing .
                        Polyurethane Foam(PUF) panel of density 40 Kg/m³ having both sides 0.5 mm thk GI precoated sheets.
                        Higher Thickness of panel available on Request.
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Optional Panels</th>
                    <td> </td>
                  </tr>
                  <tr>
                    <th scope="row">Cladding Sheet</th>
                    <td>Single skin Pre-coated G.I Cladding Sheet
                        Both Side Pre-coated G.I Cladding Sheet with or without EPS Insulation</td>
                  </tr>
                  <tr>
                    <th scope="row">Plain Sheet</th>
                    <td>Single Skin Plain Sheet 1.2mm thick with all around beading</td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>Both side Plain Sheet 1.2mm thick with all around beading</td>
                  </tr>
                  <tr>
                    <th scope="row">Glasswool / Rockwool insulation available on Request</th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Outer Frame</th>
                    <td>Standard Tubular Pipe Section.</td>
                  </tr>
                  <tr>
                    <th scope="row">Top Track</th>
                    <td>Specially Designed Multiple Top Mounted Tracks as per no. of panels. Constructed from MS plate. Equipped with end stops</td>
                  </tr>
                  <tr>
                    <th scope="row">Bottom Track</th>
                    <td>Especially Designed Multiple Bottom Guide track as per no. of panels, to avoid oscillation of the door. The bottom Rail is flushed with floor level</td>
                  </tr>
                  <tr>
                    <th scope="row">Rollers</th>
                    <td>Dual Type Top Rollers assembly with bearings.</td>
                  </tr>
                  <tr>
                    <th scope="row"></th>
                    <td>Bottom Rollers assembly with bearings.</td>
                  </tr>
                  <tr>
                    <th scope="row"> Hardware & Accessories</th>
                    <td>Handle on both sides of the doors. Optional Locking system for manual doors</td>
                  </tr>
                  
                </tbody>
              </table>
              </blockquote>
              
    

 <!------>          
        <!--My code  -->
        
        
 <!----       
            <blockquote class="flaticon-quote">
                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:120px"><span class="fa fa-thumbs-o-up"></span> &nbsp;Product
                                        </td>
                                        <td>Telescopic Gates
                                        </td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><span class="fa fa-thumbs-o-up"></span> &nbsp;Door Type
                                        </td>
                                        <td>Single side With multiple panel / Bi-parting Type with multiple panel
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">Door Dimension</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><span class="fa fa-dashboard"></span> &nbsp;Width Min.
                                            /Max.</td>
                                        <td> Single Side Sliding : 2 Panels : 2000mmto 10000mm
                                            <hr>
                                            Single Side Sliding : 3 Panels : 3000mm-15000mm
                                            <hr>
                                            Bi-Parting Sliding : 2+2 Panels: 4000mm to 20000mm
                                            <hr>
                                            Bi-Parting Sliding : 3+3 Panels : 6000mm to 30000mm
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><span class=""></span> &nbsp;Available In</td>
                                        <td>Mild-Steel,Stainless Steel,Wood &amp; Steel combination,Aluminum and with
                                            Hot-dip galvanization including powder coating.
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:120px"><span></span> &nbsp;Warranty</td>
                                        <td>Limited Lifetime on the motor, gears, and rail assembly</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><span></span> &nbsp;illumination</td>
                                        <td class="txt-clr">500 Lumens of integrated LED lighting provides over 30.000
                                            hours</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><span></span> &nbsp;technology</td>
                                        <td class="txt-clr">Multi-bit code technology 315MHz 2-channel remote control is
                                            included</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><span></span>&nbsp; HPcs</td>
                                        <td class="txt-clr">HPcs indicates that Synergy 380 meets Marantec’s
                                            specifications for 1.1 HP garage door operators</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px"><i></i>&nbsp;maintenance</td>
                                        <td>Operator and rail system are maintenance free</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><i></i>&nbsp; safety system</td>
                                        <td class="txt-clr">photo eye safety system is equipped with UV filters in order
                                            to eliminate interference created by sunlight</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><span></span>&nbsp; Height Min. /Max.
                                        </td>
                                        <td class="txt-clr">
                                            2500mm – 10000mm<br>
                                            Larger Dimension on Request
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <hr>


               // specification table 2

                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>

                                    <tr>
                                        <td style="width:120px;"><i></i>&nbsp;Door Mounting</td>
                                        <td>on Top Track Mechanism</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><i></i>&nbsp; Opening Direction</td>
                                        <td class="txt-clr">Horizontal</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i></i>&nbsp;Mounting Location </td>
                                        <td>Inside/Outside</td>
                                    </tr>


                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Mounting Surface </td>
                                        <td >On RCC/steel</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Wind Resistance </td>
                                        <td>Up to 120 km/Hr.<br>
                                            *Higher Wind Pressure on Request.</td>
                                    </tr>

                                    <tr>

                                        <td colspan="2" >Door Structure</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Door Panel </td>
                                        <td>
                                            Mild Steel tubular section door frame with provision to fix 40 mm thk / 50
                                            mm thk CFC free self extinguishing
                                            .<br>Polyurethane Foam(PUF) panel of density 40 Kg/m³ having both sides 0.5
                                            mm thk GI precoated sheets.<br>
                                            Higher Thickness of panel available on Request.
                                        </td>
                                    </tr>
                                    <tr>

                                        <td colspan="2" >Optional Panels</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Cladding Sheet </td>
                                        <td >Single skin Pre-coated G.I Cladding Sheet<br>
                                            Both Side Pre-coated G.I Cladding Sheet with or without EPS Insulation</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>

                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Plain Sheet </td>
                                        <td>Single Skin Plain Sheet 1.2mm thick with all around beading
                                            <hr>
                                            Both side Plain Sheet 1.2mm thick with all around beading</td>
                                    </tr>

                                    <tr>

                                        <td colspan="2" >Glasswool / Rockwool insulation
                                            available on Request</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Outer Frame </td>
                                        <td >Standard Tubular Pipe Section.</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Top Track </td>
                                        <td >Specially Designed Multiple Top Mounted Tracks as
                                            per no. of panels. Constructed from MS plate. Equipped with end stops</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Bottom Track </td>
                                        <td >Especially Designed Multiple Bottom Guide track
                                            as per no. of panels, to avoid oscillation of the door. The bottom Rail is
                                            flushed with floor level</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Rollers </td>
                                        <td>Dual Type Top Rollers assembly with bearings.
                                            <hr>
                                            Bottom Rollers assembly with bearings.</td>
                                    </tr>

                                    <tr>
                                        <td><i class=""></i>&nbsp;Hardware &amp; accessories </td>
                                        <td>Handle on both sides of the doors.
                                            Optional Locking system for manual doors</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </blockquote>
        --> 
        </div>

    </div>
</section>
