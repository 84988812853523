<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Mild Steel Shutters</h2>
            <p>
                Mild Steel rolling shutters are fabricated from high-quality steel rods to operate on the same principles of rolling shutter. This shutter can be integrated with grills or perforated panels to achieve ventilation or see-through vision.</p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/products/shutters/msrs0.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/msrs1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/msrs2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/msrs3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/msrs4.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/msrs5.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/msrs6.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/msrs7.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">

                </div>

                    <div class="article-content" align="justify">
                        <h3>About MS Shutters</h3>
                        <p> Mild Steel Rolling Shutters are especially suited for industrial buildings, commercial buildings, kitchens, warehouses and any other openings shutters of up to a maximum dimension of 10000 mm width and 7000 mm height in a single panel. Our MS Rolling Shutters are quality products studded with numerous features. Can be operated on the lever also due to the heavy sizes. Manually operated by rotating the gear warms.
                        </p>
<br>
                        <ul class="feat" style="line-height:180%">
                            <li>MS Rolling Shutter is the cost effective solution for any kind of applications.</li>
                            <li>As the material is strong this protects from storm and burglary.</li>
                            <li>Powder coating for MS rolling shutters helps protect the shutters from corrosion.
                            </li>
                            <li>We have both manual (Push & pull, gear operated) and electrically operated rolling shutters</li>
                        <li>Stronger metal, so that it protects your entrance.</li>
                        </ul>

                    </div>

            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>
            <table class="table table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Product : </th>
                    <th scope="col">Mild Steel Rolling Shutters (Need to update specifications)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" color="#fe9900">Shutter Dimension : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Width Min. /Max.</th>
                    <td>1000 / 4200mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Height Min. /Max.</th>
                    <td>1000 / 4500mm; Max. Area 18.0 Sqm</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening Dimension</th>
                    <td>Vertical</td>
                  </tr>
                  <tr>
                    <th scope="row">Mounting Location</th>
                    <td>Inside</td>
                  </tr>
                  <tr>
                    <th scope="row">Shutter Material</th>
                    <td>Aluminum Alloy</td>
                  </tr>
                  <tr>
                    <th scope="row">Maximum Width * Height:</th>
                    <td>6 * 8 m</td>
                  </tr>
                  <tr>
                    <th scope="row"> Type of box</th>
                    <td>165 185 205 250 300 350mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Thickness</th>
                    <td>1.2mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Surface Treatin</th>
                    <td>Powder Coating</td>
                  </tr>
                  <tr>
                    <th scope="row">The sevice life</th>
                    <td>can be used 15 years</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening/Closing Speed</th>
                    <td>0.05m-0.2 m/sec</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Up to 90 km/hr</td>
                  </tr>
                  
    
                  <tr>
                    <th scope="row" color="#fe9900">Shutter Structure : </th>
                    <td></td>
                  </tr>
    
                  <tr>
                    <th scope="row">Slat Material</th>
                    <td>Non Insulated Double Wall Aluminum profiled Slats</td>
                  </tr>
                  <tr>
                    <th scope="row">Slat Profile</th>
                    <td>Corrugation profile</td>
                  </tr>
                  <tr>
                    <th scope="row">Bottom Profile</th>
                    <td>Aluminum profile with Bottom Rubber sealing.
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Side Guide Profile</th>
                    <td>Aluminum profile with Rubber inserts on both ends.</td>
                  </tr>
                  <tr>
                    <th scope="row">Roller Shaft</th>
                    <td>M.S Tubular Pipe with flanges & machined shaft at the ends.
                        Pipe dia-100 to 300mm, as per the opening dimension.</td>
                  </tr>
                  <tr>
                    <th scope="row">Side Brackets</th>
                    <td>M.S plate 3mm to 10mm,as per the opening dimension.</td>
                  </tr>
                  <tr>
                    <th scope="row">Surface Finish</th>
                    <td>Standard Off White Color</td>
                  </tr>
    
                  <tr>
                    <th scope="row" color="#fe9900"></th>
                    <td>Special Color on request</td>
                  </tr>
    
                  <tr>
                    <th scope="row">Details for Automation System : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Control Type</th>
                    <td>Deadman / Pulse Control</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Type</th>
                    <td>Indirect / Direct Connecting Drive</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Unit</th>
                    <td>Electric Motor(Imported Make) Single Phase/Three Phase.</td>
                  </tr>
                  <tr>
                    <th scope="row">Emergency Operation	</th>
                    <td>Manual operation by Hand chain</td>
                  </tr>
                  <tr>
                    <th scope="row" color="#fe9900">Options Available</th>
                    <td>Up to 90 km/hr</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Perforation In Slat profile
                        Rolling Grill
                        Wicket Door for pedestrian entry</td>
                  </tr>
                  <tr>
                    <th scope="row">Accessories(Optional) </th>
                    <td>Additional Push Button
                        <br>Remote Control with receiver
                        <br>Pull Cord Switch
                        <br> *can be integrated with advanced control</td>
                  </tr>
                  <tr>
                    <th scope="row">Features : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Certification</th>
                    <td>CE VDE checked</td>
                  </tr>
                  <tr>
                    <th scope="row">Protection Class</th>
                    <td>IP54.</td>
                  </tr>
                  
                </tbody>
              </table>
            <!--
<blockquote class="flaticon-quote">

                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">

                                <tbody>
                                </tbody>
                                <tbody>
                                    <tr>
                                        <td style="width:120px"><span class="fa fa-thumbs-o-up"></span> &nbsp;Opening
                                            speed</td>
                                        <td>8 m / min;</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><i class="fa fa-steam"></i>&nbsp;Motor
                                            Size</td>
                                        <td class="txt-clr">600Kg / 800Kg / 1000Kg etc</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><span class="fa fa-group"></span> &nbsp;Maximum size
                                        </td>
                                        <td>10M High</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px" class="txt-clr"><span class="fa fa-dashboard"></span>
                                            &nbsp;Operating voltage</td>
                                        <td class="txt-clr">380V (+6%, -10%), 50HZ;</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><span class="fa fa-gears"></span>&nbsp; Operating
                                            temperature</td>
                                        <td>-20째C-60째C;</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px" class="txt-clr"><i
                                                class="fa fa-steam"></i>&nbsp;Electrical motor and reduction box </td>
                                        <td class="txt-clr">domestic or import lifting motor;</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><i class="fa fa-fire-extinguisher"></i>&nbsp; Sound
                                            Insulation</td>
                                        <td>40dB;</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px" class="txt-clr"><i class="fa fa-steam"></i>&nbsp;Opening
                                            ways</td>
                                        <td class="txt-clr">push button,pull cord,radar,induction
                                            loop,remote...(optional) </td>
                                    </tr>




                                </tbody>


                            </table>

                        </div>
                    </div>



                </div>
            </blockquote>
            -->
            
        </div>

    </div>
</section>
