<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Automated Gates</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home</a></li>
                        <li><a routerLink="/products">Products</a></li>
                        <li>Automated Gates</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="contact-info-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="h2-cus">Automed Gate Products</h2>
           </div>
                <div class="txt-center">

                <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">
                    <li class="nav-item">
                        <a class="nav-link arrow_box active" id="efa-srt-tab" data-toggle="pill" href="#efa-srt" role="tab"
                            aria-controls="efa-srt" aria-selected="true">
                            <i class="flaticon-settings"></i>
                        </a>
                    </li>
                    <li class="nav-item  ">
                        <a class="nav-link" id="efa-sst-tab" data-toggle="pill" href="#efa-sst" role="tab"
                            aria-controls="efa-sst" aria-selected="false">   <i class="flaticon-network"></i>

                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="efa-stt-tab" data-toggle="pill" href="#efa-stt" role="tab"
                            aria-controls="efa-stt" aria-selected="false">   <i class="flaticon-diagram"></i></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="efa-str-tab" data-toggle="pill" href="#efa-str" role="tab"
                            aria-controls="efa-str" aria-selected="false">    <i class="flaticon-backup"></i></a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" id="efa-srt-mtl-tab" data-toggle="pill" href="#efa-srt-mtl" role="tab"
                            aria-controls="efa-srt-mtl" aria-selected="false">  <i class="flaticon-database-1"></i></a>
                    </li>
                </ul>

            </div>
                <div class="tab-content" id="pills-tabContent">
                    <div class="tab-pane fade" id="efa-srt" role="tabpanel" aria-labelledby="efa-srt-tab">
                        <app-efa-srt></app-efa-srt>
                    </div>
                    <div class="tab-pane fade" id="efa-sst" role="tabpanel" aria-labelledby="efa-sst-tab">
                        <app-efa-sst></app-efa-sst>
                    </div>
                    <div class="tab-pane fade show active" id="efa-stt" role="tabpanel"
                        aria-labelledby="efa-stt-tab">
                        <app-efa-stt></app-efa-stt>
                    </div>

                    <div class="tab-pane fade" id="efa-str" role="tabpanel" aria-labelledby="efa-str-tab">
                        <app-efa-str></app-efa-str>
                    </div>
                    <div class="tab-pane fade" id="efa-srt-mtl" role="tabpanel" aria-labelledby="efa-srt-mtl-tab">
                        <app-efa-srt-mtl></app-efa-srt-mtl>
                    </div>
                </div>
    </div>
</section>
