<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Brouchers</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home </a></li>
                        <li>Brouchers</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="blog-area ptb-100 container">
    <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
        <h2>PRODUCT BROCHURES</h2>
        <!-- <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
            dolore magna aliqua. ipsum suspendisse.</p> -->
    </div>
    <div class="row pt-70">
        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>Automatic Gates</h3>
                    <img src="assets/img/gate-1.jpg" alt="Gates Broucher" type="image/jpeg">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/ndbv/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/Brochure-Gates.pdf"
                        download="Brochure-Gates">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>

            </mat-card>
        </div>

        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>Rolling Shutters</h3>
                    <img src="assets/img/shutter-1.jpg" alt="Shutters Broucher" type="image/jpeg">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/qumz/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/Brochure-Shutters.pdf"
                        download="Brochure-Shutters">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>
            </mat-card>

        </div>

        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>High Speed Doors</h3>
                    <img src="assets/img/doors-bro.PNG" alt="Efaflex_World_of_Doors Broucher" type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/qjef/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/Efaflex_World_of_Doors.pdf"
                        download="Efaflex-World-of-Doors">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>
            </mat-card>
        </div>
    </div>
    <div class="row pt-70">
        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>EFA-Protect-Efficient</h3>
                    <img src="assets/img/efaprotect_series_Efficient.PNG" alt="GB Efaprotect Series Efficient Broucher"  type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/hjcv/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_Efaprotect_Series_Efficient.pdf"
                        download="GB Efaprotect Series Efficient Brochure">Download&nbsp;&nbsp;&nbsp;<i
                            class="fa fa-download" aria-hidden="true"></i></a>
                </div>
            </mat-card>

        </div>

        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>EFA-Protect-Secure</h3>
                    <img src="assets/img/efaprotect_secure.PNG" alt="GB Efaprotect Series Secure Broucher" type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/hjcv/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_Efaprotect_Series_Secure.pdf"
                        download="GB Efaprotect Series Secure Brochure">Download&nbsp;&nbsp;&nbsp;<i
                            class="fa fa-download" aria-hidden="true"></i></a>
                </div>
            </mat-card>

        </div>

        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>EFA-SCAN Door</h3>
                    <img src="assets/img/efa_scan.PNG" alt="GB EFA-SCAN Broucher" type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/rghnq/gsux/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_EFA-SCAN.pdf"
                        download="GB EFA-SCAN Brochure">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>
            </mat-card>
        </div>
    </div>

    <div class="row pt-70">

        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>EFA-SST-PS-150 Doors</h3>
                    <img src="assets/img/ps.PNG" alt="GB EFA-SST-PS-150 Broucher" type="image/png"  class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/rghnq/horb/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_EFA-SST-PS-150.pdf"
                        download="GB EFA-SST-PS-150 Brochure">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>
            </mat-card>
        </div>

        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>EFA F-Series Door</h3>
                    <img src="assets/img/f-series.PNG" alt="Gates Broucher" type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://online.fliphtml5.com/rghnq/mbba/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_F-Series.pdf"
                        download="GB F Series Brochure">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>

            </mat-card>
        </div>

        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>EFA R-Series 150 Door</h3>
                    <img src="assets/img/r_series.PNG" alt="GB R Series Broucher" type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/jkpu/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_R-Series-150.pdf"
                        download="GB R Series Brochure">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>
            </mat-card>

        </div>
    </div>

    <div class="row pt-70">
        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>EFA GB ATEX Door</h3>
                    <img src="assets/img/atex.PNG" alt="GB Serie_ATEX Broucher" type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/nxty/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_Serie_ATEX.pdf"
                        download="GB Serie_ATEX Shutters">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>
            </mat-card>
        </div>

        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>EFA GB CR-150 Door</h3>
                    <img src="assets/img/cr_150.PNG" alt="GB Series CR-150 Broucher" type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/ynlw/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_Serie_CR-150.pdf"
                        download="GB Series CR-150 Shutters">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>
            </mat-card>
        </div>

        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>EFA GB MTL-150 Door</h3>
                    <img src="assets/img/mtl_150.PNG" alt="GB Series MTL-150 Door Broucher" type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/rwhm/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_Serie_MTL-150.pdf"
                        download="GB Series MTL-150 Door Broucher">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>
            </mat-card>
        </div>




    </div>

    <div class="row pt-70">

        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>GB S-Series 150 Door</h3>
                    <img src="assets/img/doors-bro.PNG" alt="GB S-Series Broucher" type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/jkpu/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_S-Series-150.pdf"
                        download="GB_S-Series-Shutters">Download&nbsp;&nbsp;&nbsp;<i class="fa fa-download"
                            aria-hidden="true"></i></a>
                </div>
            </mat-card>
        </div>


        <div class="col-lg-4 col-sm-4">
            <mat-card class="example-card">
                <div class="brochure" style="opacity: 1;">
                    <h3>GB TK-Series-100-Screen Door</h3>
                    <img src="assets/img/100_screen.PNG" alt="GB TK-Series-100-Screen Doors Broucher" type="image/png" class="imgh">
                    <a class="stripe" id="btnRM" href="https://fliphtml5.com/fcdiz/hpno/"
                        target="_blank">View&nbsp;&nbsp;&nbsp;<i class="fa fa-eye" aria-hidden="true"></i></a><br>
                    <a class="stripe view" href="assets/img/pdf/GB_TK-Series-100-screen.pdf"
                        download="GB TK-Series-100-Screen Doors Brochure">Download&nbsp;&nbsp;&nbsp;<i
                            class="fa fa-download" aria-hidden="true"></i></a>
                </div>
            </mat-card>
        </div>


    </div>
</section> <!-- end s-about -->
