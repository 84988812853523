import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})

export class NavbarComponent implements OnInit {
    menu = false;
  constructor() { }
  ngOnInit(): void {
  }

  closeMenu() {
      this.menu = false;
  }
  menutoggle() {
    if (this.menu === true) {
        this.menu = false;
    }
    if (this.menu === false) {
        this.menu = true;
    }
}


}
