<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Automated Gates</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home</a></li>
                        <li><a routerLink="/products">Products</a></li>
                        <li>Automated Gates</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="contact-info-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="h2-cus"><b>Automated Sliding Gates</b></h2>
            <!-- </div>
        <div class="txt-center"> -->
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                <li class="nav-item  ">
                    <a class="nav-link" id="sliding-tab" (click)="turnWhite('sliding')" [ngClass]="{'active' : page == 'sliding'}" data-toggle="pill"
                        href="#sliding" role="tab" aria-controls="sliding" aria-selected="false"> <i class="">
                            <img class="cus-icon" [hidden]="!slidingblack" src="assets/img/icons/Gates/sliding-gate.png" alt="Image" type="image/jpeg">
                            <img class="cus-icon" [hidden]="!slidingwhite" src="assets/img/icons/Gates/sliding-gate-white.PNG" alt="Image" type="image/jpeg">
                        </i>

                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="telesocpic-tab" (click)="turnWhite('telesocpic')" [ngClass]="{'active' : page == 'telesocpic'}"
                        data-toggle="pill" href="#telesocpic" role="tab" aria-controls="telesocpic"
                        aria-selected="false"> <i class="">
                            <img class="cus-icon" [hidden]="!telescopicblack"
                                src="assets/img/icons/Gates/telescopic-gate.png" alt="Image" type="image/png">
                            <img class="cus-icon" [hidden]="!telescopicwhite"
                                src="assets/img/icons/Gates/telescopic-gate-white.PNG" alt="Image" type="image/png">
                        </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link arrow_box " (click)="turnWhite('cantilever')" [ngClass]="{'active' : page == 'cantilever'}" id="cantilever-tab"
                        data-toggle="pill" href="#cantilever" role="tab" aria-controls="cantilever"
                        aria-selected="true">
                        <i class="">
                            <img class="cus-icon" [hidden]="!cantileverblack"
                                src="assets/img/icons/Gates/cantilever.png" alt="Image" type="image/png">
                            <img class="cus-icon" [hidden]="!cantileverwhite"
                                src="assets/img/icons/Gates/cantilever-white.PNG" alt="Image" type="image/png">
                        </i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="retractable-tab"  (click)="turnWhite('retractable')" [ngClass]="{'active' : page == 'retractable'}"
                        data-toggle="pill" href="#retractable" role="tab" aria-controls="retractable"
                        aria-selected="false"> <i class="">
                            <img class="cus-icon" [hidden]="!retractableblack"
                                src="assets/img/icons/Gates/retractable.png" alt="Image" type="image/png">
                            <img class="cus-icon" [hidden]="!retractablewhite"
                                src="assets/img/icons/Gates/retractable-white.PNG" alt="Image" type="image/png">
                        </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="swing-tab" (click)="turnWhite('swing')" [ngClass]="{'active' : page == 'swing'}" data-toggle="pill"
                        href="#swing" role="tab" aria-controls="swing" aria-selected="false"> <i class="">
                            <img class="cus-icon" [hidden]="!swingblack" src="assets/img/icons/Gates/swing-gate.png"
                                alt="Image" type="image/png">
                            <img class="cus-icon" [hidden]="!swingwhite" src="assets/img/icons/Gates/swing-gate-white.PNG"
                                alt="Image" type="image/png">
                        </i></a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="boom-tab" (click)="turnWhite('boom')" [ngClass]="{'active' : page == 'boom'}" data-toggle="pill"
                        href="#boom" role="tab" aria-controls="boom" aria-selected="false"> <i class="">
                            <img [hidden]="!boomblack" class="cus-icon" src="assets/img/icons/Gates/boom.png"
                                alt="Image" type="image/png">
                            <img [hidden]="!boomwhite" class="cus-icon" src="assets/img/icons/Gates/boom-white.PNG"
                                alt="Image" type="image/png">
                        </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="curve-tab" (click)="turnWhite('curve')" [ngClass]="{'active' : page == 'curve'}" data-toggle="pill"
                        href="#curve" role="tab" aria-controls="curve" aria-selected="false"> <i class="">
                            <img [hidden]="!curveblack" class="cus-icon" src="assets/img/icons/Gates/curvedblack.png"
                                alt="Image" type="image/png">
                            <img [hidden]="!curvewhite" class="cus-icon" src="assets/img/icons/Gates/curvedwhite.png"
                                alt="Image" type="image/png">
                        </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="bifold-tab" (click)="turnWhite('bifold')" [ngClass]="{'active' : page == 'bi-fold'}" data-toggle="pill"
                        href="#bifold" role="tab" aria-controls="bifold" aria-selected="false"> <i class="">
                            <img [hidden]="!bifoldblack" class="cus-icon" src="assets/img/icons/Gates/bifoldblack.png"
                                alt="Image" type="image/png">
                            <img [hidden]="!bifoldwhite" class="cus-icon" src="assets/img/icons/Gates/bifoldwhite.png"
                                alt="Image" type="image/png">
                        </i></a>
                </li>


            </ul>


        </div>

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'sliding'}" id="sliding" role="tabpanel"
                aria-labelledby="sliding-tab">
                <app-sliding-gates></app-sliding-gates>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'telesocpic'}" id="telesocpic"
                role="tabpanel" aria-labelledby="telesocpic-tab">
                <app-teliscopic-gates></app-teliscopic-gates>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'cantilever'}" id="cantilever"
                role="tabpanel" aria-labelledby="cantilever-tab">
                <app-cantilever-gates></app-cantilever-gates>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'retractable'}" id="retractable"
                role="tabpanel" aria-labelledby="retractable-tab">
                <app-retractable-gates></app-retractable-gates>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'swing'}" id="swing" role="tabpanel"
                aria-labelledby="swing-tab">
                <app-swing-gates></app-swing-gates>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'boom'}" id="boom" role="tabpanel"
                aria-labelledby="boom-tab">
                <app-boom-barriers></app-boom-barriers>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'curve'}" id="curve" role="tabpanel"
                aria-labelledby="curve-tab">
                <app-curve-gates></app-curve-gates>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'bifold'}" id="bifold" role="tabpanel"
                aria-labelledby="bifold-tab">
                <app-bifold-gates></app-bifold-gates>
            </div>


        </div>
    </div>
</section>
