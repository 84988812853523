<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Hangar Door</h2>
            <p>Gatemaan makes them ideal for large-scale exterior door locations such as aircraft hangars, in all sizes from military planes to jumbo jets. Hangar door is reliable, long-lasting and does not require much maintenance. It endures heavy-duty industrial wear and its maintenance costs are low. These are designed to withstand fluctuations in moisture levels, temperature and stand up to even the most violent winds.
            </p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                    style="visibility: visible;">
                    <img src="assets/img/products/doors/hang2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/doors/hang1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/doors/hang3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/doors/hang4.png" alt="Image" type="image/png" width="100%" height="auto">
                    <img src="assets/img/products/doors/hang5.png" alt="Image" type="image/png" width="100%" height="auto">
                </div>

                    <div class="article-content" align="justify">
                        <h3>About Hanagr Door</h3>
                        <p>The work processes in an aircraft hangar are very demanding and require maximum organisation, efficiency and reliability. These requirements have to be fulfilled and we offers full support to master this task.
                            We support you right from the start in all planning considerations. We advise you on the size and position of your hangar door as well as on the opening principle, type of drive and choice of filling material. We take your safety equipment and architectural design aspects into account. Our specialists are your competent partners for all questions concerning the conception and planning of your hangar door.
                        </p>
                        <ul class="feat" align="justify" style="line-height:180%">
                        <li>Gatemaan Hangar Doors are lightweight but durable. Hangar door fabric looks tidy and it is easy to maintain or change in parts with low maintenance costs
                        </li>
                        <li>Hangar doors do not need extra heavy support structures like many other hoist-up fabric hangar doors on the market, which significantly helps reduce the costs.
                        </li>
                        <li>Doors have number of configurations. There is due to the design, allowing either the whole door to fold away, or if you need quick and easy access a small pedestrian part of the door can fold on its own.
                        </li>
                        <li>Gatemaan offers a comprehensive service intended to make the acquisition and use of doors as easy as possible, all the way from the design stage to the maintenance service covering the entire service life.
                        </li>
</ul>>
                    </div>

            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>
            <blockquote class="flaticon-quote">
                <table class="table">
     <thead class="table-light">
       <tr>
         <th scope="col">Product : </th>
         <th scope="col">Motorized Sliding Gate</th>
       </tr>
     </thead>
     <tbody>
       <tr>
         <th scope="row" color="#fe9900">Door Dimension: </th>
         <td></td>
       </tr>
       <tr>
         <th scope="row">Coming Soon</th>
         <td></td>
       </tr> 
     </tbody>
   </table>  
 </blockquote>
        </div>

    </div>
</section>
