<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Dock shelter</h2>
            <p>Dock Shelters are compressible foam blocks against which the truck presses when parked; seals are used at exterior truck bays in colder climates where this will provide protection from the weather. Dock Shelters also known as Dock Seals. </p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/products/Docks/s1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/Docks/s2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/Docks/s3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/Docks/s4.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/Docks/s5.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                </div>

                <div class="article-content">
                    <h3>About Dock shelter</h3>
                    <p>It is a height-adjustable platform used to ensure smooth transition between dock and truck which
                        helps prevent forklift accidents that can cause serious injuries and forklift damages. These
                        Dock Levelers is placed at the exterior of the building in a loading bay. This is an actual
                        bridge between the loading area and the vehicle, so as to balance out all the difference in
                        height and distance. It enhance safety and productivity by avoiding multiple handling of the
                        loads.</p>
                </div>
<li>Fully impactable side frames protect unit from damage from backing trailers</li>
<li>Integral, fabric-wrapped rain gutter diverts water off sides of shelter.</li>
<li>side curtains allow easy removal and reattachment for ventilation or to replace or exchange curtains when needed.</li>
<li>Provides dramatic energy savings.</li>


            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>
            <blockquote class="flaticon-quote">
                <h3>Coming Soon</h3>
            </blockquote>
        </div>

    </div>
</section>
