<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>CONTACT</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home</a></li>
                        <li>Contact</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="contact-info-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="row">
                    <div class="col-lg-6 col-sm-6 col-md-6 ">
                        <div class="single-contact-info">
                            <!-- <i class="bx bx-envelope"></i> -->
                            <div class="row">
                                <div class="col-sm-6 hddr-bg">
                                    <img src="../../../../assets/img/envelope.png"  alt="Image" type="image/png" class="cnt-img"> <span class="hddr"> Mail :</span>
                                </div>
                                <div class="col-sm-6"></div>
                            </div>
                            <div class="row below-head">
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 cadiv">
                                    <h4>Any Queries</h4>
                                    <p class="mail_p">info@gatemaan.com</p>
                                </div>
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                                    <h4>Sales</h4>
                                    <p class="mail_p">bsp@gatemaan.com</p>
                                </div>

                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">

                                    <h4>Purchase</h4>
                                    <p class="mail_p">purchase@gatemaan.com</p>
                                </div>
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12">
                                    <h4>Support</h4>
                                    <p class="mail_p">support@gatemaan.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-sm-6 col-md-6 ">

                        <div class="single-contact-info">
                            <div class="row">
                                <div class="col-sm-12 hddr-bg">
                                    <img src="../../../../assets/img/call.png"  alt="Image" type="image/png" class="cnt-img"> <span class="hddr"> Call :</span>
                                </div>
                            </div>
                            <div class="row  below-head">
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 cadiv fadeInUp">
                                    <h4>Corporate Office</h4>
                                    <p>040-4852 6886
                                     </p>
                                </div>
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 fadeInUp">
                                    <h4>Vishakapatnam</h4>
                                    <p>+91 88860 87872</p>
                                </div>
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 fadeInUp">
                                    <h4>Chennai</h4>
                                    <p>+91 93817 12037</p>
                                </div>
                                <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 fadeInUp">
                                    <h4>Bengaluru</h4>
                                    <p>+91 88860 87872</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-6 col-sm-6 col-md-6">
                        <div class="single-contact-info">
                            <i class="bx bx-support"></i>
                            <h3>Live Chat</h3>
                            <a href="#">live chat all the time with our company 24/7</a>
                        </div>
                    </div> -->
                </div>
            </div>

            <div class="col-lg-7 col-sm-7 col-md-7">
                <div class="single-contact-info">
                    <div class="row">
                        <div class="col-sm-12 hddr-bg">
                            <img src="../../../../assets/img/map.png"  alt="Image" type="image/png" class="cnt-img"> <span class="hddr">Visit us :</span>
                        </div>
                    </div>
                    <div class="row  below-head">
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 cadiv">

                            <h4>Registered Office (Plant - 1)</h4>
                            <p>D-11, Phase IV, Extenstion IDA,
                                <br>
                                Jeedimetla, Hyderabad,
                                <br>
                                Telangana - 500055.</p>
                        </div>
                        <br>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 cadiv">
                           
                        </div>
                        <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 cadiv">
                            <h4>Plant - 2</h4>
                            <p>S.No 1411, Pandiguttoor Village,
                                <br>
                                Mudigolam Post, Irala Mandal, Chittoor,
                                <br> Andhra Pradesh - 517124.</p>
                        </div>

                       <!-- <div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 cadiv">
                            <h4>Tirupathi Office</h4>
                            <p>Flat No: 102, Ground Floor,
                                krishna nikunj Apartments,
                                Renigunta Road, Tirupathi,<br>
                                Andhra Pradesh-517501.</p>
                        </div>-->
                        <!--<div class="col-lg-6 col-sm-6 col-md-6 col-xs-12 cadiv">
                            <h4>Bengaluru Office</h4>
                            <p>#1578, Behind Light Living House,
                                Agara HSR Layout Sector,
                                Bengaluru,<br> Karnataka - 560102.</p>
                        </div>-->
                    </div>
                </div>
            </div>
            <div class="col-lg-5 col-md-5 ">
                    <div class="single-contact-info">
                        <div class="row">
                            <div class="col-sm-12 hddr-bg">
                                <img src="../../../../assets/img/envelope.png"  alt="Image" type="image/png" class="cnt-img"> <span class="hddr"> Drop Message :</span>
                            </div>
                        </div>

                        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" id="contactForm below-head">
                                <div class="form-group">
                                    <!-- <label>Name</label> -->
                                    <input type="text"  placeholder="Name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label>Phone</label> -->
                                    <input type="text" placeholder="Phone" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                                        <div *ngIf="f.phone.errors.required">Phone number is required</div>
                                        <div *ngIf="f.phone.errors.pattern">Phone number is not valid</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label>Email</label> -->
                                    <input type="text"  placeholder="Email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label>Subject</label> -->
                                    <input type="text"  placeholder="Subject" formControlName="subject" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.subject.errors }" />
                                    <div *ngIf="submitted && f.subject.errors" class="invalid-feedback">
                                        <div *ngIf="f.subject.errors.required">Subject is required</div>
                                        <!-- <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div> -->
                                    </div>
                                </div>
                                <div class="form-group">
                                    <!-- <label>Message</label> -->
                                    <input type="text" placeholder="Message" formControlName="message" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" />
                                    <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                                        <div *ngIf="f.message.errors.required">Message is required</div>
                                        <!-- <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div> -->
                                    </div>
                                </div>

                            <div class="text-center">

                                <button class="default-btn btn-two">
                                    <i class="fa fa-spinner fa-spin" style="font-size:24px" *ngIf="showSpinner === true"></i> <span  *ngIf="showSpinner === false">Send Message</span></button>
                                <!-- <button class="btn btn-secondary" type="reset" (click)="onReset()">Cancel</button> -->
                            </div>
                        </form>


                        <!-- <form id="contactForm below-head" #myform = "ngForm" (ngSubmit) = "onSubmit(myform)" >
                            <div class="row">
                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <input type="text" name="name" class="form-control" placeholder="Name" ngModel required>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-group">
                                       <input type="email" name="email" class="form-control" placeholder="Email" ngModel required>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-group">
                                       <input type="text" pattern="\d*" maxlength="10" name="phone"  class="form-control" placeholder="Phone" ngModel required>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-group">
                                        <input type="text" name="subject" class="form-control" placeholder="Subject" ngModel required>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <div class="form-group">
                                       <textarea name="message" class="form-control" placeholder="Message" ngModel required></textarea>
                                    </div>
                                </div>

                                <div class="col-sm-12">
                                    <button type="submit" class="default-btn btn-two">Send Message</button>
                                </div>
                            </div>
                        </form> -->

                    </div>

            </div>
        </div>
    </div>
</section>
<div id="map">
    <div class="mapouter"><div class="gmap_canvas"><iframe width="100%" height="500" id="gmap_canvas" src="https://maps.google.com/maps?q=NITHYAA%20SAI%20METAL%20TECHNICS%20D-11%2C%20Phase%20IV%20Extension%2C%20IDA%20Jeedimetla%2C%20Chinthal%2C%20Jeedimetla%2C%20Hyderabad%2C%20Telangana%20500055&t=&z=13&ie=UTF8&iwloc=&output=embed" frameborder="0" scrolling="no" marginheight="0" marginwidth="0"></iframe><a href="https://www.embedgooglemap.net/blog/divi-discount-code-elegant-themes-coupon/"></a></div><style>.mapouter{position:relative;text-align:right;height:500px;width:100%;}.gmap_canvas {overflow:hidden;background:none!important;height:500px;width:100%;}</style></div>
</div>
<!-- <section class="main-contact-area pb-100">
    <div class="container">
        <div class="row">
            <div class="col-12">

            </div>
        </div>
    </div>
</section> -->
