<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Galvalume Shutters</h2>
            <p> Galvalume rolling shutters are a type of protective door or barrier commonly used in commercial and industrial settings. They are designed to provide security, insulation, and ease of operation for openings such as storefronts, warehouses, garages, and other similar spaces.
<br>
                Galvalume refers to the material used in the construction of these rolling shutters. Galvalume is a coated steel product that consists of a combination of aluminum, zinc, and silicon.Galvalume material consist of 55% aluminium
                43.4% Zinc and 1.6% silicon. Material solidified in 600 degree centigrade high temperature to resist
                corrosion factors.  This coating provides excellent corrosion resistance, making it suitable for outdoor applications where exposure to the elements is a concern.</p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/products/shutters/galva1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/galva2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                </div>

                    <div class="article-content" align="justify">
                        <h3>About Galvalume Shutter</h3>
                        <p>Galvalume Rolling Shutter are ideally suited for most types of Industrial and Commercial
                            Applications. The Aluminium – zinc alloy coating offers excellent corrosion resistance. It
                            retains the sacrificial properties of zinc related steel while aluminium provides a tough
                            film of aluminium oxide to increase barrier protection. Gatemaan continue to gain approval
                            by Specifiers, Architects, Consultants, Contractors, Builders and many other due to its
                            versatility, Ease of use, Aesthetics and long term performance. Counter balance system for
                            manual operation with strong and durable spring. We provide nylon belt to prevent noise,
                            options for easy manual and electrical operation.
                            <br>
                            <br>
                            The rolling shutters are typically composed of interlocking metal slats that are connected to form a continuous curtain. These slats are made from galvalume-coated steel and are often filled with insulating materials to enhance thermal and acoustic insulation properties.
The shutters are operated by a motorized system, allowing for easy opening and closing. They can be controlled manually, using a switch or a remote control, or integrated into an automated system for added convenience and security.
                        </p>
                        <ul class="feat" style="line-height:180%">
                            <li>Resistant to corrosion and are sturdy, reliable and environment friendly</li>
                            <li>Wide range of Aluminium roller shutters are present with GATEMAAN
                                with variety of components and profiles including guide rails, shutter
                                boxes, manual drives, end caps and many more</li>
                            <li>Retain their shape and colour for more than 15 years</li>
                            <li>it retains a very bright surface appearance when exposed to most atmospheres</li>
                        </ul>
<p>Our superior corrosion resistance of Galvalume Shutter is achieved by the presence of microscopic zinc-rich and aluminium-rich areas within the coating. The aluminium-rich areas, which corrode very slowly, provide the long-term durability while the zinc-rich areas, which corrode preferentially, provide galvanic protection. since the coating is so resistant to corrosion.</p>
                    </div>

            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>

            <table class="table table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Product : </th>
                    <th scope="col">Motorized Galvalume Rolling Shutter</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" color="#fe9900">Shutter Dimension : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Width Min. /Max.</th>
                    <td>1000 / 4200mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Height Min. /Max.</th>
                    <td>1000 / 4500mm; Max. Area 18.0 Sqm</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening Dimension</th>
                    <td>Vertical</td>
                  </tr>
                  <tr>
                    <th scope="row">Mounting Location</th>
                    <td>Inside</td>
                  </tr>
                  <tr>
                    <th scope="row">Shutter Material</th>
                    <td>Aluminum Alloy</td>
                  </tr>
                  <tr>
                    <th scope="row">Maximum Width * Height:</th>
                    <td>6 * 8 m</td>
                  </tr>
                  <tr>
                    <th scope="row"> Type of box</th>
                    <td>165 185 205 250 300 350mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Thickness</th>
                    <td>1.2mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Surface Treatin</th>
                    <td>Powder Coating</td>
                  </tr>
                  <tr>
                    <th scope="row">The sevice life</th>
                    <td>can be used 15 years</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening/Closing Speed</th>
                    <td>0.05m-0.2 m/sec</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Up to 90 km/hr</td>
                  </tr>
                  
    
                  <tr>
                    <th scope="row" color="#fe9900">Shutter Structure : </th>
                    <td></td>
                  </tr>
    
                  <tr>
                    <th scope="row">Slat Material</th>
                    <td>Non Insulated Double Wall Aluminum profiled Slats</td>
                  </tr>
                  <tr>
                    <th scope="row">Slat Profile</th>
                    <td>Corrugation profile</td>
                  </tr>
                  <tr>
                    <th scope="row">Bottom Profile</th>
                    <td>Aluminum profile with Bottom Rubber sealing.
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Side Guide Profile</th>
                    <td>Aluminum profile with Rubber inserts on both ends.</td>
                  </tr>
                  <tr>
                    <th scope="row">Roller Shaft</th>
                    <td>M.S Tubular Pipe with flanges & machined shaft at the ends.
                        Pipe dia-100 to 300mm, as per the opening dimension.</td>
                  </tr>
                  <tr>
                    <th scope="row">Side Brackets</th>
                    <td>M.S plate 3mm to 10mm,as per the opening dimension.</td>
                  </tr>
                  <tr>
                    <th scope="row">Surface Finish</th>
                    <td>Standard Off White Color</td>
                  </tr>
    
                  <tr>
                    <th scope="row" color="#fe9900"></th>
                    <td>Special Color on request</td>
                  </tr>
    
                  <tr>
                    <th scope="row">Details for Automation System : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Control Type</th>
                    <td>Deadman / Pulse Control</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Type</th>
                    <td>Indirect / Direct Connecting Drive</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Unit</th>
                    <td>Electric Motor(Imported Make) Single Phase/Three Phase.</td>
                  </tr>
                  <tr>
                    <th scope="row">Emergency Operation	</th>
                    <td>Manual operation by Hand chain</td>
                  </tr>
                  <tr>
                    <th scope="row" color="#fe9900">Options Available</th>
                    <td>Up to 90 km/hr</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Perforation In Slat profile
                        Rolling Grill
                        Wicket Door for pedestrian entry</td>
                  </tr>
                  <tr>
                    <th scope="row">Features : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Certification</th>
                    <td>CE VDE checked</td>
                  </tr>
                  <tr>
                    <th scope="row">Protection Class</th>
                    <td>IP54.</td>
                  </tr>
                  
                </tbody>
              </table>
    
<!--
            <blockquote class="flaticon-quote">
                <div class="tab-pane active" role="tabpanel" id="tab-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <tbody>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td style="width:120px"><span class="fa fa-thumbs-o-up"></span>
                                                &nbsp;Product</td>
                                            <td>Motorized Galvalume-shutter
                                            </td>
                                        </tr>
                                        <tr>

                                            <td colspan="2">Door Dimension</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><span class="fa fa-dashboard"></span> &nbsp;Width
                                                Min. /Max.</td>
                                            <td> 1000/4200mm</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;" class="txt-clr"><span
                                                    class="fa fa-gears"></span>&nbsp; Height Min. /Max.</td>
                                            <td class="txt-clr">1000/4500mm
                                                Max. Area 18.0Sqm</td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px"><i class="fa fa-steam"></i>&nbsp;Opening Dimension
                                            </td>
                                            <td>Vertical</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;" class="txt-clr"><i
                                                    class="fa fa-fire-extinguisher"></i>&nbsp; Mounting Location</td>
                                            <td class="txt-clr">Inside</td>
                                        </tr>




                                    </tbody>

                                </table>

                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <td style="width:120px"><span></span> &nbsp;Door Material</td>
                                            <td>Aluminum Alloy</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;" class="txt-clr"><span></span> &nbsp;Maximum Width *
                                                Height:</td>
                                            <td class="txt-clr">6*8m</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><span></span> &nbsp;Type of box</td>
                                            <td>165 185 205 250 300 350mm</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;" class="txt-clr"><span></span>&nbsp;Thickness</td>
                                            <td class="txt-clr">1.2mm</td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px"><i></i>&nbsp;Surface Treatin </td>
                                            <td>Powder Coating</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;" class="txt-clr"><i></i>&nbsp; The sevice life</td>
                                            <td class="txt-clr">can be used 15 years</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><i></i>&nbsp;Opening/Closing Speed </td>
                                            <td>0.05m-0.2 m/sec</td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    <hr>


                    <!-- specification table 2 

                    <div class="row">
                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <tbody>



                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Wind Resistance </td>
                                            <td style="text-align:center;">Up to 90 km/hr</td>
                                        </tr>

                                        <tr>

                                            <td colspan="2" style="text-align:center;">Door Structure</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;color: #fff"><i class=""></i>&nbsp;Slat Material
                                            </td>
                                            <td style="text-align:center;">Non Insulated Double Wall Aluminum profiled
                                                Slats</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;color:aliceblue;"><i class=""></i>&nbsp;Slat Profile
                                            </td>
                                            <td>Corrugation profile</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;color: #fff;"><i class=""></i>&nbsp;Bottom Profile
                                            </td>
                                            <td style="text-align:center;">Aluminum profile with Bottom Rubber sealing
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;color: white;"><i class=""></i>&nbsp;Side Guide
                                                Profile </td>
                                            <td>Aluminum profile with Rubber inserts on both ends.</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;color: #fff;"><i class=""></i>&nbsp;Roller Shaft
                                            </td>
                                            <td style="text-align:center;">M.S Tubular Pipe with flanges &amp; machined
                                                shaft at the ends.<br>
                                                Pipe dia-100 to 300mm, as per the opening dimension.</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Side
                                                Brackets </td>
                                            <td>M.S plate 3mm to 10mm,as per the opening dimension</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;color: #fff;"><i class=""></i>&nbsp;Surface Finish
                                            </td>
                                            <td style="text-align:center;">Standard Off White Color
                                                <hr>
                                                Special Color on request</td>
                                        </tr>



                                    </tbody>
                                </table>

                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <tbody>
                                        <tr>

                                            <td colspan="2" style="text-align:center;">Details for Automation System
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Control Type
                                            </td>
                                            <td>Deadman/Pulse Control</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><i class=""></i>&nbsp;Drive Type </td>
                                            <td>In Direct /Direct Connecting Drive</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Drive Unit
                                            </td>
                                            <td>Electric Motor(Imported Make) Single Phase/Three Phase</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><i class=""></i>&nbsp;Emergency Operation </td>
                                            <td>Manual operation by Hand chain</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Options
                                                Available </td>
                                            <td>Up to 90 km/hr</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><i class=""></i>&nbsp;Wind Resistance </td>
                                            <td>Perforation In Slat profile<br>
                                                Rolling Grill<br>
                                                Wicket Door for pedestrian entry</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;color: aliceblue"><i
                                                    class=""></i>&nbsp;Accessories(Optional)
                                                *can be integrated with advanced control </td>
                                            <td>
                                                Additional Push Button<br>
                                                Remote Control with receiver<br>
                                                Pull Cord Switch<br>

                                            </td>

                                        </tr>

                                        <tr>

                                            <td colspan="2">Features</td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px;"><i
                                                    class=""></i>&nbsp;Certification </td>
                                            <td>CE VDE checked<br>

                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><i class=""></i>&nbsp;Protection Class </td>
                                            <td>IP54<br>

                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </blockquote> -->
        </div>

    </div>
</section>
<section class="bottomright">
    <div class="single-contact-info">
        <div class="row">
            <div class="col-sm-12 hddr-bg">
                <img src="../../../../assets/img/envelope.png"  alt="Image" type="image/png" class="cnt-img"> <span class="hddr"> Drop Message :</span>
            </div>
        </div>

        <form [formGroup]="registerForm" (ngSubmit)="onSubmit()" id="contactForm below-head">
                <div class="form-group">
                    <!-- <label>Name</label> -->
                    <input type="text"  placeholder="Name" formControlName="name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                        <div *ngIf="f.name.errors.required">Name is required</div>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <label>Phone</label> -->
                    <input type="text" placeholder="Phone" formControlName="phone" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.phone.errors }" />
                    <div *ngIf="submitted && f.phone.errors" class="invalid-feedback">
                        <div *ngIf="f.phone.errors.required">Phone number is required</div>
                        <div *ngIf="f.phone.errors.pattern">Phone number is not valid</div>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <label>Email</label> -->
                    <input type="text"  placeholder="Email" formControlName="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                        <div *ngIf="f.email.errors.required">Email is required</div>
                        <div *ngIf="f.email.errors.email">Email must be a valid email address</div>
                    </div>
                </div>
                <div class="form-group">
                    <!-- <label>Subject</label> -->
                    <input type="hidden"  placeholder="Subject" value="Enquiry About Galvalume Shutters" formControlName="subject" class="form-control" />

                </div>
                <div class="form-group">
                    <!-- <label>Message</label> -->
                    <input type="text" placeholder="Message" formControlName="message" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.message.errors }" />
                    <div *ngIf="submitted && f.message.errors" class="invalid-feedback">
                        <div *ngIf="f.message.errors.required">Message is required</div>
                        <!-- <div *ngIf="f.password.errors.minlength">Password must be at least 6 characters</div> -->
                    </div>
                </div>

            <div class="text-center">
                <button class="btn btn-secondary cust-btn">
                    <i class="fa fa-spinner fa-spin" style="font-size:24px" *ngIf="showSpinner === true"></i> <span  *ngIf="showSpinner === false">Send Message</span></button>
                    <button class="btn btn-secondary" type="reset" (click)="onReset()">Cancel</button>
            </div>
        </form>
    </div>
    </section>
