<section class="blog-details-area">
    <br>
    <div class="row">

        <div class="col-sm-12 text-center">
            <ul class="nav nav-pills mb-3 text-center bordernav" id="pills-tab" role="tablist">
                <li class="nav-item">
                    <a class="nav-link active anav" id="sst-secure-tab" data-toggle="pill" href="#sst-secure" role="tab"
                        aria-controls="sst-secure" aria-selected="true">EFA SST® Secure</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="sst-efficient-tab" data-toggle="pill" href="#sst-efficient" role="tab"
                        aria-controls="sst-efficient" aria-selected="false">EFA SST® Efficient</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="sst-ms-tab" data-toggle="pill" href="#sst-ms" role="tab"
                        aria-controls="sst-ms" aria-selected="false">EFA SST® MS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="sst-essential-tab" data-toggle="pill" href="#sst-essential" role="tab"
                        aria-controls="sst-essential" aria-selected="false">EFA SST® Essential</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="sst-ex-tab" data-toggle="pill" href="#sst-ex" role="tab"
                        aria-controls="sst-ex" aria-selected="false">EFA SST® EX</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="sst-tk-tab" data-toggle="pill" href="#sst-tk" role="tab"
                        aria-controls="sst-tk" aria-selected="false">EFA SST® TK</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="sst-ps-tab" data-toggle="pill" href="#sst-ps" role="tab"
                        aria-controls="sst-ps" aria-selected="false">EFA SST® PS</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="sst-iso-tab" data-toggle="pill" href="#sst-iso" role="tab"
                        aria-controls="sst-iso" aria-selected="false">EFA SST® ISO 60</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link anav" id="sst-sst-tab" data-toggle="pill" href="#sst-sst" role="tab"
                        aria-controls="sst-sst" aria-selected="false">EFA SST®</a>
                </li>
            </ul>
        </div>

    </div>
    <br>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade" id="sst-sst" role="tabpanel" aria-labelledby="sst-sst-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SST®</h2>
                    <p>Worldwide increasing energy prices have prompted EFAFLEX to design completely new high-speed
                        spiral doors as hall closure. The technically new design constitutes an improvement of the
                        physical characteristics of the door leaf as well as an optimisation of the functionality.
                        <br>
                        <br>
                        As the first manufacturer worldwide, we will from now offer thermally separated EFA-THERM®
                        insulation
                        laths for the EFA-SST® as a standard. Thus, depending on the size of the door, an excellent heat
                        insulation of between 0.66 and 1.52 W/m2K can be achieved. The new door leaf is exceptionally
                        robust,
                        long-lived, tight and sound insulating. Depending on the desired light entry, we include as many.
                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SST/1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/3.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/4.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/5.jpg" alt="Image" width="100%" height="auto">

                        </div>

                            <div class="article-content">
                                <h3>About EFA-SST®</h3>
                                <p>Today, energy as one of the most important factors with regard to profitability is
                                    more important than ever
                                    before. We at EFAFLEX react to increasing prices and an uncertain raw materials
                                    supply: due to the fast
                                    opening and closing speed of our doors, only few energy gets lost towards outside or
                                    between two rooms.
                                    By using EFAFLEX high-speed doors, you can efficiently reduce your energy costs.
                                </p>
                                <br>
                                <strong>Save massively on Energy Costs</strong>
                                <p>
                                    We are the world’s first manufacturer to provide a series of thermally insulated
                                    EFA-THERM® insulation laths
                                    for EFA-SST®. The thermally insulated EFA-THERM® laths of the EFA-SST® considerably
                                    improve the thermal
                                    insulation, thus lowering your energy costs.
                                </p>
                                <br>
                                <strong>Individual Design Possible</strong>
                                <p>
                                    In addition to the aluminium-coloured standard coating, we also paint the laths as
                                    well as the steel parts
                                    of the door in almost every colour of the RAL system, if requested by our customers.
                                    Thus, our high speed
                                    doors can architecturally be perfectly adjusted to every facade or get the colour of
                                    your house which gives
                                    your premises a representative look.
                                </p>
                                <h3>Self-repairing door leaf</h3>
                                <strong>Fast and Safe</strong>
                                <p>
                                    As the only manufacturer of high-speed doors worldwide, EFAFLEX offers a combination
                                    of fixed door leaf and
                                    an active crash system. EFA-ACS® does not only protect the door against being
                                    damaged and therefore against
                                    operating failure and repairs - it carries out the "self-repair" fully automatically
                                    within just a few seconds!
                                    In the case of a crash, your door will henceforth nevertheless be immediately ready
                                    for use again.
                                </p>

                                <strong>Fast and Safe</strong>
                                <p>
                                    In the case of a collision, the detachably connected laths are pressed out of the
                                    guidance without being
                                    damaged. This is recognised by an inductive sensor system which joins the inner and
                                    the outer hinges of
                                    the door leaf again in a safe and correct manner during a slower upwards movement.
                                </p>

                                <h3>Perfect door leaf guide</h3>

                                <strong>Door leaf guidance in perfection</strong>
                                <p>
                                    The door leaf is not wound-up on a shaft but kept space-saving in a certain distance
                                    in the patented EFAFLEX
                                    spiral. This functional principle guarantees quite a number of unique advantages:
                                    only this unique design
                                    combines highest opening speed, long service life and efficiency in such a perfect
                                    manner.
                                </p>
                                <strong>Oval spiral and low header</strong>
                                <p>
                                    EFAFLEX offers you high-speed spiral doors in different versions. The circular round
                                    spiral is the standard and
                                    at the same time the solution of highest opening and closing speed. If there is only
                                    few space above the door,
                                    you can also chose between two space-saving variants of the many door types of the S
                                    series- oval spiral and low header.
                                </p>



                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>



                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST®</th>
                 <th> </th>
                 <th> </th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                   <td scope="col">S
                </td>
                <td scope="col">ÜS
                </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>•
                </td>
                <td scope="col">•
                    <br>•
                </td>
                <td scope="col">•
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">2 - 4
               </td>
               <td scope="col">4
            </td>
            <td scope="col">2 - 4
            </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
               <td scope="col">fulfilled
            </td>
            <td scope="col">fulfilled
            </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
               <td scope="col">0
            </td>
            <td scope="col">0
            </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">2
               </td>
               <td scope="col">2
            </td>
            <td scope="col">2
            </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">23
               </td>
               <td scope="col">25
            </td>
            <td scope="col">25
            </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">5.8
               </td>
               <td scope="col">5.6
            </td>
            <td scope="col">5.6
            </td>
              </tr>

              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>5000
               </td>
               <td scope="col">6000
                <br>7000
           </td>
           <td scope="col">8000
            <br>7000
       </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">2.0
               </td>
               <td scope="col">2.0
            </td>
            <td scope="col">1.5
            </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.5
                    <br>0.75
                    <br>1.0
               </td>
               <td scope="col">1.2
                <br>0.6
                <br>1.0
           </td>
           <td scope="col">1.0
            <br>0.6
            <br>1.0
       </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>•
                    <br>-
               </td>
               <td scope="col">•
                <br>•
                <br>-
           </td>
           <td scope="col">•
            <br>•
            <br>-
       </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
               </td>
               <td scope="col">•
                <br>◦
                <br>◦
           </td>
           <td scope="col">•
            <br>◦
            <br>◦
       </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>◦
                        <br>◦
                        <br>•
                        <br>-
                        <br>-
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
               <td scope="col">-
                <br>-
                <br>◦
                <br>◦
                <br>•
                <br>-
                <br>-
                <br>◦
                <br>-
                <br>-
                <br>-
                <br>-
       </td>
       <td scope="col">-
        <br>-
        <br>◦
        <br>◦
        <br>•
        <br>-
        <br>-
        <br>◦
        <br>-
        <br>-
        <br>-
        <br>-
</td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
               <td scope="col">B2
            </td>
            <td scope="col">B2
            </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
               <td scope="col">Spring
            </td>
            <td scope="col">Spring
            </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">250,000
               </td>
               <td scope="col">250,000
            </td>
            <td scope="col">250,000
            </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
           <td scope="col">•
            <br>-
       </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
                    <br>•
               </td>
               <td scope="col">•
                <br>-
                <br>◦
                <br>•
           </td>
           <td scope="col">-
            <br>-
            <br>•
            <br>•
       </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
           <td scope="col">◦
            <br>•
       </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">◦
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
                    <br>◦
                    <br>◦
               </td>
               <td scope="col">•
                <br>◦
                <br>◦
                <br>◦
                <br>◦
           </td>
           <td scope="col">•
            <br>◦
            <br>◦
            <br>◦
            <br>◦
       </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/◦
               </td>
               <td scope="col"> -/◦
            </td>
            <td scope="col"> -/◦
            </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
               <td scope="col">•
            </td>
            <td scope="col">•
            </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>
        <div class="tab-pane fade show active" id="sst-secure" role="tabpanel" aria-labelledby="sst-secure-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SST® Secure</h2>
                    <p>Burglary, robbery and vandalism – unfortunately in the modern era these crimes are no longer rare
                        occurrences. Burglaries and robberies in particular are usually well organised and pre-planned,
                        conducted by networks of criminals who have the necessary means to forcibly gain entry to
                        property and steal valuables.

                        People wishing to safeguard against these risks often resort to electronic security detection
                        and alarm systems – however, these can complicate logistical processes and result in the slowing
                        down of internal procedures and significant restrictions to ongoing operations. </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                            <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                                style="visibility: visible;">
                                <img src="assets/img/products/hsd/SST/sec1.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SST/sec2.jpg" alt="Image" width="100%" height="auto">
                                <img src="assets/img/products/hsd/SST/sec3.jpg" alt="Image" width="100%" height="auto">

                            </div>

                            <div class="article-content">
                                <h3>About EFA-SST® Secure</h3>
                                <p><strong>Security without Limits</strong></p>
                                <p>The EFA-SST® Secure combines speed with security in a single door solution. It
                                    provides maximum protection to the people around you, whilst incorporating the speed
                                    of an efficient EFAFLEX high-speed door. Double-sided automatic locks secure the
                                    door during every closing operation, transforming it into a near-insurmountable
                                    obstacle. The door fits seamlessly into the structure of the building and features
                                    impressive opening and closing speeds to ensure your processes remain quick and
                                    efficient, while your valuables stay protected.</p>

                                <p><strong> EFA-SST® Secure at a Glance </strong></p>
                                <ul class="feat">
                                    <li>Resistance class WK 4 in accordance with DIN V ENV 1627-1630:1999-04</li>
                                    <li>Patented technology</li>
                                    <li>Tested and certified by ift Rosenheim</li>
                                    <li>Automatic locking during every closing procedure</li>
                                    <li>Emergency opening by tension springs</li>
                                    <li>Opening speed up to 1.0 m/s</li>
                                    <li>Closing speed up to 0.6 m/s</li>
                                    <li> Up to 250,000 operating cycles per year</li>
                                    <li> Max. sizes, W = 4,000 mm, H = 5,000 mm</li>

                                </ul>
                                <p> <strong>Tested and certified</strong></p>
                                <p>
                                    Thanks to certification by ift Rosenheim in accordance with DIN V ENV
                                    1627-1630:1999-04, the door has attained resistance class WK 4 to offer maximum
                                    security against burglaries, robbery and vandalism.
                                </p>
                            </div>


                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                </div>
                            </section>
                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST® Secure (RC3 + RC4)</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">4
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">25
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">5.8
               </td>
              </tr>

              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>5000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">1.0
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.0
                    <br>-
                    <br>0.6
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>-
                        <br>-
                        <br>•
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">250,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">-
                    <br>-
                    <br>•
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
                    <br>◦
                    <br>◦
               </td>
            </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col">-/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade" id="sst-efficient" role="tabpanel" aria-labelledby="sst-efficient-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SST® Efficient</h2>
                    <p>The EFA-SST® Efficient is a space-saving and springless high-speed spiral door, which was
                        designed specifically for installations of restricted or limited dimensions. The highly
                        insulating door blade, and the chain drive make the compact frame dimensions possible.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SST/eff1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/eff2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/eff3.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SST®-Efficient</h3>
                                <p>The door is virtually maintenance-free due to the lack of any counterbalance. Owing
                                    to the selected drive system and the specialist equipment, the door has attained
                                    resistance class WK 2, meaning it automatically becomes intruder-resistant each time
                                    it is closed.

                                    There are a variety of applications for the EFA-SST® Efficient range, including
                                    internal use and as a secure hall closing door. Fitting it with an on-site canopy
                                    means that the high-speed door can also be installed externally.</p>
                                <p><strong>Durable with minimal wear </strong></p>
                                <p> The door blade consists of laths which are connected by a hinge chain. Travel
                                    rollers are attached to the hinge chain and glide through the vertical and
                                    spiral-shaped guides during the doors opening and closing motion. This results in
                                    minimal noise being generated, negligible wear to the door blade, improved
                                    durability and overall an extended service life for the door system.</p>

                                <p><strong>
                                        EFA-SST® Efficient at a glance
                                    </strong></p>

                                <ul class="feat">
                                    <li>Slim frame for confined installation locations.</li>
                                    <li>Chain drive ensures minimal maintenance costs.</li>
                                    <li>Resistance class WK 2 in accordance with DIN V ENV 1627-1630:1999-04.</li>
                                    <li>Opening and closing speed up to 0.5 m/s</li>
                                    <li>Up to 150,000 operating cycles per year.</li>
                                    <li>Max. sizes, W = 4,000 mm, H = 5,130 mm.</li>
                                    <li>Tested and certified by ift Rosenheim.</li>

                                </ul>


                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                </div>
                            </section>
                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST® Efficient</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">2 - 4
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">2
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">20
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">1.7
               </td>
              </tr>

              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>5130
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">0.5
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">0.5
                    <br>0.5
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>•
                        <br>-
                        <br>◦
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">150,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> ◦/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade" id="sst-ms" role="tabpanel" aria-labelledby="sst-ms-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SST® MS</h2>
                    <p>The ingenious construction principle of the EFAFLEX spiral, combined with slim double walled
                        extruded aluminium laths, permits such a compact design. This harmonised design guarantees very
                        smooth, safe use supported by high speed operation. The contactless operation of the door leaf
                        prevents surfaces from being scratched, while the optional transparent vision laths remain
                        transparent even after extensive use. </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                        style="visibility: visible;">
                        <img src="assets/img/products/hsd/SST/ms1.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/SST/ms2.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/SST/ms3.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/SST/ms4.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/SST/ms5.jpg" alt="Image" width="100%" height="auto">
                    </div>

                            <div class="article-content">
                                <h3>About EFA-SST® MS</h3>
                                <p>The control unit which can be used worldwide operates at performance level PL »d« for
                                    safety-relevant control circuits and can be extended using the machine protection
                                    doors MS-AM connection module. Machine protection door EFA-SST® MS from EFAFLEX.
                                    <br>
                                    The machine protection door EFA-SST® MS operates by means of virtually
                                    maintenancefree tension spring assembly with electromechanical function monitoring.
                                </p>


                                <strong>Special Advantages</strong>
                                <p>
                                    Used as a standalone separating safety guard, the EFA-SST® MS high-speed spiral door
                                    fulﬁls the requirements for a safe, modern machine protection door. Together with
                                    optional ﬂoor supports, the selfsupporting simple design allows standalone
                                    installation. To ensure protection in low height situations, additional covers are
                                    available for the spiral unit.
                                </p>

                                <strong>Floor Support</strong>
                                <p>
                                    Optional supports in order to install the door system as a standalone system.
                                    Levelling of uneven ground possible by means of screws
                                </p>

                                <strong>Safety</strong>
                                <p>
                                    Safety contact strip in the main closing edge, in combination with an additional
                                    light barrier.
                                </p>

                                <p><strong>EFA-SST® MS at a glance</strong></p>
                                <ul class="feat">
                                    <li>Functional safety performance level »d«</li>
                                    <li>Maximum speed up to 2.7 m / s</li>
                                    <li>Compact extruded aluminium laths</li>
                                    <li>Up to 250,000 cycles per year</li>
                                    <li>Maximum of 7 cycles per minute</li>
                                    <li>Life cycle 12 years</li>
                                    <li>Standard sizes of up to w=3,000mm x h=3,000mm</li>


                                </ul>



                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>


                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST® MS</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">4
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">23
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">-
               </td>
              </tr>

              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">3000
                    <br>3000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">2.7
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">2.2
                    <br>0.6
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>◦
                        <br>◦
                        <br>•
                        <br>-
                        <br>-
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">250,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">◦
                    <br>-
                    <br>•
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">◦
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">◦HSO
                    <br>•
                    <br>•
                    <br>◦
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/-
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade" id="sst-essential" role="tabpanel" aria-labelledby="sst-essential-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SST® Essential</h2>
                    <p>EFA-SST® Essential high-speed spiral door, the switchover from conventional door systems to the
                        premium products of EFAFLEX is even easier. Efficient and economical, this robust spiral door
                        fulfils the consistently high quality standards of EFAFLEX and allows a
                        particularly attractive purchase price due to its basic equipment. </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SST/ess1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/ess2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/ess3.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SST® Essential</h3>
                                <p>The ingenious building principle of EFAFLEX spirals permits a compact structure of
                                    the high speed door
                                    system. This homogeneous structure guarantees very good mechanical properties
                                    combined with a sufficiently
                                    high speed.</p>

                                <p><strong>Sound/heat insulation</strong></p>
                                <p>
                                    Today, energy as one of the most important factors with regard to profitability is
                                    more important than ever before. We at EFAFLEX react to increasing prices and an
                                    uncertain raw materials supply: due to the fast opening and closing speed of our
                                    doors, only few energy gets lost towards outside or between two rooms. By using
                                    EFAFLEX high-speed doors, you can efficiently reduce your energy costs.
                                    <br>
                                    As the first manufacturer of high-speed doors we are from now offering as a standard
                                    the EFA-SST® with thermally separated EFA-THERM® laths. These laths considerably
                                    increase heat insulation and thus reduce your energy costs.
                                </p>
                                <p><strong>Individual door leaf design</strong></p>
                                <p>In addition to the aluminium-coloured standard coating, we also paint the laths as
                                    well as the steel parts of the door in almost every colour of the RAL system, if
                                    requested by our customers. Thus, our high speed doors can architecturally be
                                    perfectly adjusted to every facade or get the colour of your house which gives your
                                    premises a representative look.</p>

                                <p><strong>Safety devices EFA-SST</strong></p>
                                <p>EFAFLEX high-speed doors also guarantee you the highest standard with regard to
                                    safety. As leading manufacturer of high-speed doors, EFAFLEX is aware of its
                                    responsibility! Therefore, we also want to be pioneers in the field of safety. Our
                                    systems bring you much more safety - for persons as well as for the material loaded
                                    and for the door itself.
                                    <br>
                                    Not only the doors itself satisfy the highest demands at EFAFLEX. The appertaining
                                    safety systems also offer unique advantages. EFA-SCAN®, the latest EFAFLEX patent,
                                    is the first laser scanner worldwide which can be used in a door on the one hand,
                                    and which is an activator as well as safety system in one and the same product on
                                    the other hand.
                                </p>

                                <p><strong>EFA-SST® Essential at a glance</strong></p>
                                <ul class="feat">
                                    <li>Very good acoustic and thermal insulation</li>
                                    <li>Wind resistance class 2 - 4</li>
                                    <li>Up to 100,000 load cycles per year</li>
                                    <li>Suitable for external and internal applications</li>
                                    <li>Maximum speed of up to 0.5 m / s</li>
                                    <li>Standard sizes of up to w=4,500mm x h=5,000mm</li>


                                </ul>



                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST® Essential</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">2 - 4
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">2
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">20
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">1.67
               </td>
              </tr>

              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4500
                    <br>5000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">0.5
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">0.5
                    <br>0.5
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">•
                        <br>◦
                        <br>◦
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">100,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">◦
                    <br>•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">◦
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>◦
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade" id="sst-ex" role="tabpanel" aria-labelledby="sst-ex-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SST® EX</h2>
                    <p>The EFA-SST® EX(Explosion-Proff) is excellently suited for both internal and external use. In
                        addition to high
                        operating speeds, it also offers a solid door blade with high wind resistance and optimal
                        sealing.
                        The standard door blade consists of double-walled aluminium laths, which can be supplemented all
                        in
                        or part with optional transparent laths. The door blade is guided by a spiral, without contact
                        minimising wear.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SST/ex1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/ex2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/ex3.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SST® EX</h3>
                                <p>The EFA-SST® EX has been developed in accordance with ATEX Directive 2014/34/EU
                                    specifically for use
                                    in potentially explosive atmospheres for Zone 1 (II 2G IIB T4 GbX), Zone 2 (II 3G
                                    IIB T4 GcX),
                                    Zone 21 (II 2D IIIB 135°C DbX) and Zone 22 (II 3D IIIB 135°C DcX). The control
                                    cabinet must be
                                    installed outside the explosion protection zone.</p>

                                <ul class="feat">
                                    <li>For use in explosion protection zones 1, 2, 21 and 22</li>
                                    <li>Highest wind load capacity</li>
                                    <li>Up to 200,000 operating cycles p.a.</li>
                                    <li>Suitable for external and internal applications</li>
                                    <li>Opening up to 1.0 m/s</li>
                                    <li>Closing up to 0.5 m/s</li>
                                    <li>Standard sizes up to w=4,000mm x h=5,000mm</li>


                                </ul>



                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
               <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST® EX</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">2 - 4
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">2
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">23
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">5.8
               </td>
              </tr>

              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>5000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">1.0
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.0
                    <br>0.5
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>•
                        <br>◦
                        <br>•
                        <br>-
                        <br>-
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">200,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">-
                    <br>-
                    <br>•
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">◦
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">◦
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>
<!--
        <div class="tab-pane fade" id="sst-tk" role="tabpanel" aria-labelledby="sst-tk-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SST® TK100</h2>
                    <p>The first truly single-door solution for deep-freeze areas that simultaneously offers highest
                        possible opening
                        and closing speeds and the best insulation values for spiral doors. The EFAFLEX EFA-SST® TK-100
                        high-speed door
                        is a high-quality solution for every deep-freeze room. </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SST/tk1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/tk2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/tk3.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/tk4.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/tk5.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SST® TK100</h3>
                                <p>Contact surface heaters are fitted throughout the entire EFAFLEX high-speed door,
                                    integrated into the seals around the opening and into the bottom beam. These heat
                                    the contact surfaces of the seals where they meet the door blade and floor. In
                                    comparison with other door solutions you can save up to 50% on heating costs by
                                    using an EFAFLEX deep-freeze high-speed door. The result of this unique design is
                                    heat transfer coefficients of as low as 0.62W/m2K in operational temperatures
                                    between
                                    plus 30°C and minus 30°C.</p>
                                <p><strong>Deep-freeze single door solution</strong></p>
                                <p>
                                    The innovative construction of the EFA-SST® TK-100 features the door seal running
                                    all the way around the opening
                                    and a dynamic door blade guide. This guide is mounted displaceably against the
                                    frames and spiral mount, an active
                                    guide rail mechanism (EFA-AFM®) keeps the door blade pushed tight against the door
                                    frame and, combined with the seal,
                                    seals the door practically hermetically. The 100mm thick EFA-THERM® insulation laths
                                    on the EFA-SST® TK-100 guarantee
                                    better thermal insulation than ever before. The laths are thermally separated as
                                    standard for cold storage rooms.
                                </p>

                                <ul class="feat">
                                    <li>Optimal deep-freeze single door solution</li>
                                    <li>EFAFLEX High speed door technology</li>
                                    <li>Frames and laths thermally separated</li>
                                    <li>Almost hermetically sealed.</li>
                                    <li>Opening up to 2,0 m/s</li>
                                    <li>Closing up to 0.5 m/s</li>
                                    <li>Highest U value 0,62 W / m2K</li>
                                    <li>Up to 200,000 operating cycles p.a.</li>
                                    <li>Standard sizes of up to w=4,000mm x h=6,000mm, max. 18 m²</li>

                                </ul>



                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> 

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST® Efficient</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">2 - 4
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">2
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">0
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">20
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">1.7
               </td>
              </tr>

              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>5130
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">0.5
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">0.5
                    <br>0.5
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>•
                        <br>-
                        <br>◦
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">150,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> ◦/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>
-->
        <div class="tab-pane fade" id="sst-tk" role="tabpanel" aria-labelledby="sst-tk-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SST® TK</h2>
                    <p>Doors in cooled rooms must be able to withstand very high loads. Top quality and perfect
                        workmanship are a prerequisite for all components to withstand cold and humidity. </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/HSD1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/HSD2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/HSD1.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SST®</h3>
                                <p>EFA-SST is
                                    the ideal closing door when a sophisticated insulation is required for highly
                                    frequented
                                    openings. The EFA-SST combines two EFAFLEX door technologies to one innovative
                                    solution: the
                                    multi-patented spiral as well as highly insulating laths.</p>

                                <ul class="feat">
                                    <li>Max.heat insulation with EFA-THERM® laths</li>
                                    <li>Opening up to 2.5 m/s.</li>
                                    <li>Closing up to 1.0 m/s.</li>
                                    <li>Highest wind load capacity.</li>
                                    <li>Top safety devices.</li>
                                    <li>Up to 250,000 operating cycles p.a.</li>
                                    <li>Standard sizes of up to w=10,000mm x h=12,000mm</li>
                                    <li> This functional principle guarantees quite a number of unique advantages.</li>

                                </ul>
                                <p>
                                    EFAFLEX offers you high-speed spiral doors in different versions. The circular round
                                    spiral is the standard and at the same time the solution of highest opening and
                                    closing
                                    speed. If there is only few space above the door, you can also chose between two
                                    space-saving variants of the many door types of the S series: oval spiral and low
                                    header.
                                </p>


                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>



                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST® TK-100</th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">
                   </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">Lock up deep freeze
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">4
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">4
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">26
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">0.62
               </td>
              </tr>

              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>6000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">2.5
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">2.0
                    <br>0.75
                    <br>0.5
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">•
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">200,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">-
                    <br>-
                    <br>•
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">-
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">◦
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">-
                    <br>•
                    <br>•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col">-/-
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">◦
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade" id="sst-ps" role="tabpanel" aria-labelledby="sst-ps-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SST® PS</h2>
                    <p>Uniquely fast, secure and reliable – one more characteristic is now going to be added to the
                        known features of
                        EFAFLEX high-speed doors. Once again, our engineers have scored a real coup: They developed the
                        EFA-SST® PS(Parking Solution),
                        a space-saving door particularly suited for parking and garage systems. The new construction can
                        be installed
                        even under minimal space conditions in the lintel or side frame.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                        style="visibility: visible;">
                        <img src="assets/img/products/hsd/SST/p1.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/SST/p2.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/SST/p3.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/SST/p4.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/SST/p4.jpg" alt="Image" width="100%" height="auto">
                    </div>

                            <div class="article-content">
                                <h3>About EFA-SST® PS</h3>
                                <p>The EFAFLEX spiral is still an ingenious construction principle. However, in
                                    comparison to common
                                    spiral doors, the space requirement was significantly reduced by using a compact
                                    spiral construction
                                    which has been patented several times. The smaller laths (height of 105 mm) also
                                    mean that the door
                                    can be installed in tight spaces in the lintel.</p>

                                <p><strong>New perspectives</strong></p>
                                <p>The external building door opens in mere seconds and closes immediately after the car
                                    has passed the door.
                                    The EFA-SST®-PS thus prevents unauthorised access and unauthorised cars may not gain
                                    entry to the building.
                                    Fast and secure doors contribute to safe entries and exits of (underground) car
                                    parks of hotels, banks,
                                    agencies and other public institutions in particular.</p>

                                <p><strong>For new dimensions of durability</strong></p>
                                <p>One’s own ambitions are the strongest drive for outstanding performance: Common
                                    garage doors are calculated to
                                    go through approximately 5 opening cycles a day, but the EFA-SST® PS is dimensioned
                                    for 200,000 work cycles a
                                    year. That’s why no other door can be compared to the EFAFLEX doors concerning
                                    durability and capacity.</p>



                                <p><strong>EFA-SST® PS at a glance</strong></p>
                                <ul class="feat">
                                    <li>space-saving construction</li>
                                    <li>Opening up to 1.8 m/s</li>
                                    <li>Closing up to 1.0 m/s</li>
                                    <li>Highest wind load capacity</li>
                                    <li>Top safety devices</li>
                                    <li>Up to 200,000 operating cycles p.a.</li>
                                    <li>Standard sizes of up to w=6,100mm x h=4,000mm</li>


                                </ul>

                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>



                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST® PS</th>
                 <th> </th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                   <td scope="col">S
                </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>•
                </td>
                <td scope="col">•
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">4
               </td>
               <td scope="col">2
            </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
               <td scope="col">fulfilled
            </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">2
               </td>
               <td scope="col">2
            </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">1
               </td>
               <td scope="col">1
            </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">23
               </td>
               <td scope="col">23
            </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">6.5
               </td>
               <td scope="col">6.5
            </td>
              </tr>

              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>4000
               </td>
               <td scope="col">6100
                <br>4000
           </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">2.0
               </td>
               <td scope="col">1.5
            </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">1.8
                    <br>0.5
                    <br>1.0
               </td>
               <td scope="col">1.2
                <br>0.5
                <br>1.0
           </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
               </td>
               <td scope="col">•
                <br>-
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
               </td>
               <td scope="col">•
                <br>◦
                <br>◦
           </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>◦
                        <br>◦
                        <br>•
                        <br>-
                        <br>-
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
               <td scope="col">-
                <br>-
                <br>◦
                <br>◦
                <br>•
                <br>-
                <br>-
                <br>◦
                <br>-
                <br>-
                <br>-
                <br>-
       </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
               <td scope="col">B2
            </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
               <td scope="col">Spring
            </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">200,000
               </td>
               <td scope="col">200,000
            </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
                    <br>•
               </td>
               <td scope="col">•
                <br>-
                <br>◦
                <br>•
           </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">◦
               </td>
               <td scope="col">◦
            </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
                    <br>◦
                    <br>◦
               </td>
               <td scope="col">•
                <br>-
                <br>-
                <br>◦
                <br>◦
           </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/◦
                </td>
                <td scope="col"> ◦/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
               <td scope="col">•
            </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        <div class="tab-pane fade" id="sst-iso" role="tabpanel" aria-labelledby="sst-iso-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SST® ISO-60</h2>
                    <p>The EFA-SST® ISO-60 combines two EFAFLEX door technologies to one innovative solution: the
                        multi-patented
                        spiral as well as highly insulating laths. In addition, cost-intensive sealing prevents the
                        exchange of air
                        and temperature. These components make up an excellently insulated door.

                    </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/SST/iso1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/iso2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/iso3.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/SST/iso4.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-SST® ISO60</h3>
                                <p>Doors in cooled rooms must be able to withstand very high loads. Top quality and
                                    perfect workmanship are
                                    a prerequisite for all components to withstand cold and humidity. EFA-SST® ISO-60 is
                                    the ideal closing
                                    door when a sophisticated insulation is required for highly frequented openings,
                                    e.g. in cold-storage
                                    rooms.
                                </p>

                                <p><strong>Perfect Heat Insulation</strong></p>
                                <p>
                                    A better heat insulation then ever before is from now on guaranteed by the 60 mm
                                    thick EFA-THERM®
                                    insulation laths! The laths are thermally separated as a standard. Especially
                                    developed details
                                    provide excellent vertical and horizontal sealing of the EFA-SST® ISO-60. The
                                    vertical insulation
                                    towards the frame is carried out by thermally separated aluminium profiles with
                                    nearly wear-free
                                    sealings. The horizontal space between wall and door leaf is closed by an active
                                    lintel sealing
                                    made of aluminium. For being used even in conditions of high temperature differences
                                    and/or high
                                    humidity, both sealings can optionally be equipped with heating tapes.
                                </p>

                                <p><strong>Constant Temperatures</strong></p>
                                <p>
                                    The EFA-SST® ISO60 is used in situations where constantly low temperatures have to
                                    be maintained,
                                    e.g. in the field of airline catering. In order to ensure the constantly high
                                    quality of served
                                    meals, constant and exact cooling is necessary. Especially doors are a very
                                    sensitive point between
                                    two temperature zones.
                                </p>

                                <p><strong>Perfect Sealing</strong></p>
                                <p>
                                    If closed, the double-walled and thermally separated insulation lath of the EFA-SST®
                                    ISO-60 with a
                                    double rubber sealing provides the corresponding insulation. Due to the high
                                    fluctuation of goods,
                                    doors used in the field of airline catering must perform many operating cycles.
                                    Here, the high speeds
                                    of the EFAFLEX high-speed doors prevent an excessive temperature exchange.
                                </p>
                                <p><strong>EFA-SST® ISO-60 at a glance</strong></p>
                                <ul class="feat">
                                    <li>Max.heat insulation with EFA-THERM® laths</li>
                                    <li>Opening up to 2.5 m/s.</li>
                                    <li>Closing up to 1.0 m/s.</li>
                                    <li>60 mm thick door leaf</li>
                                    <li>k-value up to 0.8 W/m2 K</li>
                                    <li>Up to 250,000 operating cycles p.a.</li>
                                    <li>Standard sizes of up to w=6,000mm x h=6,000mm</li>


                                </ul>



                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>



                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-SST® ISO-60</th>
                 <th> </th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                   </td>
                   <td scope="col">
                </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>•
                </td>
                <td scope="col">•
                    <br>•
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">3 - 4
               </td>
               <td scope="col">3 - 4
            </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
               <td scope="col">fulfilled
            </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">3
               </td>
               <td scope="col">3
            </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">3
               </td>
               <td scope="col">3
            </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">25
               </td>
               <td scope="col">25
            </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">0.93
               </td>
               <td scope="col">0.80
            </td>
              </tr>
              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4500
                    <br>4550
               </td>
                <td scope="col">6000
                    <br>6000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">2.5
               </td>
               <td scope="col">1.5
            </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">2.0
                    <br>0.75
                    <br>1.0
               </td>
               <td scope="col">1.2
                <br>0.6
                <br>1.0
           </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
               </td>

               <td scope="col">•
                <br>-
                <br>-
           </td>            
        </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
               </td>
               <td scope="col">•
                <br>◦
                <br>◦
           </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">•
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>-
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
               <td scope="col">•
                <br>◦
                <br>-
                <br>-
                <br>-
                <br>-
                <br>-
                <br>◦
                <br>-
                <br>-
                <br>-
                <br>-
       </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
               <td scope="col">B2
            </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
               <td scope="col">Spring
            </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">250,000
               </td>
               <td scope="col">250,000
            </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
                    <br>•
               </td>
               <td scope="col">•
                <br>-
                <br>◦
                <br>•
           </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
               <td scope="col">•
                <br>-
           </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">•
               </td>
               <td scope="col">•
            </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
                    <br>◦
                    <br>◦
               </td>
               <td scope="col">•
                <br>-
                <br>-
                <br>◦
                <br>◦
           </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> ◦/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>
                </div>

            </div>
        </div>

        

    </div>

</section>
