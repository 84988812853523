<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Shutters</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/home">Home</a></li>
                        <li><a routerLink="/products">Products</a></li>
                        <li>Shutters</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<section class="contact-info-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2 class="h2-cus">SHUTTERS</h2>
            <!-- </div>
        <div class="txt-center"> -->
            <ul class="nav nav-pills mb-3" id="pills-tab" role="tablist">

                <li class="nav-item  ">
                    <a class="nav-link" id="aluminium-tab" (click)="turnWhite('aluminium')" [ngClass]="{'active' : page == 'aluminium'}" data-toggle="pill"
                        href="#aluminium" role="tab" aria-controls="aluminium" aria-selected="false"> <i class="">
                            <img [hidden]="!aluminiumblack" class="cus-icon" src="assets/img/icons/Shutters/Aluminium.png" alt="Image" type="image/png">
                            <img [hidden]="!aluminiumwhite" class="cus-icon" src="assets/img/icons/Shutters/Aluminium-white.PNG" alt="Image" type="image/png">

                        </i>

                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="ss-tab" (click)="turnWhite('ss')" [ngClass]="{'active' : page == 'ss'}" data-toggle="pill"
                        href="#ss" role="tab" aria-controls="ss" aria-selected="false"> <i class="">
                            <img [hidden]="!ssblack" class="cus-icon" src="assets/img/icons/Shutters/ss.png" alt="Image" type="image/png">
                            <img [hidden]="!sswhite" class="cus-icon" src="assets/img/icons/Shutters/ss-white.PNG" alt="Image" type="image/png">
                            </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link arrow_box " (click)="turnWhite('galvalume')" [ngClass]="{'active' : page == 'galvalume'}" id="galvalume-tab"
                        data-toggle="pill" href="#galvalume" role="tab" aria-controls="galvalume" aria-selected="true">
                        <i class="">
                            <img [hidden]="!galvalumeblack" class="cus-icon" src="assets/img/icons/Shutters/galavalume.png" alt="Image" type="image/png">
                            <img [hidden]="!galvalumewhite" class="cus-icon" src="assets/img/icons/Shutters/galavalume-white.PNG" alt="Image" type="image/png">
                            </i>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="polycarbonate-tab" (click)="turnWhite('polycarbonate')"[ngClass]="{'active' : page == 'polycarbonate'}" data-toggle="pill"
                        href="#polycarbonate" role="tab" aria-controls="polycarbonate" aria-selected="false"> <i class="">
                            <img [hidden]="!polycarbonateblack" class="cus-icon" src="assets/img/icons/Shutters/polycarbonate.png" alt="Image" type="image/png">
                            <img [hidden]="!polycarbonatewhite" class="cus-icon" src="assets/img/icons/Shutters/polycarbonate-white.PNG" alt="Image" type="image/png">

                            </i></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link" id="perforated-tab" (click)="turnWhite('perforated')" [ngClass]="{'active' : page == 'perforated'}"
                        data-toggle="pill" href="#perforated" role="tab" aria-controls="perforated"
                        aria-selected="false"> <i class="">
                            <img [hidden]="!perforatedblack" class="cus-icon" src="assets/img/icons/Shutters/perforated.png" alt="Image" type="image/png">
                            <img [hidden]="!perforatedwhite" class="cus-icon" src="assets/img/icons/Shutters/perforated-white.PNG" alt="Image" type="image/png">

                            </i></a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="galvanised-tab" (click)="turnWhite('galvanised')" [ngClass]="{'active' : page == 'galvanised'}"
                        data-toggle="pill" href="#galvanised" role="tab" aria-controls="galvanised"
                        aria-selected="false"> <i class="">
                            <img [hidden]="!galvanisedblack" class="cus-icon" src="assets/img/icons/Shutters/GI.png" alt="Image" type="image/png">
                            <img [hidden]="!galvanisedwhite" class="cus-icon" src="assets/img/icons/Shutters/GI-white.PNG" alt="Image" type="image/png">
                            </i></a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="fire-rated-tab" (click)="turnWhite('fire-rated')" [ngClass]="{'active' : page == 'fire-rated'}"
                        data-toggle="pill" href="#fire-rated" role="tab" aria-controls="fire-rated"
                        aria-selected="false"> <i class="">
                            <img [hidden]="!fireblack" class="cus-icon" src="assets/img/icons/Shutters/firerated.png" alt="Image" type="image/png">
                            <img [hidden]="!firewhite" class="cus-icon" src="assets/img/icons/Shutters/fire-rated-white.PNG" alt="Image" type="image/png">
                            </i></a>
                </li>

                <li class="nav-item">
                    <a class="nav-link" id="pvc-tab" (click)="turnWhite('pvc')" [ngClass]="{'active' : page == 'pvc'}"
                        data-toggle="pill" href="#pvc" role="tab" aria-controls="pvc"
                        aria-selected="false"> <i class="">
                            <img [hidden]="!pvcblack" class="cus-icon" src="assets/img/icons/Shutters/MS.png" alt="Image" type="image/png">
                            <img [hidden]="!pvcwhite" class="cus-icon" src="assets/img/icons/Shutters/MS-white.PNG" alt="Image" type="image/png">
                            </i></a>
                </li>
            </ul>


        </div>

        <div class="tab-content" id="pills-tabContent">
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'aluminium'}" id="aluminium" role="tabpanel"
                aria-labelledby="aluminium-tab">
                <app-aluminium-shutters></app-aluminium-shutters>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'ss'}" id="ss" role="tabpanel"
                aria-labelledby="ss-tab">
                <app-ss-shutters></app-ss-shutters>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'galvalume'}" id="galvalume" role="tabpanel"
                aria-labelledby="galvalume-tab">
                <app-galvalume-shutters></app-galvalume-shutters>
            </div>

            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'polycarbonate'}" id="polycarbonate"
                role="tabpanel" aria-labelledby="polycarbonate-tab">
                <app-polycarbonate-shutters></app-polycarbonate-shutters>
            </div>
            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'perforated'}" id="perforated"
                role="tabpanel" aria-labelledby="perforated-tab">
                <app-perforated-shutters></app-perforated-shutters>
            </div>

            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'galvanised'}" id="galvanised"
                role="tabpanel" aria-labelledby="galvanised-tab">
                <app-galvanised-shutters></app-galvanised-shutters>
            </div>

            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'fire-rated'}" id="fire-rated"
                role="tabpanel" aria-labelledby="fire-rated-tab">
                <app-fire-rated-shutters></app-fire-rated-shutters>
            </div>

            <div class="tab-pane fade" [ngClass]="{'show active' : page == 'pvc'}" id="pvc" role="tabpanel"
                aria-labelledby="pvc-tab">
                <app-pvc-shutters></app-pvc-shutters>
            </div>
        </div>
    </div>
</section>
