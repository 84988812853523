<section class="blog-details-area">
    <br>
    <div class="row">

        <div class="col-sm-12 text-center">
            <ul class="nav nav-pills mb-3 text-center bordernav" id="pills-tab" role="tablist">

                <li class="nav-item">
                    <a class="nav-link active anav" id="periphery-1-tab" data-toggle="pill" href="#periphery-1" role="tab"
                        aria-controls="periphery-1" aria-selected="true">EFA-SCAN® laser scanner</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link anav" id="periphery-2-tab" data-toggle="pill" href="#periphery-2" role="tab"
                        aria-controls="periphery-2" aria-selected="false">EFA-TLG® door light grid</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link anav" id="periphery-3-tab" data-toggle="pill" href="#periphery-3" role="tab"
                        aria-controls="periphery-3" aria-selected="false">Approach area monitoring</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link anav" id="periphery-4-tab" data-toggle="pill" href="#periphery-4" role="tab"
                        aria-controls="periphery-4" aria-selected="false">EFA-TRONIC® Control Box</a>
                </li>


            </ul>
        </div>

    </div>
    <br>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="periphery-1" role="tabpanel" aria-labelledby="periphery-1-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-SCAN® - our patent</h2>
                    <p>Once again, EFAFLEX presents a completely new and patented technology. For you, our engineers have developed EFA-SCAN®, the first laser scanner worldwide for being used in a door! </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/periphery/efa_scan.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/periphery/efa_scan1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/periphery/efa_scan2.jpg" alt="Image" width="100%" height="auto">
                        </div>

                        <div class="article-content">
                            <h3>EFA-SCAN® - our patent</h3>
                            <p>EFA-SCAN® carries out the tasks of activators and safety systems better than any other technology on the market. The innovation by EFAFLEX covers the entire area in front of the door – for the first time without missing anything and, by using intelligent direction recognition, it is more reliable than any other technology.
                            </p>
                            <p> Complex software algorithms prevent triggering during rain, snow and extraneous light. Therefore, EFA-SCAN® is one of the few laser scanners which are also suitable for being installed outdoors. The innovative laser technology guarantees a uniquely secure, split-second opening of your door.
                            </p>

                            <strong>EFA-SCAN® at a glance</strong>
                            <ul class="feat">
                                <li>Patented world premiere.</li>
                                <li>The first laser scanner for door systems.</li>
                                <li>Gapless detection of moving and motionless objects.</li>
                                <li>Almost airtight, Also for material locks.</li>
                                <li>Intelligent direction recognition.</li>
                                <li>Immune to environmental influences</li>
                                <li>Suitable for outside installation.</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>



                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade show" id="periphery-2" role="tabpanel" aria-labelledby="periphery-2-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-TLG® - from the inventor</h2>
                    <p>In order to avoid crashes in the lower area of the door, where nearly 90% of all crashes happen, right from the beginning, you are on the safe side by using our TÜV-certified infrared light grid EFA-TLG®. </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/periphery/efa_tlg1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/periphery/efa_tlg2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/periphery/efa_tlg3.jpg" alt="Image" width="100%" height="auto">
                        </div>

                        <div class="article-content">
                            <h3>EFA-TLG® - from the inventor</h3>
                            <p>The system is unique worldwide and it is completely self-monitoring. It is directly installed into the lateral door tracks so that it is well protected. EFA-TLG® monitors the closing plane of the door up to a height of 2.5 metres.
                            </p>
                            <p>Crossing infrared rays generate a nearly flat light grid which can detect even the smallest obstacles without contacting them. If there is something in the way, the closing movement is stopped immediately or not initiated at all, respectively. This unique door technology protects persons, doors, vehicles and material.
                            </p>

                            <strong>EFA-TLG® at a glance</strong>
                            <ul class="feat">
                                <li>Monitoring height 2,500mm</li>
                                 <li>3-fold linear resolution</li>
                                  <li>Crossed ray analysis</li>
                                  <li>Self-sufficient safety system</li>
                                   <li>TÜV-certified</li>
                                    <li>Exceeds all safety standards</li>
                                    <li>Unique worldwide</li>
                                    <li>Patented worldwide</li>
                                </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>



                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade show" id="periphery-3" role="tabpanel" aria-labelledby="periphery-3-tab">
            <div class="container">
                <div class="section-title">
                    <h2>Individual approach area monitoring</h2>
                    <p>EFAFLEX offers you a range of different systems in order to adjust approach area monitoring to your individual requirements.  </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/periphery/monitor1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/periphery/monitor2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/periphery/monitor3.jpg" alt="Image" width="100%" height="auto">
                        </div>

                        <div class="article-content">
                            <h3>EFA-SCAN® - our patent</h3>
                            <p>The range of possibilities covers known systems such as induction loops, radio remote controls, active infrared or radar systems on the one hand, and new technologies  which intelligently combine radar alarms and infrared systems to form one single system on the other hand so that the advantages offered by both technologies are used.</p>
                            <p> Our sales staff will be happy to assist you in order to find the perfectly tailored solution for you. </p>

                            <strong>At a glance</strong>
                            <ul class="feat">
                               <li>Induction loops</li>
                                <li>Radio remote controls</li>
                                <li>Radar motion detectors</li>
                                <li>Active infrared sensor</li>
                                <li>Combination technologies</li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg3" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>



                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
            </div>
        </div>

        <div class="tab-pane fade show" id="periphery-4" role="tabpanel" aria-labelledby="periphery-4-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-TRONIC® made by our engineers</h2>
                    <p>Once again, EFAFLEX presents a completely new and patented technology. For you, our engineers have developed EFA-SCAN®, the first laser scanner worldwide for being used in a door! </p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/periphery/tronic1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/periphery/tronic2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/periphery/tronic3.jpg" alt="Image" width="100%" height="auto">
                        </div>

                        <div class="article-content">
                            <h3>EFA-TRONIC® made by our engineers</h3>
                            <p>Optimised functionality, compact and modern design all describe our all new EFA-TRONIC® controller. Fully compatible, incredibly reliable and significant power reserves are amongst its many key features. The new controller is equipped serially with more than 20 inputs, along with three bus systems and enhanced security functions.
                            </p>
                            <h3>New: Control with the latest micro-electronics</h3>
                            <p> Our new EFA-TRONIC® control boxes come fully equipped with the very latest generation of microprocessors, with its digital heart’ capable of processing numerous network data inputs; simultaneously coordinating the control circuits between motor, brake, drive, frequency converter, safety devices and your chosen activators.</p>

                            <strong>EFA-SCAN® at a glance</strong>
                            <ul class="feat"><li>Three Bus systems</li>
                                <li>USB interface</li>
                                <li>Modular design</li>
                                <li>Protection class I/IP65</li>
                                <li>Compact</li>
                                <li>Vacuum-fluorescent display</li></ul>
                        </div>
                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>



                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
            </div>
        </div>


    </div>

</section>
