import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { MetaService } from '../../../services/meta-service.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-high-speed-doors',
  templateUrl: './high-speed-doors.component.html',
  styleUrls: ['./high-speed-doors.component.scss']
})
export class HighSpeedDoorsComponent implements OnInit {
    page: any;
    sstwhite  = true;
    sstblack = false;

    sttblack = true;
    sttwhite = false;

    srtblack = true;
    srtwhite = false;

    strblack = true;
    strwhite = false;

    mtlblack = true;
    mtlwhite = false;


  constructor(private route: ActivatedRoute, private metaService: MetaService, private location: Location) { }

  ngOnInit(): void {
    this.metaService.setCanonicalURL();
    this.route.queryParams.subscribe(params => {
        this.page = params.page;
    });  }


    turnWhite(imgname) {
        if (imgname === 'sst') {
            this.sstwhite  = true;
            this.sstblack = false;

            this.sttblack = true;
            this.sttwhite = false;

            this.srtblack = true;
            this.srtwhite = false;

            this.strblack = true;
            this.strwhite = false;

            this.mtlblack = true;
            this.mtlwhite = false;
            this.location.replaceState('high-speed-doors?page=sst');
        } else if (imgname === 'stt') {
            this.sstwhite  = false;
            this.sstblack = true;

            this.sttblack = false;
            this.sttwhite = true;

            this.srtblack = true;
            this.srtwhite = false;

            this.strblack = true;
            this.strwhite = false;

            this.mtlblack = true;
            this.mtlwhite = false;
            this.location.replaceState('high-speed-doors?page=stt');
        } else if (imgname === 'srt') {
            this.sstwhite  = false;
            this.sstblack = true;

            this.sttblack = true;
            this.sttwhite = false;

            this.srtblack = false;
            this.srtwhite = true;

            this.strblack = true;
            this.strwhite = false;

            this.mtlblack = true;
            
            this.mtlwhite = false;
            this.location.replaceState('high-speed-doors?page=srt');
        } else if (imgname === 'str') {
            this.sstwhite  = false;
            this.sstblack = true;

            this.sttblack = true;
            this.sttwhite = false;

            this.srtblack = true;
            this.srtwhite = false;

            this.strblack = false;
            this.strwhite = true;

            this.mtlblack = true;
            
            this.mtlwhite = false;
            this.location.replaceState('high-speed-doors?page=str');
        } else if (imgname === 'mtl') {
            this.sstwhite  = false;
            this.sstblack = true;

            this.sttblack = true;
            this.sttwhite = false;

            this.srtblack = true;
            this.srtwhite = false;

            this.strblack = true;
            this.strwhite = false;

            this.mtlblack = false;
           
            this.mtlwhite = true;
            this.location.replaceState('high-speed-doors?page=mtl');
        } else {
            this.sstwhite  = true;
            this.sstblack = false;

            this.sttblack = true;
            this.sttwhite = false;

            this.srtblack = true;
            this.srtwhite = false;

            this.strblack = true;
            this.strwhite = false;

            this.mtlblack = true;
            this.mtlwhite = false;
            this.location.replaceState('high-speed-doors?page=periphery');
        }

    }

}
