<div class="page-title-area">
    <div class="container">
        <div class="page-title-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <h2>Affiliate</h2>
                </div>

                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="">Home </a></li>
                        <li>Pages</li>
                        <li>Affiliate</li>
                    </ul>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="affiliate-content-wrap">
                        <h3>Web Hosting Affiliate Program</h3>
                        <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni nostrum dolores doloremque sed eum quod! Minima nostrum non excepturi, voluptate.</p>
                        <a class="default-btn" routerLink="/sign-up"> Sign Up</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<section class="affiliate-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Why Become a hosta Affiliate?</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-affiliate-info">
                    <i class="bx bx-search"></i>
                    <h3>High Conversion</h3>
                    <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio illo corporis possimus, <a href="domain.html"> domain registration.</a> assumenda minus. Error</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-affiliate-info">
                    <i class="bx bx-wrench"></i>
                    <h3>Quick & Easy Setup</h3>
                    <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio illo corporis possimus, domain registration assumenda minus. Error</p>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-affiliate-info">
                    <i class="bx bx-dollar"></i>
                    <h3>Regular Payouts</h3>
                    <p class="mb-0">Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio illo corporis possimus, domain registration. assumenda minus. Error</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="how-it-work-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>How It Works</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-work">
                    <span>1</span>
                    <h3>Access your affiliate account</h3>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, ut corporis sint pariatur laboriosam sapiente.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-work">
                    <span>2</span>
                    <h3>Share your the affiliate link</h3>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, ut corporis sint pariatur laboriosam sapiente.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-work">
                    <span>3</span>
                    <h3>Watch your affiliate commissions</h3>
                    <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Incidunt, ut corporis sint pariatur laboriosam sapiente.</p>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="affiliate-area pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Become An Affiliate</h2>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. ipsum suspendisse.</p>
        </div>

        <div class="row">
            <div class="col-lg-4 col-sm-6">
                <div class="single-affiliate-info">
                    <i class="bx bx-cabinet"></i>
                    <h3>Impact Radius</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio illo corporis possimus, domain registration assumenda minus. Error</p>
                    <a class="default-btn" routerLink="/">Enroll here</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6">
                <div class="single-affiliate-info">
                    <i class="bx bx-recycle"></i>
                    <h3>Commission Junction</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio illo corporis possimus, domain registration assumenda minus. Error</p>
                    <a class="default-btn" routerLink="/">Enroll here</a>
                </div>
            </div>

            <div class="col-lg-4 col-sm-6 offset-sm-3 offset-lg-0">
                <div class="single-affiliate-info">
                    <i class="bx bx-share-alt"></i>
                    <h3>Share A Sale</h3>
                    <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odio illo corporis possimus, domain registration. assumenda minus. Error</p>
                    <a class="default-btn" routerLink="/">Enroll here</a>
                </div>
            </div>
        </div>
    </div>
</section>

<section class="affiliate-program-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="customer-title">
                    <h2>24/7 Online Customer Support</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt  labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commo o viverra maecenas accumsan lacus.</p>
                    <p>Lorem ipsum dolor sit, amet consectetur adipisicing elit. Cum quasi sapiente obcaecati, nesciunt nemo quam ut ipsa vel, labore ex ad.</p>
                </div>

                <div class="row">
                    <div class="col-lg-6 col-sm-6">
                        <ul class="support-list">
                            <li>Earn $10.00 for every referral</li>
                            <li>365 Day cookie tracking</li>
                            <li>A wide database's template</li>
                            <li>Monthly affiliate payouts</li>
                        </ul>
                    </div>

                    <div class="col-lg-6 col-sm-6">
                        <ul class="support-list">
                            <li>High conversion rates</li>
                            <li>Real-time reporting</li>
                            <li>Prioritizing simplicity</li>
                            <li>Personalized tracking Codes</li>
                        </ul>
                    </div>

                    <div class="col-12 col-md-12">
                        <a class="default-btn mt-3" routerLink="/sign-up">Sign Up</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="affiliate-img">
                    <img src="assets/img/support-img.png" alt="Image" type="image/png">
                </div>
            </div>
        </div>
    </div>
</section>
