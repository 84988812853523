<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Industrial Sliding Door</h2>
            <p>GATEMAAN offer sliding doors for almost every application from a simple doorway size right up to aircraft hangar size. Our sliding doors are nearly all a double skinned steel sandwich construction for extra strength and stability, with various options in the running mechanism to be either supported and sliding at the top or the bottom, depending on the application.
            </p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                    style="visibility: visible;">
                    <img src="assets/img/products/doors/isd0.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/doors/isd1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/doors/isd2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/doors/isd3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/doors/isd4.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">

                </div>
                    <div class="article-content" align="justify">
                        <h3>About Industrial Sliding</h3>
                        <p> Our products are made from top-quality materials which makes them extremely durable. Compact track and hanger design enables moving heavy loads without compromising safety or operational lightness. These sliding door systems are particularly suitable for industrial buildings, large halls and aircraft hangers. If you need sliding door systems for demanding conditions, we offer a wide range of Stainless steel products</p>
                    </div>
                    <ul class="feat" align="justify" style="line-height:180%">
<li>There are no internal tracks to cause obstructions, there are no spring tension systems requiring maintenance, tensioning and servicing and the life span for sliding door.
</li>
<li>The service levels for most sliding doors is absolutely minimal as there are so few moving parts and with little stress placed on them.
</li>
<li>The door leaf obviously slides neatly along and against a wall either internally or externally leaving maximum space clear.
</li>
<li>The overall security for a sliding door is superb as it is difficult to force a panel that is overlapping the structure it is fitted to, and it is effectively secured on all  4 sides.
</li>
</ul>
            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>
            <blockquote class="flaticon-quote">
                <table class="table">
     <thead class="table-light">
       <tr>
         <th scope="col">Product : </th>
         <th scope="col">Motorized Sliding Gate</th>
       </tr>
     </thead>
     <tbody>
       <tr>
         <th scope="row" color="#fe9900">Door Dimension: </th>
         <td></td>
       </tr>
       <tr>
         <th scope="row">Coming Soon</th>
         <td></td>
       </tr> 
     </tbody>
   </table>  
 </blockquote>
        </div>

    </div>
</section>
