<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Swing Gate</h2>
            <p>A Gate that swings in either direction and closes when released. A swing gate has one or two doors that swing open on hinges attached to poles. Swing gates are made-to-measure and are available in different styles and materials.
            </p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                style="visibility: visible;">
                <img src="assets/img/products/gates/s1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                <img src="assets/img/products/gates/s2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                <img src="assets/img/products/gates/s3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                <img src="assets/img/products/gates/s4.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                <img src="assets/img/products/gates/s5.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
            </div>

                    <div class="article-content">
                        <h3>About Swing Gate</h3>
                        <p align="justify">A swing gate has one or two doors that swing open on hinges attached to poles. Swing gates are made-to-measure and are available in different styles and materials. There are also various options for operating them. Swing gates need the space of at-least 6% clear opening on either side for the gate for the gate leafs to swing while opening and closing.
                            Swing Gate is an ideal solution where a driveway access is critical due to lower frequented entrances or exits.
                            swing gates consist of a single leaf or double leaf which typically travels a 90 degree arc between their open and closed positions.</p>


                            <ul class="feat" align="justify" style="line-height:180%">
                                <li>Our swing gates are perfect suit to residential, commercial and industrial applications because of their low cost and ease of installation.</li>
        <li>Our swing gate is manufactured and installed perfectly to prevent people or vehicles from being hit or trapped by the moving gate.
        </li>
<li>our swing gates are - convenient, safer, more functional, extremely durable, less space and improve visual appeal.</li>
<li>Upper and lower swinging mechanism is provided which is simple and reliable to use</li>

                            </ul>



                    </div>


            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>


<!------>          
        <!--My code  -->
        <blockquote class="flaticon-quote">
        <table class="table table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col">Product : </th>
                <th scope="col">Automatic Swing Gate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" color="#fe9900">Gate Dimension</th>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Width Min. /Max.</th>
                <td>Single Leaf : 1/4mm; Double Leaf :2/8mm</td>
              </tr>
              <tr>
                <th scope="row">Height Min. /Max.</th>
                <td>1/21mm Larger Dimension on request</td>
              </tr>
              <tr>
                <th scope="row">Max. No. of Leaves</th>
                <td>Single Leaf : 1 No.</td>
              </tr>
              <tr>
                <th scope="row"></th>
                <td>Double Leaf : 2 No.</td>
              </tr>
              <tr>
                <th scope="row">Available In</th>
                <td>Mild Steel, Stainless Steel, Wood, Aluminum,and Perforated Sheet.</td>
              </tr>
              <tr>
                <th scope="row">Material</th>
                <td>Aluminum and Galvanized steel</td>
              </tr>
              <tr>
                <th scope="row">Color</th>
                <td>Optional (red antique copper, bronze, rose golden etc)</td>
              </tr>
              <tr>
                <th scope="row">Size of upright column</th>
                <td>1mm * 1mm * 2mm</td>
              </tr>
              <tr>
                <th scope="row">Top tube</th>
                <td>4mm * 7mm * 1.5mm</td>
              </tr>
              
              <tr>
                <th scope="row">Horizontal Tube</th>
                <td>4mm * 4mm * 1.5mm</td>
              </tr>
              <tr>
                <th scope="row">Gate Size</th>
                <td>According customers requirements</td>
              </tr>
              <tr>
                <th scope="row">Standard</th>
                <td>up to the specific size of products</td>
              </tr>
              <tr>
                <th scope="row">Widly Applied to</th>
                <td>Enterprise, Factory, Park, School, University, Stadium etc.,</td>
              </tr>
              <tr>
                <th scope="row" color="#fe9900">Gate Structure</th>
                <td></td>
              </tr>

              <tr>
                <th scope="row">Outer Frame</th>
                <td>Heavy Duty Box Section or As per the clients specific design</td>
              </tr>
              <tr>
                <th scope="row">Internal Members</th>
                <td>Heavy Duty Box Section Or As per the clients specific design.</td>
              </tr>
              <tr>
                <th scope="row">Hinges</th>
                <td>High precision top & bottom pivoted hinges with ball bearing / heavy duty hinges
                </td>
              </tr>
              <tr>
                <th scope="row">Bottom Rail</th>
                <td>polished bright bar with I-beam support</td>
              </tr>
              <tr>
                <th scope="row">Bottom Rollers</th>
                <td>Heavy Duty Bottom Wheel with both side bearings.</td>
              </tr>
              <tr>
                <th scope="row"> Full height Column</th>
                <td>Heavy Duty Box Section Or as per the clients specific design Special Color on request</td>
              </tr>
              <tr>
                <th scope="row" color="#fe9900">Pattern / Design</th>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Available Gate material</th>
                <td>Mild Steel, Stainless Steel, 
                    Hot dip Galvanized Steel, 
                    Cast Iron</td>
              </tr>
              <tr>
                <th scope="row">Options Available</th>
                <td>Spikes as per requirement Wicket gate/Side Gates in the similar pattern as the main gate, Inbuilt gate in the similar pattern as the main gate, Any type of Customized Design can be made on request</td>
              </tr>
              <tr>
                <th scope="row" color="#fe9900">Details for Automation System</th>
                <td></td>
              </tr>
              <tr>
                <th scope="row"> Drive Unit</th>
                <td>Electric Motor(Imported) Single/Three Phase.</td>
              </tr>
              <tr>
                <th scope="row">Accessories</th>
                <td>Operational Accessories, Additional Push Button, Key switch, Remote Control with receiver, Magnetic Loop Detector</td>
              </tr>
              <tr>
                <th scope="row">Certification</th>
                <td>CE</td>
              </tr>
              <tr>
                <th scope="row">Protection Class</th>
                <td>IP44 – IP55.</td>
              </tr>
              
            </tbody>
          </table>
          </blockquote>
          


<!----  


            <blockquote class="flaticon-quote">
                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:12px"><span class=""></span> &nbsp;Product</td>
                                        <td>Automatic Swing Gates
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2" >Gate Dimension</td>
                                    </tr>

                                    <tr>
                                        <td style="width:12px"><span class="fa fa-dashboard"></span> &nbsp;Width Min. /Max.</td>
                                        <td> Single Leaf : 1/4mm
                                            Double Leaf :2/8mm</td>
                                    </tr>

                                    <tr>
                                        <td style="width:12px;" class="txt-clr"><span class="fa fa-gears"></span>&nbsp;Height Min. /Max.</td>
                                        <td class="txt-clr" >1/21mm
                                            Larger Dimension on request</td>
                                    </tr>

                                    <tr>
                                        <td style="width:12px"><i class="fa fa-steam"></i>&nbsp;Max. Number of Leaves </td>
                                        <td>Single Leaf:1 No<hr>
                                            Double Leaf:2 No</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;" class="txt-clr"><span></span>&nbsp;Available in</td>
                                        <td class="txt-clr" >
                                            Mild Steel, Stainless Steel, Wood, Aluminum,and Perforated Sheet.</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:12px"><span class=""></span> &nbsp;Material</td>
                                        <td>Aluminum and Galvanized steel</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;"><span class=""></span> &nbsp;Color</td>
                                        <td >Optional (red antique copper, bronze, rose golden etc)</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px"><span class=""></span> &nbsp;Size of upright column</td>
                                        <td >1mm*1mm*2.mm</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;"><span class=""></span>&nbsp; Top tube</td>
                                        <td >4mm*7mm*1.5mm</td>
                                    </tr>

                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp;Horizontal Tube</td>
                                        <td>4mm*4mm*1.5mm</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp; Gate Size</td>
                                        <td >According customers requirements</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px"><i class=""></i>&nbsp;Standard</td>
                                        <td>up to the specific size of products</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <br><hr>
                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>

                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp; Widly Applied to</td>
                                        <td style="">Enterprise, Factory, Park, School, University, Stadium...</td>
                                    </tr>

                                    <tr>

                                        <td colspan="2" >Gate Structure</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;" class="txt-clr"><i class=""></i>&nbsp; Outer Frame</td>
                                        <td class="txt-clr" style="">Heavy Duty Box Section<hr>
                                            Or as per the clients specific design</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp;Internal members	 </td>
                                        <td style="">Heavy Duty Vertical Box sections<hr>
                                            Or as per the clients specific design</td>
                                    </tr>


                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp;Hinges	 </td>
                                        <td >High precision top &amp; bottom pivoted hinges with ball bearing / heavy duty hinges</td>
                                    </tr>

                                    <tr>

                                        <td colspan="2" >Patterns/Designs</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp;Available gate material	 </td>
                                        <td>
                                            Mild Steel<br>
                                            Stainless Steel<br>
                                            Hot dip Galvanized Steel<br>
                                            Cast Iron
                                        </td>
                                    </tr> -->

                                    <!--  <tr>
                                                                          <td style="width:12px;"><i class=""></i>&nbsp; Sheets	 </td>
                                                                          <td >
                                          M.S/GI/Perforated /Aluminum sheets<br>
          Polycarbonate / Acrylic / PVC Sheet/Glass/Wood
                                          </td>
                                                                  </tr> 

                                </tbody>
                            </table>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp;Options Available	 </td>
                                        <td>
                                            Spikes as per customized requirement<br>
                                            Wicket gate/Side Gates in the similar pattern as the main gate<br>
                                            Inbuilt gate in the similar pattern as the main gate<br>
                                            Any type of Customized Design can be made on request.
                                        </td>
                                    </tr>

                                    <tr>

                                        <td colspan="2" >Details for Automation System</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp;Drive Unit	 </td>
                                        <td >Electric Motor(Imported)<br>
                                            Single/Three Phase.</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp;Accessories </td>
                                        <td >
                                            Operational Accessories<br>
                                            Additional Push Button<br>
                                            Key switch<br>
                                            Remote Control with receiver<br>
                                            Magnetic Loop Detector
                                        </td>

                                    </tr>

                                    <tr>

                                        <td colspan="2" >Features</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp;Certification	 </td>
                                        <td >CE
                                            Special Color on request</td>
                                    </tr>
                                    <tr>
                                        <td style="width:12px;"><i class=""></i>&nbsp;Protection Class	 </td>
                                        <td>IP44 – IP55</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </blockquote>
        -->
        </div>

    </div>
</section>
