<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Perforated Shutters</h2>
            <p>Perforated Rolling Shutters are high security steel rolling shutter doors, suitable for a wide variety of applications that require good ventilation and/or visibility. We are devotedly betrothed in providing a wide series of Rolling Grills and Perforated Rolling Shutters.
              </p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/products/shutters/perf1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/perf2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/perf3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                </div>

                    <div class="article-content" align="justify">
                        <h3>About Perforated Shutter</h3>
                        <p>  Perforated rolling shutters are the shutters, which are made up off perforated sheet (sheet metal that
                            has been manually or mechanically stamped or punched to create a pattern of holes, slots, or decorative
                            shapes).Small holes provide partial vision and airflow. We provides vision and ventilation without compromising security.
                        </p>
                        <ul class="feat" style="line-height:180%">
                            <li>Steel, Aluminium or stainless steel perforated rolling shutters and grill rolling shutters are available in various designs.
                            </li>
                            <li>Our shutters are manufactured from the best quality metals which ensure long lasting performance.
                            </li>
                            <li>Both vision and ventilation panels can be provided as per clients’ requirement.</li>
                            <li>These rolling shutters give longer product life.</li>
                            <li>These shutters mostly used in office areas, to create a professional and corporate look.
                            </li>
                            <li>Heat Insulation Environment friendly</li>
                        </ul>
                    </div>



            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>

            <table class="table table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Product : </th>
                    <th scope="col">Motorized Perforated Rolling Shutter (Need to Update the Specifications)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" color="#fe9900">Shutter Dimension : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Width Min. /Max.</th>
                    <td>1000 / 12000mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Height Min. /Max.</th>
                    <td>1000 / 12000mm; Max. Area 90.0 Sqm 
                        <br> Large Dimestion on Request
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Opening Dimension</th>
                    <td>Vertical</td>
                  </tr>
                  <tr>
                    <th scope="row">Mounting Location</th>
                    <td>Inside / Outside</td>
                  </tr>
                  <tr>
                    <th scope="row">Shutter Material</th>
                    <td>Aluminum Alloy</td>
                  </tr>
                  <tr>
                    <th scope="row">Maximum Width * Height:</th>
                    <td>6 * 8 m</td>
                  </tr>
                  <tr>
                    <th scope="row"> Type of box</th>
                    <td>165 185 205 250 300 350mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Thickness</th>
                    <td>1.2mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Surface Treatin</th>
                    <td>Powder Coating</td>
                  </tr>
                  <tr>
                    <th scope="row">The sevice life</th>
                    <td>can be used 15 years</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening/Closing Speed</th>
                    <td>0.05m - 0.2m/sec</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Up to 90 km/hr
                        <br> * Higher Wind Pressure on Request
                    </td>
                  </tr>
                
                  <tr>
                    <th scope="row" color="#fe9900">Motor Size</th>
                    <td>600Kg / 800Kg / 1000Kg etc</td>
                  </tr>
    
                  <tr>
                    <th scope="row">Color</th>
                    <td>Red, Yellow, Orange, Blue, Grey etc</td>
                  </tr>
                  <tr>
                    <th scope="row">Control Panel</th>
                    <td>Danvers Brand,Including Inverter, PLC and other components.Size Option: 0.75KW</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive System</th>
                    <td>Germany SEW motor 380V or 220V/50Hz 0.75kw,1.1kw,1.5kw
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Opening Way</th>
                    <td>Double-side radar, double-side press button, infrared sensors</td>
                  </tr>
                  <tr>
                    <th scope="row">Dynamical systems</th>
                    <td>Adopt high-tech motor reducer:0.75-2.2KW, which according to your door size.</td>
                  </tr>
                  <tr>
                    <th scope="row">Options Available</th>
                    <td>Perforation In all above Slat profile S.S Hood Cover made of sheet thickness 0.4mm</td>
                  </tr>
    
                  <tr>
                    <th scope="row">Details for Automation System : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Control Type</th>
                    <td>Deadman / Pulse Control</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Type</th>
                    <td>Indirect / Direct Connecting Drive</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Unit</th>
                    <td>Electric Motor(Imported Make) Single Phase/Three Phase.</td>
                  </tr>
                  <tr>
                    <th scope="row">Emergency Operation	</th>
                    <td>Manual operation by Hand chain</td>
                  </tr>
                  <tr>
                    <th scope="row" color="#fe9900">Options Available</th>
                    <td>Up to 90 km/hr</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Perforation In Slat profile
                        Rolling Grill
                        Wicket Door for pedestrian entry</td>
                  </tr>
                  <tr>
                    <th scope="row">Features : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Certification</th>
                    <td>CE VDE checked</td>
                  </tr>
                  <tr>
                    <th scope="row">Protection Class</th>
                    <td>IP54.</td>
                  </tr>
                  
                </tbody>
              </table>

<!--
            <blockquote class="flaticon-quote">
                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:120px"><span class="fa fa-thumbs-o-up"></span> &nbsp;Product
                                        </td>
                                        <td>Motorized perforated Shutter
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2" style="text-align:center;color:white;">Gate Dimension</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><span class="fa fa-dashboard"></span> &nbsp;Width Min.
                                            /Max.</td>
                                        <td>Single Leaf : 1000/12000mm
                                            Double Leaf :1000/24000mm</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><span class="fa fa-gears"></span>&nbsp;
                                            Height Min. /Max.</td>
                                        <td class="txt-clr">1000/2200mm
                                            Larger Dimension on request</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><i class="fa fa-steam"></i>&nbsp;Max. Number of Leaves
                                        </td>
                                        <td>Two</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><i
                                                class="fa fa-fire-extinguisher"></i>&nbsp; Opening Direction</td>
                                        <td class="txt-clr">Horizontal</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><span></span> &nbsp;Warranty</td>
                                        <td>Limited Lifetime on the motor, gears, and rail assembly</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;" class="txt-clr"><span></span> &nbsp;illumination</td>
                                        <td class="txt-clr">500 Lumens of integrated LED lighting provides over 30.000
                                            hours</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px"><span></span> &nbsp;technology</td>
                                        <td>Multi-bit code technology 315MHz 2-channel remote control is included</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:120px" class="txt-clr"><span class=" txt-clr"></span>&nbsp;
                                            HPcs</td>
                                        <td class="txt-clr">HPcs indicates that Synergy 380 meets Marantec’s
                                            specifications for 1.1 HP garage door operators</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;maintenance</td>
                                        <td>Operator and rail system are maintenance free</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px" class="txt-clr"><i></i>&nbsp; safety system</td>
                                        <td class="txt-clr">photo eye safety system is equipped with UV filters in order
                                            to eliminate interference created by sunlight</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><i></i>&nbsp;Mounting Location </td>
                                        <td>Inside</td>
                                    </tr>


                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Mounting Surface </td>
                                        <td >Bottom Rail: RCC
                                            Columns : RCC</td>
                                    </tr>

                                    <tr>

                                        <td colspan="2" >Gate Design</td>
                                    </tr>
                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Pattern </td>
                                        <td >Standard Mild Steel Tubular pattern</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Gate Frame </td>
                                        <td >Heavy Duty Box Section
                                            Or as per the clients specific design</td>
                                    </tr>


                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Internal vertical member </td>
                                        <td>Heavy Duty Vertical Box sections
                                            Or as per the clients specific design.</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <hr>
            </blockquote>
            -->
        </div>

    </div>
</section>
