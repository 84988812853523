<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Curved Sliding Gates</h2>
            <p>Curved sliding gates are a specialized type of gate designed to operate along a curved track 
                rather than a straight line. They are typically used in situations where space constraints 
                or architectural considerations make a straight sliding gate impractical or less aesthetically 
                pleasing.
            </p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated" style="visibility: visible;">
                    <img src="assets/img/products/gates/cs1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/gates/cs2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/gates/cs3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/gates/cs1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                </div>

                <div class="article-content">
                    <h3>About Curved Sliding Gates</h3>
                    <p align="justify">Curved sliding gates can turn a 90-degree corner and return along walls that are perpendicular to the gate opening.
                        The gate consists of a series of panels connected to each other with hinges that follow a curved track. 
                        Like traditional sliding gates, curved sliding gates can be operated manually or automated with motors for convenience. 
                        Automation is particularly useful for larger or heavier gates.
                    </p>
                  <p align="justify"> Curved Sliding Gates provide an innovative solution for your bulk material conveying and 
                    distribution needs. The unique curved slide plate is precision formed to accurately fit the contours of 
                    a screw conveyor U-trough. When compared to a standard screw conveyor discharge with flat slide gate, 
                    the curved slide plate eliminates the potential for material build up. The curved slide plate is guided 
                    by bronze-bushed rollers that require no grease and are adjustable in order to provide years of maintenance-free 
                    operation. Replacement parts are easy to source and low cost. </p>

                        <ul class="feat" align="justify" style="line-height:180%">
                            <li>The curved slide plate fits snugly along the bottom of the trough, there is no place for conveyed bulk materials to gather.</li>
                            <li>Bridging and packing of bulk materials is eliminated.</li>
                            <li>The curved slide plate also reduces cross contamination when conveying different bulk materials in the same conveyor.</li>
                            <li>Curved Slide Gates can be designed and manufactured for any application and constructed from a wide variety of materials and thicknesses.</li>
                            <li>Inlet and discharge transitions can be adapted to fit any opening configuration.</li>
                        </ul>

                </div>


            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>

<!------>          
        <!--My code  -->
        <blockquote class="flaticon-quote">
        <table class="table table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col">Product : </th>
                <th scope="col">Motorized Curve Sliding Gate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" color="#fe9900">Gate Dimension</th>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Width Min. /Max.</th>
                <td>Single Leaf : 1000/12000mm; Double Leaf :1000/24000mm</td>
              </tr>
              <tr>
                <th scope="row">Height Min. /Max.</th>
                <td>1000/2200mm Larger Dimension on request</td>
              </tr>
              <tr>
                <th scope="row">Max. No. of Leaves</th>
                <td>2</td>
              </tr>
              <tr>
                <th scope="row">Opening Direction</th>
                <td>Horizontal</td>
              </tr>
              <tr>
                <th scope="row">Warranty</th>
                <td>Limited Lifetime on the motor, gears, and rail assembly</td>
              </tr>
              <tr>
                <th scope="row"> Illumination	</th>
                <td>500 Lumens of integrated LED lighting provides over 30.000 hours</td>
              </tr>
              <tr>
                <th scope="row"> Technology</th>
                <td>Multi-bit code technology 315MHz 2-channel remote control is included</td>
              </tr>
              <tr>
                <th scope="row">Available In</th>
                <td>Mild-Steel, Stainless Steel,Wood & Steel combination, Aluminum and Hot-dip galvanization including powder coating.</td>
              </tr>
              <tr>
                <th scope="row">HPcs</th>
                <td>HPcs indicates that Synergy 380 meets Marantec’s specifications for 1.1 HP garage door operators</td>
              </tr>
              <tr>
                <th scope="row">Maintenance</th>
                <td>Operator and rail system are maintenance free</td>
              </tr>
              <tr>
                <th scope="row">Safety System</th>
                <td>photo eye safety system is equipped with UV filters in order to eliminate interference created by sunlight.</td>
              </tr>
              <tr>
                <th scope="row">Mounting Location</th>
                <td>Inside</td>
              </tr>
              <tr>
                <th scope="row">Mounting Surface</th>
                <td>Bottom Rail: RCC Columns : RCC.</td>
              </tr>

              <tr>
                <th scope="row" color="#fe9900">Gate Design</th>
                <td></td>
              </tr>

              <tr>
                <th scope="row">Pattern</th>
                <td>Standard Mild Steel Tubular pattern.</td>
              </tr>
              <tr>
                <th scope="row">Gate Frame</th>
                <td>Heavy Duty Box Section Or as per the clients specific design.</td>
              </tr>
              <tr>
                <th scope="row"> Internal vertical member</th>
                <td>Heavy Duty Vertical Box sections Or as per the clients specific design.
                </td>
              </tr>
              <tr>
                <th scope="row">Bottom Rail</th>
                <td>polished bright bar with I-beam support</td>
              </tr>
              <tr>
                <th scope="row">Bottom Rollers</th>
                <td>Heavy Duty Bottom Wheel with both side bearings.</td>
              </tr>
              <tr>
                <th scope="row"> Full height Column</th>
                <td>Heavy Duty Box Section Or as per the clients specific design Special Color on request</td>
              </tr>
              <tr>
                <th scope="row">Guide Rollers</th>
                <td>Nylon Rollers with bearings both side mounted on column for support & smooth movement of gate.</td>
              </tr>

              <tr>
                <th scope="row" color="#fe9900">Pattern / Design</th>
                <td></td>
              </tr>

              <tr>
                <th scope="row">Other available gate material</th>
                <td>Stainless Steel, Hot dip Galvanized</td>
              </tr>
              <tr>
                <th scope="row"> Sheets</th>
                <td>M.S/ GI/ Perforated/ Aluminum sheets/ Polycarbonate / Acrylic / PVC Sheet/ Glass/ Wood</td>
              </tr>
              <tr>
                <th scope="row">Ornamental Designs</th>
                <td>On Request or as per client design.</td>
              </tr>
              <tr>
                <th scope="row">Options Available</th>
                <td>Spikes as per requirement Wicket gate/Side Gates in the similar pattern as the main gate, Inbuilt gate in the similar pattern as the main gate, Any type of Customized Design can be made on request</td>
              </tr>
              <tr>
                <th scope="row">Surface Finish</th>
                <td>Standard: Red Oxide Primer Optional: Enamel Painting/Duco/PU painting Available with or without sand Blasting</td>
              </tr>
              <tr>
                <th scope="row" color="#fe9900">Details for Automation System</th>
                <td></td>
              </tr>
              <tr>
                <th scope="row"> Drive Unit</th>
                <td>Electric Motor(Imported) Single/Three Phase.</td>
              </tr>
              <tr>
                <th scope="row">Emergency Operation</th>
                <td>Manual release knob</td>
              </tr>
              <tr>
                <th scope="row">Accessories</th>
                <td>Operational Accessories, Additional Push Button, Key switch, Remote Control with receiver, Magnetic Loop Detector</td>
              </tr>
              <tr>
                <th scope="row">Certification</th>
                <td>CE</td>
              </tr>
              <tr>
                <th scope="row">Protection Class</th>
                <td>IP44 – IP55.</td>
              </tr>
              
            </tbody>
          </table>
          </blockquote>
          


<!----




            <blockquote class="flaticon-quote">
                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td class="aluf6"><span class="fa fa-thumbs-o-up"></span> &nbsp;Product</td>
                                        <td>Motorized Boom Barrier Gate
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">Gate Dimension</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><span class="fa fa-dashboard"></span> &nbsp;Width Min. /Max.
                                        </td>
                                        <td>Single Leaf : 1000/12000mm
                                            Double Leaf :1000/24000mm</td>
                                    </tr>
                                    <tr>
                                        <td class="txt-clr aluf7"><span class="fa fa-gears"></span>&nbsp; Height Min.
                                            /Max.</td>
                                        <td class="txt-clr">1000/2200mm
                                            Larger Dimension on request</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><i class="fa fa-steam"></i>&nbsp;Max. Number of Leaves</td>
                                        <td>Two</td>
                                    </tr>
                                    <tr>
                                        <td class="txt-clr aluf7"><i class="fa fa-fire-extinguisher"></i>&nbsp; Opening
                                            Direction</td>
                                        <td class="txt-clr">Horizontal</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><span></span> &nbsp;Warranty</td>
                                        <td>Limited Lifetime on the motor, gears, and rail assembly</td>
                                    </tr>
                                    <tr>
                                        <td class="txt-clr aluf7"><span></span> &nbsp;illumination</td>
                                        <td class="txt-clr aluf8">500 Lumens of integrated LED lighting provides over
                                            30.000 hours</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><span></span> &nbsp;technology</td>
                                        <td>Multi-bit code technology 315MHz 2-channel remote control is included</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td class="txt-clr aluf6"><span class=" txt-clr"></span>&nbsp; HPcs</td>
                                        <td class="txt-clr">HPcs indicates that Synergy 380 meets Marantec’s
                                            specifications for 1.1 HP garage door operators</td>
                                    </tr>

                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;maintenance</td>
                                        <td>Operator and rail system are maintenance free</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><i></i>&nbsp; safety system</td>
                                        <td class="txt-clr">photo eye safety system is equipped with UV filters in order
                                            to eliminate interference created by sunlight</td>
                                    </tr>

                                    <tr>
                                        <td class="aluf7"><i></i>&nbsp;Mounting Location </td>
                                        <td class="aluf6">Inside</td>
                                    </tr>


                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Mounting Surface </td>
                                        <td>Bottom Rail: RCC
                                            Columns : RCC</td>
                                    </tr>

                                    <tr>

                                        <td colspan="2">Gate Design</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Pattern </td>
                                        <td>Standard Mild Steel Tubular pattern</td>
                                    </tr>

                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Gate Frame </td>
                                        <td>Heavy Duty Box Section
                                            Or as per the clients specific design</td>
                                    </tr>


                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Internal vertical member </td>
                                        <td class="aluf8">Heavy Duty Vertical Box sections
                                            Or as per the clients specific design.</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <hr>




                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>

                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Bottom Rail
                                        </td>
                                        <td>polished bright bar with I-beam
                                            support.
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Bottom Rollers
                                        </td>
                                        <td>Heavy Duty Bottom Wheel with both side bearings
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Full height Column </td>
                                        <td>Heavy Duty Box Section
                                            Or as per the clients specific design
                                            Special Color on request</td>
                                    </tr>

                                    <tr>
                                        <td style="width:120px;"><i class=""></i>&nbsp;Guide Rollers
                                        </td>
                                        <td>Nylon Rollers with bearings both side mounted on
                                            column for support &amp; smooth movement of gate.
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">Patterns/Designs</td>
                                    </tr>

                                    <tr>
                                        <td><i class=""></i>&nbsp;Other available gate
                                            material </td>
                                        <td>Stainless Steel,
                                            Hot dip Galvanized</td>
                                    </tr>

                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Sheets </td>
                                        <td class="aluf6">
                                            M.S/GI/Perforated /Aluminum sheets
                                            Polycarbonate / Acrylic / PVC Sheet/Glass/Wood
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><i class=""></i>&nbsp;Ornamental Designs </td>
                                        <td>On Request or as per client design.</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Options Available </td>
                                        <td class="aluf6">
                                            Spikes as per requirement
                                            Wicket gate/Side Gates in the similar pattern as the main gate,
                                            Inbuilt gate in the similar pattern as the main gate,
                                            Any type of Customized Design can be made on request
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="aluf8"><i class=""></i>&nbsp;Surface Finish </td>
                                        <td class="aluf8">
                                            Standard: Red Oxide Primer
                                            Optional: Enamel Painting/Duco/PU painting
                                            Available with or without sand Blasting
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2" style="text-align:center;color: #fff;">Details for automation
                                            system</td>
                                    </tr>

                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Drive Unit </td>
                                        <td class="aluf7">
                                            Electric Motor(Imported)
                                            Single/Three Phase
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="aluf8">&nbsp;Emergency Operation </td>
                                        <td class="aluf8">Manual release knob
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="aluf8"><i class=""></i>&nbsp;Accessories </td>
                                        <td class="aluf8">
                                            Operational Accessories,
                                            Additional Push Button,
                                            Key switch,
                                            Remote Control with receiver,
                                            Magnetic Loop Detector
                                        </td>
                                    </tr>

                                    <tr>
                                        <td colspan="2">Features</td>
                                    </tr>

                                    <tr>
                                        <td class="aluf8"><i class=""></i>&nbsp;Certification </td>
                                        <td class="aluf8">CE
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="aluf8">&nbsp;Protection Class </td>
                                        <td class="aluf8">IP44 – IP55
                                        </td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </blockquote> -->
        </div>

    </div>
</section>
