import { Component, OnInit } from '@angular/core';
import { Location, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { filter } from 'rxjs/operators';
declare let gtag;
import {
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router
  } from '@angular/router';
declare let $: any;
import { MetaService } from './services/meta-service.service';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    providers: [
        Location, {
            provide: LocationStrategy,
            useClass: PathLocationStrategy
        }
    ]
})
export class AppComponent implements OnInit {
    location: any;
    routerSubscription: any;
    title = 'Ng-Teams';
    loading = false;
    constructor(private router: Router, private metaService: MetaService) {
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                gtag('config', 'AW-401464378',
                    {
                        page_path: event.urlAfterRedirects
                    }
                );
            }
        });
        this.router.events.subscribe((event: Event) => {
            switch (true) {
              case event instanceof NavigationStart: {
                this.loading = true;
                break;
              }

              case event instanceof NavigationEnd:
              case event instanceof NavigationCancel:
              case event instanceof NavigationError: {
                this.loading = false;
                break;
              }
              default: {
                break;
              }
            }
          });
    }

    ngOnInit() {
       // this.metaService.setCanonicalURL();
      //  this.recallJsFuntions();
    }

    onActivate(event) {
        window.scroll(0, 0);

    }


    // recallJsFuntions() {
    //     this.router.events
    //     .subscribe((event) => {
    //         if ( event instanceof NavigationStart ) {
    //             $('.preloader').fadeIn('slow');
    //         }
    //     });
    //     this.routerSubscription = this.router.events
    //     .pipe(filter(event => event instanceof NavigationEnd || event instanceof NavigationCancel))
    //     .subscribe(event => {
    //         $.getScript('../assets/js/custom.js');
    //         $('.preloader').fadeOut('slow');
    //         this.location = this.router.url;
    //         if (!(event instanceof NavigationEnd)) {
    //             return;
    //         }
    //         window.scrollTo(0, 0);
    //     });
    // }
}
