<footer class="footer-top-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <a routerLink="/" class="logo">
                        <img src="assets/img/gatemaangold.png" alt="Image">
                    </a>
                    <p>Lorem ipsum dolor consectetur, exercitationem adipisicing elit. Enim, exercitationem. Minima voluptatum.</p>
                    <ul class="social-icon">
                        <li><a href="#" target="_blank"><i class="bx bxl-facebook"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-twitter"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-instagram"></i></a></li>
                        <li><a href="#" target="_blank"><i class="bx bxl-linkedin"></i></a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget pl-5">
                    <h3>Products</h3>

                    <ul>
                        <li><a routerLink="/">Shared Lorem</a></li>
                        <li><a routerLink="/">Lorem Hosting</a></li>
                        <li><a routerLink="/">Reseller ipsum</a></li>
                        <li><a routerLink="/">VPS Lorem</a></li>
                        <li><a routerLink="/">Lorem Server</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Services</h3>

                    <ul>
                        <li><a routerLink="/">ipsum Name Search</a></li>
                        <li><a routerLink="/">Transipsumfer</a></li>
                        <li><a routerLink="/">ipsum ipsum</a></li>
                        <li><a routerLink="/">Free ipsum</a></li>
                        <li><a routerLink="/">Premium ipsum</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-footer-widget">
                    <h3>Support</h3>

                    <ul>
                        <li><a routerLink="/contact">Contact</a></li>
                        <li><a routerLink="/">Report Abuse</a></li>
                        <li><a routerLink="/">Tutorials</a></li>
                        <li><a routerLink="/">Blog</a></li>
                        <li><a routerLink="/">Premium ipsum</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</footer>


