import { Component, OnInit } from '@angular/core';
declare let $: any;
import { MetaService } from '../../../../services/meta-service.service';
@Component({
  selector: 'app-bi-folding-doors',
  templateUrl: './bi-folding-doors.component.html',
  styleUrls: ['./bi-folding-doors.component.scss']
})
export class BiFoldingDoorsComponent implements OnInit {

  constructor(private metaService: MetaService) { }

  ngOnInit(): void {
    this.metaService.setCanonicalURL();
    $('.custom1').owlCarousel({
        animateOut: 'slideOutDown',
        animateIn: 'slideInDown',
        items: 1,
        margin: 30,
        stagePadding: 30,
        smartSpeed: 450,
        autoplay: true,
        autoplayTimeout: 3000,
        autoplayHoverPause: true,
        loop: true
    });
  }

}
