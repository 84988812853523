<section class="blog-details-area">
    <br>
    <div class="row">

        <div class="col-sm-12 text-center">
            <ul class="nav nav-pills mb-3 text-center bordernav" id="pills-tab" role="tablist">

                <li class="nav-item">
                    <a class="nav-link active anav" id="stt-stt-tab" data-toggle="pill" href="#stt-stt" role="tab"
                        aria-controls="stt-stt" aria-selected="true">EFA STT®</a>
                </li>

                <li class="nav-item">
                    <a class="nav-link anav" id="stt-cr-tab" data-toggle="pill" href="#stt-cr" role="tab"
                        aria-controls="stt-cr" aria-selected="false">EFA STT® CR</a>
                </li>


            </ul>
        </div>

    </div>
    <br>
    <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show " id="stt-cr" role="tabpanel" aria-labelledby="stt-cr-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-STT® CR</h2>
                    <p>When speed is the first priority, you will find no better solution than the transparent clean
                        room
                        door EFA-STT® CR. Thanks to the patented spiral, this high-speed door opens and closes reliably
                        with
                        up to 2.5 m/s. It is particularly suitable if the exchange of pressure and atmosphere are to be
                        minimised, e.g. during the inertisation for try fire prevention. Compared with a normal
                        EFA-STT®,
                        its tightness in use for clean rooms is achieved by means of so-called labyrinth packings.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                        style="visibility: visible;">
                        <img src="assets/img/products/hsd/STT/1.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/STT/2.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/STT/3.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/STT/4.jpg" alt="Image" width="100%" height="auto">
                        <img src="assets/img/products/hsd/STT/5.jpg" alt="Image" width="100%" height="auto">

                    </div>

                            <div class="article-content">
                                <h3>About EFA-STT® CR</h3>
                                <p>
                                    The fixed door leaf which is made of single-walled transparent laths is kept in a
                                    certain
                                    distance during winding up. Thus, the EFA-STT® of the CR series remains transparent
                                    for
                                    many years - also for door sizes of 4x5 metres!
                                </p>
                                <strong>Safety Device</strong>
                                <p>
                                    EFAFLEX high-speed doors of course also guarantee highest standard with regard to
                                    safety. As the leading manufacturer
                                    of high-speed doors, EFAFLEX is aware of its responsibility! Therefore, we aim at
                                    doing pioneering work also with regard
                                    to safety. Our systems offer you considerably more safety - for persons as well as
                                    for material loaded and the door itself.
                                    <br>
                                    Not only the doors themselves satisfy highest demands at EFAFLEX. The appertaining
                                    safety systems also offer
                                    unique advantages. EFA-SCAN®, the latest EFAFLEX patent, is the first laser scanner
                                    worldwide which is suitable
                                    for being used in a door and which is activator and safety system at the same time.

                                </p>

                                <strong>EFA-STT® CR at a glance</strong>
                                <ul class="feat">
                                    <li>The door leaf consists at 70% of crystal clear acrylic glass.</li>
                                    <li>Opening up to 2.5 m/s.</li>
                                    <li>Closing up to 0.75 m/s.</li>
                                    <li>Almost airtight, Also for material locks</li>
                                    <li>Top safety devices.</li>
                                    <li>Up to 200,000 operating cycles p.a</li>
                                    <li>Standard sizes up to w=4,000mm x h=5,000mm</li>


                                </ul>



                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>



                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                    <blockquote class="flaticon-quote">
                        <table class="table">
             <thead class="table-light">
               <tr>
                 <th scope="col">Product : </th>
                 <th>EFA-STT® CR</th>
                 <th> </th>
                 <th> </th>
               </tr>
             </thead>
             <tbody>
                <tr>
                    <th scope="row" ></th>
                    <td scope="col">Size
                    </td>
                    <td scope="col">L
                    </td>
                  </tr>
               <tr>
                 <th scope="row" >Application </th>
                 <td scope="col">Interior Door
                    <br>
                    Lock up Doors
                 </td>
                 <td scope="col">•
                    <br>-
                </td>
               </tr>
               <tr>
                <th scope="row">Wind load max.*</th>
                <td scope="col">According to DIN EN 12424 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Operating forces/safe opening</th>
                <td scope="col">According to DIN EN 13241
                </td>
                <td scope="col">fulfilled
               </td>
              </tr>
              <tr>
                <th scope="row">Resistance against water ingress</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Air permeability*</th>
                <td scope="col">According to DIN EN 13241 class
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">Direct airborne sound insulation RW*</th>
                <td scope="col">in dB according to DIN EN 717-1
                </td>
                <td scope="col">-
               </td>
              </tr>
              <tr>
                <th scope="row">U value maximum*</th>
                <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
                </td>
                <td scope="col">-
               </td>
              </tr>

              <tr>
                <th scope="row">Door size (in mm) </th>
                <td scope="col">Width W max.
                    <br>
                    Height H max.
                </td>
                <td scope="col">4000
                    <br>5000
               </td>
              </tr>
              <tr>
                <th scope="row">Maximum door blade speed* </th>
                <td scope="col">in m/s
                </td>
                <td scope="col">3.0
               </td>
              </tr>
              <tr>
                <th scope="row">Average speed, ca.*</th>
                <td scope="col">Opening in m/s
                    <br>Closing in m/s
                    <br>Closing in m/s, with EFA-TLG® door light-line
                    grid
                </td>
                <td scope="col">3.0
                    <br>0.75
                    <br>0.75
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade guidance</th>
                <td scope="col">Round spiral
                    <br>Oval Spiral
                    <br>Low-header
                </td>
                <td scope="col">•
                    <br>-
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Steel design</th>
                <td scope="col">Galvanized sheet steel frame
                    <br>Stainless steel
                    <br>Powder coated in RAL colours
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Door blade</th>
                <td scope="col">EFA-THERM® laths insulated / painted
                    <br>EFA-CLEAR® Vision laths double-walled,
                    thermally separated
                    <br>EFA-CLEAR® Vision laths single-walled
                    <br>EFA-VENT® Ventilation laths
                    <br>EFA-ALUX® Aluminium laths
                    <br>Vision panel single-walled / double-walled
                    <br>non transparent infill single-walled / doublewalled
                    <br>Colour according to RAL (without vison panel)
                    <br>Door blade modules made of anodized
                    aluminium E6 / EV1
                    <br>Triple glazing for vision panel 
                    <br>Door curtain made of flexible PVC, transparent
                    with warning stripes in different colours 
                    <br>flexible fabric in different colours with / without
                    vison panel
                </td>
                <td scope="col">-
                        <br>-
                        <br>•
                        <br>-
                        <br>-
                        <br>-
                        <br>◦
                        <br>◦
                        <br>-
                        <br>-
                        <br>-
                        <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Fire class</th>
                <td scope="col">Building Material class DIN 4102
                </td>
                <td scope="col">B2
               </td>
              </tr>
              <tr>
                <th scope="row">Weight balancing by</th>
                <td scope="col">
                </td>
                <td scope="col">Spring
               </td>
              </tr>
              <tr>
                <th scope="row">Designed for approx ... operating cycles </th>
                <td scope="col">per year
                </td>
                <td scope="col">200,000
               </td>
              </tr>
              <tr>
                <th scope="row">Drive</th>
                <td scope="col">Electric motor
                    <br>Pneumatic with electric controller

                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Control</th>
                <td scope="col">EFA-TRONIC®
                    <br>EFA-TRONIC® Light
                    <br>EFA-TRONIC® Professional
                    <br>Main switch and foil keypad
                </td>
                <td scope="col">•
                    <br>-
                    <br>◦
                    <br>•
               </td>
              </tr>
              <tr>
                <th scope="row">Lead</th>
                <td scope="col">Electricity connection 230 V/50 Hz
                    <br>Electricity connection 400 V/50 Hz
                </td>
                <td scope="col">•
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Manual locking</th>
                <td scope="col">
                </td>
                <td scope="col">◦
               </td>
              </tr>
              <tr>
                <th scope="row">Emergency opening</th>
                <td scope="col">Automatic after manual activation
                    <br>Manual activation
                </td>
                <td scope="col">◦
                    <br>-
               </td>
              </tr>
              <tr>
                <th scope="row">Safety Devices</th>
                <td scope="col">EFA-TLG® door light grid in door closing line
                    <br>Contact edge
                    <br>Light barrier
                    <br>Approach area monitoring
                    <br>Light grid, external
                </td>
                <td scope="col">•
                    <br>◦
                    <br>◦
                    <br>◦
                    <br>◦
               </td>
              </tr>
              <tr>
                <th scope="row">Safety system including activator</th>
                <td scope="col">EFA-SCAN® frame/bollard
                </td>
                <td scope="col"> -/◦
               </td>
              </tr>
              <tr>
                <th scope="row">Activators</th>
                <td scope="col">Connection of all common activators possible
                </td>
                <td scope="col">•
               </td>
              </tr>
              </tbody>
           </table>
           <span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
             
         </blockquote>

                </div>

            </div>
        </div>

        <div class="tab-pane fade show active" id="stt-stt" role="tabpanel" aria-labelledby="stt-stt-tab">
            <div class="container">
                <div class="section-title">
                    <h2>EFA-STT®</h2>
                    <p>Due to its laths which are made of crystal clear acrylic glass, the door leaf of the EFA-STT®
                        is transparent at more than 70% - this fact makes it a worldwide unique high-speed door:
                        robust but nevertheless completely transparent.
                        <br>
                        The door leaf of the EFA-STT® is kept in a certain distance in the spiral. Thanks to
                        contact-less winding-up, the transparent laths offer a good view through the door for
                        many years - and with hardly any wear. Therefore, the whole door convinces with a perfect
                        look, even under hard everyday working conditions.</p>
                </div>
                <div class="row">
                    <div class="col-lg-8 col-md-12">
                        <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                            style="visibility: visible;">
                            <img src="assets/img/products/hsd/STT/cr1.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/STT/cr2.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/STT/cr3.jpg" alt="Image" width="100%" height="auto">
                            <img src="assets/img/products/hsd/STT/cr4.jpg" alt="Image" width="100%" height="auto">
                        </div>

                            <div class="article-content">
                                <h3>About EFA-STT®</h3>
                                <p> At all passageways in company which are passed through from two directions, the
                                    clear view through
                                    the door offers decisive advantages: Accidents are prevented and smooth transport
                                    operations are
                                    guaranteed.
                                    Copied a thousand times - but still unequalled. The door leaf is not wound-up on a
                                    shaft but kept
                                    space-saving in a certain distance in the patented EFAFLEX spiral. This functional
                                    principle guarantees
                                    you quite a number of unique advantages: only this unique design combines hightest
                                    opening speed, long
                                    service life and efficiency in such a perfect manner. Thanks to the spiral, the
                                    doors move quietly and
                                    wear-free - and look as good as new for many years.</p>

                                <strong>Ideal for air locks</strong>
                                <p>In air locks, as they are used e.g. in the automobile industry, the transparent laths
                                    of the EFA-STT®
                                    bring much light into your workshop. They do not only provide a clear view but also
                                    have a perfect look
                                    and create a free atmosphere with open visual axes between the individual rooms.
                                    This makes work much more
                                    comfortable.</p>
                                <strong>Universal use</strong>
                                <p>
                                    The EFA-STT® can be used universally. Due to its product characteristics such as the
                                    modern and transparent
                                    design, the high opening speed and its sizes of up to 8,000mm x 7,800mm, it can be
                                    used for many different
                                    applications.
                                </p>
                                <strong>Individual Design</strong>
                                <p>
                                    The EFA-STT® can be adjusted to the desired application. All transparent sections of
                                    the EFA-STT® are made
                                    of PVC-free acrylic glass as a standard. If requested, any combination with grey
                                    coloured laths are also
                                    available. A translucent design is also available as option. This design guarantees
                                    perfect light entry
                                    and at the same time protects from unauthorised persons looking inside.
                                </p>

                                <strong>EFA-STT® at a glance</strong>
                                <ul class="feat">
                                    <li>The door leaf consists at 70% of crystal clear acrylic glass.</li>
                                    <li>Opening up to 3 m/s.</li>
                                    <li>Closing up to 1.0 m/s.</li>
                                    <li>Highest wind load capacity.</li>
                                    <li>Top safety devices.</li>
                                    <li>Up to 200,000 operating cycles p.a.</li>
                                    <li>Standard sizes of up to w=8,000mm x h=7,800mm</li>


                                </ul>
                                <p>
                                    The EFA-STT® can be used universally. Due to its product characteristics such as the
                                    modern and transparent design, the high opening speed and its sizes of up to 8,000mm
                                    x 7,800mm, it can be used for many different applications.
                                </p>


                            </div>



                    </div>

                    <div class="col-lg-4 col-md-12">
                        <aside class="widget-area" id="secondary">
                            <section class="widget widget-hosta-posts-thumb">
                                <h3 class="widget-title">Why Efaflex</h3>

                                <div class="post-wrap">
                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg11" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Global Market Leader
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg12" role="img"></span>
                                        </a>
                                        <div class="info">
                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Unwavering Innovative Strength
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg13" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Consistent customer focus
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg14 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Cost Efficiency
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg15 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Quality Pays off
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>

                                    <article class="item">
                                        <a routerLink="" class="thumb">
                                            <span class="fullimage cover bg16 side-icon" role="img"></span>
                                        </a>
                                        <div class="info">

                                            <h4 class="title usmall">
                                                <a routerLink="">
                                                    Customised Configuration
                                                </a>
                                            </h4>
                                        </div>

                                        <div class="clear"></div>
                                    </article>
                                </div>
                            </section>

                            <!-- <section class="widget widget_categories">
                                <h3 class="widget-title">Archives</h3>

                                <div class="post-wrap">
                                    <ul>
                                        <li><a routerLink="/">January <span>2020</span></a></li>
                                        <li><a routerLink="/">February <span>2020</span></a></li>
                                        <li><a routerLink="/">March <span>2020</span></a></li>
                                        <li><a routerLink="/">April <span>2020</span></a></li>
                                        <li><a routerLink="/">May <span>2020</span></a></li>
                                        <li><a routerLink="/">June <span>2020</span></a></li>
                                    </ul>
                                </div>
                            </section> -->

                        </aside>
                    </div>
                </div>
                <div class="article-content">
                    <h3 class="cus-head">Specifications</h3>
                   
         <blockquote class="flaticon-quote">
            <table class="table">
 <thead class="table-light">
   <tr>
     <th scope="col">Product : </th>
     <th>EFA-STT®</th>
     <th> </th>
     <th> </th>
     <th> </th>
   </tr>
 </thead>
 <tbody>
    <tr>
        <th scope="row" ></th>
        <td scope="col">Size
        </td>
        <td scope="col">L
       </td>
       <td scope="col">S
    </td>
    <td scope="col">ÜS
    </td>
      </tr>
   <tr>
     <th scope="row" >Application </th>
     <td scope="col">Interior Door
        <br>
        Lock up Doors
     </td>
     <td scope="col">•
        <br>•
    </td>
    <td scope="col">•
        <br>•
    </td>
    <td scope="col">•
        <br>•
    </td>
   </tr>
   <tr>
    <th scope="row">Wind load max.*</th>
    <td scope="col">According to DIN EN 12424 class
    </td>
    <td scope="col">3 - 4
   </td>
   <td scope="col">2 - 4
</td>
<td scope="col">2 - 4
</td>
  </tr>
  <tr>
    <th scope="row">Operating forces/safe opening</th>
    <td scope="col">According to DIN EN 13241
    </td>
    <td scope="col">fulfilled
   </td>
   <td scope="col">fulfilled
</td>
<td scope="col">fulfilled
</td>
  </tr>
  <tr>
    <th scope="row">Resistance against water ingress</th>
    <td scope="col">According to DIN EN 13241 class
    </td>
    <td scope="col">0
   </td>
   <td scope="col">0
</td>
<td scope="col">0
</td>
  </tr>
  <tr>
    <th scope="row">Air permeability*</th>
    <td scope="col">According to DIN EN 13241 class
    </td>
    <td scope="col">2
   </td>
   <td scope="col">2
</td>
<td scope="col">2
</td>
  </tr>
  <tr>
    <th scope="row">Direct airborne sound insulation RW*</th>
    <td scope="col">in dB according to DIN EN 717-1
    </td>
    <td scope="col">20
   </td>
   <td scope="col">20
</td>
<td scope="col">20
</td>
  </tr>
  <tr>
    <th scope="row">U value maximum*</th>
    <td scope="col">in W/m<sup>2</sup>K according to DIN EN 13241
    </td>
    <td scope="col">6.5
   </td>
   <td scope="col">6.37
</td>
<td scope="col">6.28
</td>
  </tr>

  <tr>
    <th scope="row">Door size (in mm) </th>
    <td scope="col">Width W max.
        <br>
        Height H max.
    </td>
    <td scope="col">4000
        <br>5000
   </td>
   <td scope="col">6000
    <br>6000
</td>
<td scope="col">8000
<br>7800
</td>

  </tr>
  <tr>
    <th scope="row">Maximum door blade speed* </th>
    <td scope="col">in m/s
    </td>
    <td scope="col">3.0
   </td>
   <td scope="col">2.8
</td>
<td scope="col">2.0
</td>
  </tr>
  <tr>
    <th scope="row">Average speed, ca.*</th>
    <td scope="col">Opening in m/s
        <br>Closing in m/s
        <br>Closing in m/s, with EFA-TLG® door light-line
        grid
    </td>
    <td scope="col">2.5
        <br>2.2
        <br>1.8
   </td>
   <td scope="col">0.75
    <br>0.6
    <br>0.6
</td>
<td scope="col">1.0
<br>0.6
<br>0.6
</td>
  </tr>
  <tr>
    <th scope="row">Door blade guidance</th>
    <td scope="col">Round spiral
        <br>Oval Spiral
        <br>Low-header
    </td>
    <td scope="col">•
        <br>-
        <br>-
   </td>
   <td scope="col">•
    <br>-
    <br>-
</td>
<td scope="col">•
<br>-
<br>-
</td>
  </tr>
  <tr>
    <th scope="row">Steel design</th>
    <td scope="col">Galvanized sheet steel frame
        <br>Stainless steel
        <br>Powder coated in RAL colours
    </td>
    <td scope="col">•
        <br>◦
        <br>◦
   </td>
   <td scope="col">•
    <br>◦
    <br>◦
</td>
<td scope="col">•
<br>-
<br>◦
</td>
  </tr>
  <tr>
    <th scope="row">Door blade</th>
    <td scope="col">EFA-THERM® laths insulated / painted
        <br>EFA-CLEAR® Vision laths double-walled,
        thermally separated
        <br>EFA-CLEAR® Vision laths single-walled
        <br>EFA-VENT® Ventilation laths
        <br>EFA-ALUX® Aluminium laths
        <br>Vision panel single-walled / double-walled
        <br>non transparent infill single-walled / doublewalled
        <br>Colour according to RAL (without vison panel)
        <br>Door blade modules made of anodized
        aluminium E6 / EV1
        <br>Triple glazing for vision panel 
        <br>Door curtain made of flexible PVC, transparent
        with warning stripes in different colours 
        <br>flexible fabric in different colours with / without
        vison panel
    </td>
    <td scope="col">-
            <br>-
            <br>•
            <br>◦
            <br>-
            <br>•/-
            <br>◦/-
            <br>◦
            <br>-
            <br>-
            <br>-
            <br>-
   </td>
   <td scope="col">-
    <br>-
    <br>•
    <br>◦
    <br>-
    <br>•/-
    <br>◦/-
    <br>◦
    <br>-
    <br>-
    <br>-
    <br>-
</td>
<td scope="col">-
<br>-
<br>•
<br>◦
<br>-
<br>•/-
<br>◦/-
<br>◦
<br>-
<br>-
<br>-
<br>-
</td>
  </tr>
  <tr>
    <th scope="row">Fire class</th>
    <td scope="col">Building Material class DIN 4102
    </td>
    <td scope="col">B2
   </td>
   <td scope="col">B2
</td>
<td scope="col">B2
</td>

  </tr>
  <tr>
    <th scope="row">Weight balancing by</th>
    <td scope="col">
    </td>
    <td scope="col">Spring
   </td>
   <td scope="col">Spring
</td>
<td scope="col">Spring
</td>
  </tr>
  <tr>
    <th scope="row">Designed for approx ... operating cycles </th>
    <td scope="col">per year
    </td>
    <td scope="col">200,000
   </td>
   <td scope="col">200,000
</td>
<td scope="col">200,000
</td>
  </tr>
  <tr>
    <th scope="row">Drive</th>
    <td scope="col">Electric motor
        <br>Pneumatic with electric controller

    </td>
    <td scope="col">•
        <br>-
   </td>
   <td scope="col">•
    <br>-
</td>
<td scope="col">•
<br>-
</td>
  </tr>
  <tr>
    <th scope="row">Control</th>
    <td scope="col">EFA-TRONIC®
        <br>EFA-TRONIC® Light
        <br>EFA-TRONIC® Professional
        <br>Main switch and foil keypad
    </td>
    <td scope="col">•
        <br>-
        <br>◦
        <br>•
   </td>
   <td scope="col">•
    <br>-
    <br>◦
    <br>•
</td>
<td scope="col">-
<br>-
<br>•
<br>•
</td>

  </tr>
  <tr>
    <th scope="row">Lead</th>
    <td scope="col">Electricity connection 230 V/50 Hz
        <br>Electricity connection 400 V/50 Hz
    </td>
    <td scope="col">•
        <br>-
   </td>
   <td scope="col">•
    <br>-
</td>
<td scope="col">•
<br>◦
</td>
  </tr>
  <tr>
    <th scope="row">Manual locking</th>
    <td scope="col">
    </td>
    <td scope="col">◦
   </td>
   <td scope="col">◦
</td>
<td scope="col">◦
</td>
  </tr>
  <tr>
    <th scope="row">Emergency opening</th>
    <td scope="col">Automatic after manual activation
        <br>Manual activation
    </td>
    <td scope="col">•
        <br>-
   </td>
   <td scope="col">•
    <br>-
</td>
<td scope="col">•
<br>-
</td>
  </tr>
  <tr>
    <th scope="row">Safety Devices</th>
    <td scope="col">EFA-TLG® door light grid in door closing line
        <br>Contact edge
        <br>Light barrier
        <br>Approach area monitoring
        <br>Light grid, external
    </td>
    <td scope="col">•
        <br>◦
        <br>◦
        <br>◦
        <br>◦
   </td>
   <td scope="col">•
    <br>◦
    <br>◦
    <br>◦
    <br>◦
</td>
<td scope="col">•
<br>◦
<br>◦
<br>◦
<br>◦
</td>
  </tr>
  <tr>
    <th scope="row">Safety system including activator</th>
    <td scope="col">EFA-SCAN® frame/bollard
    </td>
    <td scope="col"> ◦/◦
   </td>
   <td scope="col"> ◦/◦
</td>
<td scope="col"> ◦/◦
</td>
  </tr>
  <tr>
    <th scope="row">Activators</th>
    <td scope="col">Connection of all common activators possible
    </td>
    <td scope="col">•
   </td>
   <td scope="col">•
</td>
<td scope="col">•
</td>
  </tr>
  </tbody>
</table>
<span>• Standard, ◦ upon request, – Not available, HSO = Head Safe Option, *Depending on door blade, door blade guidance and door size, we reserve the right to make technical alterations!</span>
 
</blockquote>
                </div>

            </div>
        </div>
    </div>

</section>
