<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Sliding Gates</h2>
            <p>A sliding gate is a type of gate that operates by sliding horizontally along a track or rail system. It is commonly used for securing entrances to properties, such as driveways or industrial facilities. Sliding gates are often made of metal, such as steel or aluminum, to provide strength and durability.
              </p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                style="visibility: visible;">
                <img src="assets/img/products/gates/sl0.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                <img src="assets/img/products/gates/sl1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                <img src="assets/img/products/gates/sl2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                <img src="assets/img/products/gates/sl3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
            </div>

                    <div class="article-content">
                        <h3>About Sliding Gate</h3>
                        <p align="justify">It is an ideal solution to protect your premises from unauthorized access and thus increase your personal security.
                            Automatic Motorized Sliding Gate requires a single gate, 2 easy roll-wheels fitted to gate, a roller post, a slam keep post and double steel track concreted into the ground.
                            our sliding gates are - convenient, safer, more functional, extremely durable, less space and improve visual appeal.
                        <br>
                        <br>
                        The gate is typically mounted on wheels or rollers, which allow it to move smoothly along the track. The gate can be operated manually, where a person physically pushes or pulls 
                        the gate to open or close it, or it can be automated using an electric motor or hydraulic system. Automated sliding gates are controlled by a remote control, keypad, or other access control mechanisms.
                        </p>
                    </div>
<br>
                    <ul class="feat" align="justify" style="line-height:180%">
                        <li>These sliding gates are widely used, and it is an economical option among almost all the
                            types of barriers.
                        </li>
                        <li>Our Sliding gates are available in a single side or a double side sliding that is suggested
                            to suit actual site condition.
                        </li>
                        <li>There are top nylon rollers with bearings on both the sides that are mounted on the column
                            for support and much smoother movement of the gate
                        </li>
                        <li>At each end- track stopper is always placed to make sure it absorbs any impact and to hold
                            the gate in the closed or open position
                        </li>
                        <li>Also, some sliding gates for houses are available with inbuilt hinged/ Sliding type gate
                        </li>
                        <li>These sliding gates can be operated manually in case of power failure
                        </li>
                        <li>Sliding gates can be interlocked with boom barrier as an additional safety.

                        </li>
                        <li>Various industrial gate designs are available to suit the clients choice

                        </li>
                        <li>The hot deep galvanizing process, sandblasting, painting can be provided.

                        </li>
                        <br>

                    </ul>

            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>
 <!------>          
        <!--My code  -->
        <blockquote class="flaticon-quote">
        <table class="table table-hover">
            <thead class="table-light">
              <tr>
                <th scope="col">Product : </th>
                <th scope="col">Motorized Sliding Gate</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row" color="#fe9900">Gate Dimension :</th>
                <td></td>
              </tr>
              <tr>
                <th scope="row">Width Min. /Max.</th>
                <td>Single Leaf : 1000/12000mm; Double Leaf :1000/24000mm</td>
              </tr>
              <tr>
                <th scope="row">Height Min. /Max.</th>
                <td>1000/2200mm Larger Dimension on request</td>
              </tr>
              <tr>
                <th scope="row">Max. No. of Leaves</th>
                <td>2</td>
              </tr>
              <tr>
                <th scope="row">Opening Direction</th>
                <td>Horizontal</td>
              </tr>
              <tr>
                <th scope="row">Warranty</th>
                <td>Limited Lifetime on the motor, gears, and rail assembly</td>
              </tr>
              <tr>
                <th scope="row"> Illumination	</th>
                <td>500 Lumens of integrated LED lighting provides over 30.000 hours</td>
              </tr>
              <tr>
                <th scope="row"> Technology</th>
                <td>Multi-bit code technology 315MHz 2-channel remote control is included</td>
              </tr>
              <tr>
                <th scope="row">Available In</th>
                <td>Mild-Steel, Stainless Steel,Wood & Steel combination, Aluminum and Hot-dip galvanization including powder coating.</td>
              </tr>
              <tr>
                <th scope="row">HPcs</th>
                <td>HPcs indicates that Synergy 380 meets Marantec’s specifications for 1.1 HP garage door operators</td>
              </tr>
              <tr>
                <th scope="row">Maintenance</th>
                <td>Operator and rail system are maintenance free</td>
              </tr>
              <tr>
                <th scope="row">Safety System</th>
                <td>photo eye safety system is equipped with UV filters in order to eliminate interference created by sunlight.</td>
              </tr>
              <tr>
                <th scope="row">Mounting Location</th>
                <td>Inside</td>
              </tr>
              <tr>
                <th scope="row">Mounting Surface</th>
                <td>Bottom Rail: RCC Columns : RCC.</td>
              </tr>

              <tr>
                <th scope="row" color="#fe9900">Gate Design :</th>
                <td></td>
              </tr>

              <tr>
                <th scope="row">Pattern</th>
                <td>Standard Mild Steel Tubular pattern.</td>
              </tr>
              <tr>
                <th scope="row">Gate Frame</th>
                <td>Heavy Duty Box Section Or as per the clients specific design.</td>
              </tr>
              <tr>
                <th scope="row"> Internal vertical member</th>
                <td>Heavy Duty Vertical Box sections Or as per the clients specific design.
                </td>
              </tr>
              <tr>
                <th scope="row">Bottom Rail</th>
                <td>polished bright bar with I-beam support</td>
              </tr>
              <tr>
                <th scope="row">Bottom Rollers</th>
                <td>Heavy Duty Bottom Wheel with both side bearings.</td>
              </tr>
              <tr>
                <th scope="row"> Full height Column</th>
                <td>Heavy Duty Box Section Or as per the clients specific design Special Color on request</td>
              </tr>
              <tr>
                <th scope="row">Guide Rollers</th>
                <td>Nylon Rollers with bearings both side mounted on column for support & smooth movement of gate.</td>
              </tr>

              <tr>
                <th scope="row" color="#fe9900">Pattern / Design :</th>
                <td></td>
              </tr>

              <tr>
                <th scope="row">Other available gate material</th>
                <td>Stainless Steel, Hot dip Galvanized</td>
              </tr>
              <tr>
                <th scope="row"> Sheets</th>
                <td>M.S/ GI/ Perforated/ Aluminum sheets/ Polycarbonate / Acrylic / PVC Sheet/ Glass/ Wood</td>
              </tr>
              <tr>
                <th scope="row">Ornamental Designs</th>
                <td>On Request or as per client design.</td>
              </tr>
              <tr>
                <th scope="row">Options Available</th>
                <td>Spikes as per requirement Wicket gate/Side Gates in the similar pattern as the main gate, Inbuilt gate in the similar pattern as the main gate, Any type of Customized Design can be made on request</td>
              </tr>
              <tr>
                <th scope="row">Surface Finish</th>
                <td>Standard: Red Oxide Primer Optional: Enamel Painting/Duco/PU painting Available with or without sand Blasting</td>
              </tr>
              <tr>
                <th scope="row" color="#fe9900">Details for Automation System :</th>
                <td></td>
              </tr>
              <tr>
                <th scope="row"> Drive Unit</th>
                <td>Electric Motor(Imported) Single/Three Phase.</td>
              </tr>
              <tr>
                <th scope="row">Emergency Operation</th>
                <td>Manual release knob</td>
              </tr>
              <tr>
                <th scope="row">Accessories</th>
                <td>Operational Accessories, Additional Push Button, Key switch, Remote Control with receiver, Magnetic Loop Detector</td>
              </tr>
              <tr>
                <th scope="row">Certification</th>
                <td>CE</td>
              </tr>
              <tr>
                <th scope="row">Protection Class</th>
                <td>IP44 – IP55.</td>
              </tr>
              
            </tbody>
          </table>
</blockquote>

<!------>


<!--
            <blockquote class="flaticon-quote">
                <div class="tab-pane active" role="tabpanel" id="tab-3">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <td style="width:120px"><span class="fa fa-thumbs-o-up"></span>
                                                &nbsp;Product</td>
                                            <td>Motorized Sliding Gate
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="2" style="text-align:center;">Gate Dimension</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><span class="fa fa-dashboard"></span> &nbsp;Width
                                                Min. /Max.</td>
                                            <td>Single Leaf : 1000/12000mm
                                                Double Leaf :1000/24000mm</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;" class="txt-clr"><span
                                                    class="fa fa-gears"></span>&nbsp; Height Min. /Max.</td>
                                            <td class="txt-clr">1000/2200mm
                                                Larger Dimension on request</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><i class="fa fa-steam"></i>&nbsp;Max. Number of
                                                Leaves</td>
                                            <td>Two</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;" class="txt-clr"><i
                                                    class="fa fa-fire-extinguisher"></i>&nbsp; Opening Direction</td>
                                            <td class="txt-clr">Horizontal</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><span></span> &nbsp;Warranty</td>
                                            <td>Limited Lifetime on the motor, gears, and rail assembly</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;" class="txt-clr"><span></span>
                                                &nbsp;illumination</td>
                                            <td class="txt-clr">500 Lumens of integrated LED lighting provides over
                                                30.000 hours</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px"><span></span> &nbsp;technology</td>
                                            <td>Multi-bit code technology 315MHz 2-channel remote control is included
                                            </td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;"><span></span> &nbsp;Available In</td>
                                            <td class="txt-clr"> Mild-Steel, Stainless Steel,Wood &amp; Steel
                                                combination, Aluminum and Hot-dip galvanization including powder
                                                coating.
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>


                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <td style="width:120px" class="txt-clr"><span class=" txt-clr"></span>&nbsp;
                                                HPcs</td>
                                            <td class="txt-clr">HPcs indicates that Synergy 380 meets Marantec’s
                                                specifications for 1.1 HP garage door operators</td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;maintenance</td>
                                            <td>Operator and rail system are maintenance free</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px" class="txt-clr"><i></i>&nbsp; safety system</td>
                                            <td class="txt-clr">photo eye safety system is equipped with UV filters in
                                                order to eliminate interference created by sunlight</td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px;"><i></i>&nbsp;Mounting Location </td>
                                            <td>Inside</td>
                                        </tr>


                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Mounting Surface
                                            </td>
                                            <td>Bottom Rail: RCC
                                                Columns : RCC</td>
                                        </tr>

                                        <tr>

                                            <td colspan="2">Gate Design</td>
                                        </tr>
                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Pattern </td>
                                            <td style="
                                                ">Standard Mild Steel Tubular pattern</td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Gate Frame </td>
                                            <td>Heavy Duty Box Section
                                                Or as per the clients specific design</td>
                                        </tr>


                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Internal vertical member
                                            </td>
                                            <td>Heavy Duty Vertical Box sections
                                                Or as per the clients specific design.</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                    <hr>


                    //specification table 2

                    <div class="row">
                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <tbody>

                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Bottom Rail
                                            </td>
                                            <td style="text-align:center;">polished bright bar with I-beam
                                                support.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Bottom Rollers
                                            </td>
                                            <td style="text-align:center;">Heavy Duty Bottom Wheel with both
                                                side bearings
                                            </td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Full height Column </td>
                                            <td>Heavy Duty Box Section
                                                Or as per the clients specific design
                                                Special Color on request</td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Guide
                                                Rollers </td>
                                            <td>Nylon Rollers with bearings both side mounted
                                                on column for support &amp; smooth movement of gate.
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="2">Patterns/Designs</td>
                                        </tr>

                                        <tr>
                                            <td><i class=""></i>&nbsp;Other available gate
                                                material </td>
                                            <td>Stainless Steel,
                                                Hot dip Galvanized</td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Sheets </td>
                                            <td style="width:120px;">
                                                M.S/GI/Perforated /Aluminum sheets
                                                Polycarbonate / Acrylic / PVC Sheet/Glass/Wood
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><i class=""></i>&nbsp;Ornamental Designs </td>
                                            <td>On Request or as per client design.</td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="table-responsive">
                                <table class="table table-bordered table-striped">
                                    <tbody>
                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Options Available </td>
                                            <td style="width:120px;">
                                                Spikes as per requirement
                                                Wicket gate/Side Gates in the similar pattern as the main gate,
                                                Inbuilt gate in the similar pattern as the main gate,
                                                Any type of Customized Design can be made on request
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><i class=""></i>&nbsp;Surface Finish </td>
                                            <td>
                                                Standard: Red Oxide Primer
                                                Optional: Enamel Painting/Duco/PU painting
                                                Available with or without sand Blasting
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="2">Details for automation system</td>
                                        </tr>

                                        <tr>
                                            <td style="width:120px;"><i class=""></i>&nbsp;Drive Unit </td>
                                            <td style="width:120px;">
                                                Electric Motor(Imported)
                                                Single/Three Phase
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>&nbsp;Emergency Operation </td>
                                            <td>Manual release knob
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><i class=""></i>&nbsp;Accessories </td>
                                            <td>
                                                Operational Accessories,
                                                Additional Push Button,
                                                Key switch,
                                                Remote Control with receiver,
                                                Magnetic Loop Detector
                                            </td>
                                        </tr>

                                        <tr>
                                            <td colspan="2">Features</td>
                                        </tr>

                                        <tr>
                                            <td><i class=""></i>&nbsp;Certification </td>
                                            <td>CE
                                            </td>
                                        </tr>

                                        <tr>
                                            <td>&nbsp;Protection Class </td>
                                            <td>IP44 – IP55
                                            </td>
                                        </tr>

                                    </tbody>
                                </table>

                            </div>
                        </div>
                    </div>
                </div>
            </blockquote> 
        -->
        </div>

    </div>
</section>
