<section class="blog-details-area pt-70">
    <div class="container">
        <div class="section-title wow fadeInUp animated animated" style="visibility: visible;">
            <h2>Fire Rated Shutters</h2>
            <p>Fire Rated Rolling Shutter is an ideal solution to compartmentalize a building in order to help prevent the fire from spreading. Many industries already experienced very huge losses just because of their negligence to use of fire resistant shutters at their workplace. </p>
        </div>
        <div class="row">
            <div class="col-lg-8 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <div class="custom1 owl-carousel owl-theme wow fadeInUp animated animated"
                    style="visibility: visible;">
                    <img src="assets/img/products/shutters/firers1.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/firers2.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                    <img src="assets/img/products/shutters/firers3.jpg" alt="Image" type="image/jpeg" width="100%" height="auto">
                </div>

                    <div class="article-content" align="justify">
                        <h3>About Fire Rated Shutter</h3>
                        <p> International Doors fire rated rolling shutters are in the store, which appear and function
                            in the same manner as traditional single motorized rolling shutters. The only difference is
                            the material used and the fitting type drive motors. These fi re rated rolling shutters are
                            designed to stay open under normal circumstances, and in the event of fi re shutter close
                            automatically to prevent the spread of fire to neighboring areas. The fusible integrated
                            links melt at 74°C, this triggers the fire shutters to close automatically in the event of a
                            fire.
                            Fire rating from 30min 60min 90min 1hr – 2hr – 3hr – 4hr.
                        <br>
                            Our Fire Rated Rolling Shutters are manufactured in such a way that it automatically closes if open in situation of fire incidents. These doors are must for all industrial and commercial places to cease the spread of fire from one unit to another.</p>

                            <h3 class="cus-head">Our Features</h3>
                        <ul class="feat" style="line-height:180%">
                            <li>Use of High Grade Steel to make all components of shutter</li>
                            <li>Easy operation with a ‘Single Push Button’</li>
                            <li>Prime quality fire resistant material suiting perfectly to industrial applications</li>
                            <li>Use of latest technology for automation and energy efficiency</li>
                            <li> Available in different finishes or powder coated with customize color combinations</li>
                        </ul>
                        <p>Our skilled and experienced engineers are capable of manufacturing and installing Fire Resistant Rolling Shutters keeping in mind international standards. Innovations in our manufacturing processes helped us to produce rolling shutters at a cost-effective price.</p>
                    </div>

            </div>

            <div class="col-lg-4 col-md-12 wow fadeInUp animated animated" style="visibility: visible;">
                <aside class="widget-area" id="secondary">
                    <section class="widget widget-hosta-posts-thumb">
                        <h3 class="widget-title">Why Gatemaan</h3>

                        <div class="post-wrap">
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg1" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            30+ Years of Experience
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg2" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Premium Quality
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg3" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Satisfied Clients
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg4 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Revolutionary line
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg5 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            Time and Budget
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg6 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            CNC technology
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>


                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg7 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            professional
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>

                            <article class="item ">
                                <a  class="thumb">
                                    <span class="fullimage cover bg8 side-icon" role="img"></span>
                                </a>
                                <div class="info">

                                    <h4 class="title usmall">
                                        <a >
                                            AMC
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                            <article class="item">
                                <a  class="thumb">
                                    <span class="fullimage cover bg9 side-icon" role="img"></span>
                                </a>
                                <div class="info">
                                    <h4 class="title usmall">
                                        <a >
                                            Global Partner
                                        </a>
                                    </h4>
                                </div>

                                <div class="clear"></div>
                            </article>
                        </div>
                    </section>

                    <!-- <section class="widget widget_categories">
                        <h3 class="widget-title">Archives</h3>

                        <div class="post-wrap">
                            <ul>
                                <li><a routerLink="/">January <span>2020</span></a></li>
                                <li><a routerLink="/">February <span>2020</span></a></li>
                                <li><a routerLink="/">March <span>2020</span></a></li>
                                <li><a routerLink="/">April <span>2020</span></a></li>
                                <li><a routerLink="/">May <span>2020</span></a></li>
                                <li><a routerLink="/">June <span>2020</span></a></li>
                            </ul>
                        </div>
                    </section> -->

                </aside>
            </div>
        </div>
        <div class="article-content">
            <h3 class="cus-head">Specifications</h3>

            <table class="table table-hover">
                <thead class="table-light">
                  <tr>
                    <th scope="col">Product : </th>
                    <th scope="col">Fire Rated Rolling Shutter (Need to Update the Specifications)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row" color="#fe9900">Shutter Dimension : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Width Min. /Max.</th>
                    <td>1000 / 12000mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Height Min. /Max.</th>
                    <td>1000 / 12000mm; Max. Area 90.0 Sqm 
                        <br> Large Dimestion on Request
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Opening Dimension</th>
                    <td>Vertical</td>
                  </tr>
                  <tr>
                    <th scope="row">Mounting Location</th>
                    <td>Inside / Outside</td>
                  </tr>
                  <tr>
                    <th scope="row">Shutter Material</th>
                    <td>Aluminum Alloy</td>
                  </tr>
                  <tr>
                    <th scope="row">Maximum Width * Height:</th>
                    <td>6 * 8 m</td>
                  </tr>
                  <tr>
                    <th scope="row"> Type of box</th>
                    <td>165 185 205 250 300 350mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Thickness</th>
                    <td>1.2mm</td>
                  </tr>
                  <tr>
                    <th scope="row">Surface Treatin</th>
                    <td>Powder Coating</td>
                  </tr>
                  <tr>
                    <th scope="row">The sevice life</th>
                    <td>can be used 15 years</td>
                  </tr>
                  <tr>
                    <th scope="row">Opening/Closing Speed</th>
                    <td>0.05m - 0.2m/sec</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Up to 90 km/hr
                        <br> * Higher Wind Pressure on Request
                    </td>
                  </tr>
                
                  <tr>
                    <th scope="row" color="#fe9900">Motor Size</th>
                    <td>600Kg / 800Kg / 1000Kg etc</td>
                  </tr>
    
                  <tr>
                    <th scope="row">Color</th>
                    <td>Red, Yellow, Orange, Blue, Grey etc</td>
                  </tr>
                  <tr>
                    <th scope="row">Control Panel</th>
                    <td>Danvers Brand,Including Inverter, PLC and other components.Size Option: 0.75KW</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive System</th>
                    <td>Germany SEW motor 380V or 220V/50Hz 0.75kw,1.1kw,1.5kw
                    </td>
                  </tr>
                  <tr>
                    <th scope="row">Opening Way</th>
                    <td>Double-side radar, double-side press button, infrared sensors</td>
                  </tr>
                  <tr>
                    <th scope="row">Dynamical systems</th>
                    <td>Adopt high-tech motor reducer:0.75-2.2KW, which according to your door size.</td>
                  </tr>
                  <tr>
                    <th scope="row">Options Available</th>
                    <td>Perforation In all above Slat profile S.S Hood Cover made of sheet thickness 0.4mm</td>
                  </tr>
    
                  <tr>
                    <th scope="row">Details for Automation System : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Control Type</th>
                    <td>Deadman / Pulse Control</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Type</th>
                    <td>Indirect / Direct Connecting Drive</td>
                  </tr>
                  <tr>
                    <th scope="row">Drive Unit</th>
                    <td>Electric Motor(Imported Make) Single Phase/Three Phase.</td>
                  </tr>
                  <tr>
                    <th scope="row">Emergency Operation	</th>
                    <td>Manual operation by Hand chain</td>
                  </tr>
                  <tr>
                    <th scope="row" color="#fe9900">Options Available</th>
                    <td>Up to 90 km/hr</td>
                  </tr>
                  <tr>
                    <th scope="row">Wind Resistance</th>
                    <td>Perforation In Slat profile
                        Rolling Grill
                        Wicket Door for pedestrian entry</td>
                  </tr>
                  <tr>
                    <th scope="row">Features : </th>
                    <td></td>
                  </tr>
                  <tr>
                    <th scope="row">Certification</th>
                    <td>CE VDE checked</td>
                  </tr>
                  <tr>
                    <th scope="row">Protection Class</th>
                    <td>IP54.</td>
                  </tr>
                  
                </tbody>
              </table>


<!--
    <blockquote class="flaticon-quote">

                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td class="aluf6"><span class="fa fa-thumbs-o-up"></span> &nbsp;Product</td>
                                        <td>Motorized Fire rated rolling shutters

                                        </td>
                                    </tr>
                                    <tr>

                                        <td colspan="2" style="text-align:center;color:white;">Door Dimension</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><span class="fa fa-dashboard"></span> &nbsp;Width Min. /Max.
                                        </td>
                                        <td> 1000/7000mm</td>
                                    </tr>
                                    <tr class="aluf8">
                                        <td class="txt-clr aluf6"><span class="fa fa-gears"></span>&nbsp; Height Min.
                                            /Max.</td>
                                        <td class="txt-clr">1000/7000mm<br>
                                            Max. Area 49.0Sqm<br>
                                            Larger Dimension on request</td>
                                    </tr>

                                    <tr>
                                        <td class="aluf6"><i class="fa fa-steam"></i>&nbsp;Opening Dimension </td>
                                        <td>Vertical</td>
                                    </tr>
                                    <tr style="color:#fff;">
                                        <td class="txt-clr aluf6"><i class="fa fa-fire-extinguisher"></i>&nbsp; Mounting
                                            Location</td>
                                        <td class="txt-clr">Inside/outside</td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>

                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td style="width:200px"><span></span> &nbsp;Control system</td>
                                        <td>Buttons</td>
                                    </tr>
                                    <tr style="color:#fff;">
                                        <td class="txt-clr aluf6"><i></i>&nbsp;Safety system</td>
                                        <td class="txt-clr">Smoke heat compound detector</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><span></span> &nbsp;Openning speed</td>
                                        <td>2-7.5m/min</td>
                                    </tr>
                                    <tr class="aluf8">
                                        <td class="txt-clr aluf6"><span></span> &nbsp;Door material</td>
                                        <td class="txt-clr">1, Fire rated used fabric <br>2,Fireproof ceramic wool</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><span></span>&nbsp; Highest stand temperater</td>
                                        <td><strong>&lt;=129 <strong> C</strong> </strong></td>
                                    </tr>

                                    <tr class="aluf8">
                                        <td class="txt-clr aluf6"><i></i>&nbsp;Cross bar</td>
                                        <td class="txt-clr">Galvanized steel material material</td>
                                    </tr>

                                    <tr>
                                        <td class="aluf6"><i></i>&nbsp;Opening ways</td>
                                        <td>push button,pull cord,radar,induction loop,remote...(optional) </td>
                                    </tr>
                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
                <hr>



                <div class="row">
                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>

                                    <tr>
                                        <td class="aluf6"><i></i>&nbsp;Mounting Surface </td>
                                        <td>Side Brackets : On Concrete /steel<br>
                                            Side Guides : on Concrete /Steel/ Masonry</td>
                                    </tr>


                                    <tr>
                                        <td class="aluf7"><i class=""></i>&nbsp;Fire rating duration </td>
                                        <td class="fire1">1 hour / 2 hour / 4 hour</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Comply To </td>
                                        <td>BS 476 Part 22</td>
                                    </tr>

                                    <tr>

                                        <td colspan="2" style="text-align:center;color:white;">Door Structure</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Slat Material </td>
                                        <td>Single Skin 1.00 mm to 1.2 mm thick flat fire retardant corrugated profile
                                        </td>
                                    </tr>

                                    <tr>
                                        <td class="fire"><i class=""></i>&nbsp;Bottom Profile </td>
                                        <td style="text-align:center;color:white;">Fire Retardant Standard profile with
                                            Stiffener</td>
                                    </tr>
                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Side Guide Profile </td>
                                        <td>Specially designed fire retardant side guides profile.</td>
                                    </tr>
                                    <tr>
                                        <td class="fire"><i class=""></i>&nbsp;Roller Shaft </td>
                                        <td class="fire1">M.S Tubular Pipe with flanges &amp; machined shaft at the
                                            ends..</td>
                                    </tr>
                                    <tr>
                                        <td class="fire"><i class=""></i>&nbsp;Side Brackets </td>
                                        <td class="fire1">M.S plate 3mm to 10mm,as per the opening dimension</td>
                                    </tr>
                                    <tr>
                                        <td class="fire"><i class=""></i>&nbsp;Surface Finish </td>
                                        <td class="fire1">Powder coated with pre-treatment process.<br>
                                            Special Color on request</td>
                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>


                    <div class="col-md-6">
                        <div class="table-responsive">
                            <table class="table table-bordered table-striped">
                                <tbody>
                                    <tr>
                                        <td class="aluf6"><i class=""></i>&nbsp;Hood Cover (Optional) </td>
                                        <td>Made from Galvanized steel sheet thickness 0.8mm</td>
                                    </tr>
                                    <tr>

                                        <td colspan="2">Details for Automation System</td>
                                    </tr>
                                    <tr>
                                        <td><i class=""></i>&nbsp;Control Type </td>
                                        <td>Deadman/Pulse Control</td>
                                    </tr>
                                    <tr>
                                        <td class="fire"><i class=""></i>&nbsp;Drive Type </td>
                                        <td class="fire">In Direct /Direct Connecting Drive</td>
                                    </tr>
                                    <tr>
                                        <td><i class=""></i>&nbsp;Drive Unit </td>
                                        <td>Electric Motor with overload heat protection thermostat device<br>
                                            Single Phase</td>
                                    </tr>
                                    <tr>
                                        <td class="fire"><i class=""></i>&nbsp;Drive Type </td>
                                        <td class="fire">In direct Connecting Type drive</td>
                                    </tr>
                                    <tr>
                                        <td><i class=""></i>&nbsp;Emergency Operation </td>
                                        <td>Chain Wheel drive</td>
                                    </tr>
                                    <tr>
                                        <td class="fire"><i class=""></i>&nbsp;Operating Switch </td>
                                        <td class="fire">Push Button from Inside<br>
                                            Optional-Push Button/Key Switch from Outside</td>
                                    </tr>
                                    <tr>
                                        <td><i class=""></i>&nbsp;Accessories(Optional)
                                            *can be integrated with advanced control </td>
                                        <td>
                                            A Flashing Light<br>
                                            Smoke detector<br>
                                            Thermal Fusible link

                                        </td>

                                    </tr>

                                </tbody>
                            </table>

                        </div>
                    </div>
                </div>
            </blockquote>
-->
            
        </div>

    </div>
</section>
