import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Router } from '@angular/router';
import { throwError, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
const httpOptions = {
  headers: new HttpHeaders({
    'Authorization': 'Bearer',
    'Content-Type': 'application/json; charset=utf-8',
  }),

  };
@Injectable({
  providedIn: 'root'
})
export class MailService {
  private mailApi = '';

  constructor(private http: HttpClient) { }
  private extractData(res: Response) {
    const body = res;
    return body || {};
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {

      console.error('An error occurred:', error.error.message);
    } else {

      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }

    return throwError(error.error);
  }
  public PostMessage(data): Observable<any> {
    const headers = new HttpHeaders({ 'Access-Control-Allow-Origin': '*', 'content-type': 'application/json'});
    const url = `https://newsite.gatemaan.com/mail.php`;
    return this.http.post(url, data, {headers})
      .pipe(map(this.extractData),
        catchError(this.handleError),
      );

    // const url = `${this.mailApi}`;
    // return this.http.post(url+'/', data, httpOptions)
    //   .pipe(map(this.extractData),
    //     catchError(this.handleError)
    //   );

  }

//   PostMessage(input: any) {
//     const headers = new HttpHeaders()

//     return this.http.post(this.mailApi, input, {headers})
//       .pipe(
//         map(
//           (response) => {
//             if (response) {
//               return response;
//             }
//           },
//           (error: any) => {
//             return error;
//           }
//         )
//       );
//   }

}
